import us from '../assets/images/nationFlags/us.svg';
import jp from '../assets/images/nationFlags/jp.svg';
import dk from '../assets/images/nationFlags/dk.svg';
import nl from '../assets/images/nationFlags/nl.svg';
import br from '../assets/images/nationFlags/br.svg';

export interface CountryOption {
	label: string;
	value: string;
	image: string;
}

export const shorthandTable = {
	english: 'en',
	japanese: 'jp',
	danish: 'dk',
	dutch: 'nl',
	brazil: 'br',
};
export type LanguageShorthand = keyof typeof shorthandTable;

//React-Intl library breaks if "value" is more than 8 characters!!!!!
export const options: CountryOption[] = [
	{
		label: 'English',
		value: 'english',
		image: us,
	},
	{
		label: '日本語',
		value: 'japanese',
		image: jp,
	},
	{
		label: 'Dansk',
		value: 'danish',
		image: dk,
	},
	{
		label: 'Nederlands',
		value: 'dutch',
		image: nl,
	},
	{
		label: 'Português Brasileiro',
		value: 'brazil',
		image: br,
	},
];
