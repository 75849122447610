import React, { FC, useState, useEffect } from 'react';
import {
	IonIcon,
	IonLabel,
	IonList,
	IonItem,
	IonListHeader,
	IonInput,
	IonCol,
	IonRow,
	IonGrid,
	IonButton,
	IonChip,
} from '@ionic/react';
import { add, close, people } from 'ionicons/icons';

import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import isAuthenticated from '../Authentication/Authenticated';

import classes from './UserGroupSetup.module.css';
import classNames from 'classnames';

import Messages from './UserGroupSetup.messages';
import Tooltip from 'react-tooltip-lite';

import CreateGroupModal from '../UserGroups/CreateGroupModal';
import { useTypedSelector } from '../../reducers';
import { b64EncodeUnicode } from '../../utils/encoding';
import { publish } from '../../actions/publish';
import { InputLimit } from '../../utils/validator';
import { translationWithErrorHandling } from '../../translation/handleMissingTranslation';

interface UserGroupSetupDetailsProps {
	userGroup?: any;
	goTo?: () => void;
	monitorizeFormChange?: any;
}

const UserGroupSetupDetails: FC<UserGroupSetupDetailsProps> = (props: any) => {
	const { intl, userGroup, monitorizeFormChange } = props;

	const usernameForEncoding = useTypedSelector(state => state.accountState.user.username);
	const [encodedUser] = useState(b64EncodeUnicode(usernameForEncoding));
	const orgId = useTypedSelector(state => state.selectedOrganizationState.organization.orgId);

	const [groupName, setGroupName] = useState<any>();
	const [removedFromGroup, setRemovedFromGroup] = useState<string[]>([]);

	const [showCreateModal, setCreateModal] = useState(false);

	const openInviteUser = async () => {
		props.setParameter('users', 'CHANGE_USER_INVITE_STATE', {
			inviteUserOpen: true,
			inviteUserToUserGroupIds: [userGroup.userGroupId],
		});
	};

	useEffect(() => {
		if (userGroup) {
			setGroupName(userGroup.name);
		}
	}, [userGroup]);

	const removeUserFromGroup = (item: any) => {
		publish(`microservice/${orgId}/${encodedUser}/updateUserGroupUsers`, {
			requestId: 'updateUserGroupUsers',
			data: {
				userGroupId: userGroup.userGroupId,
				add: [],
				remove: [item],
			},
		});

		setRemovedFromGroup([...removedFromGroup, item]);
	};

	const onChangeInput = (label: any, value: any) => {
		monitorizeFormChange(label, value);
		switch (label) {
			case 'name':
				setGroupName(value[label]);
				break;
		}
	};

	return (
		<>
			<IonGrid className={classes.formGrid}>
				<IonRow>
					<IonList className="ion-padding">
						<IonListHeader>
							<IonIcon className={classes.icon} size="medium" icon={people} />
							<IonLabel className={classes.detailHeader}>
								<FormattedMessage {...Messages.userGroupDetails} />
							</IonLabel>
						</IonListHeader>
					</IonList>
				</IonRow>
				{userGroup ? (
					<IonRow className={classes.userSetupForm}>
						<IonCol className={classes.firstCol}>
							<IonList>
								<IonItem className={classes.formItem}>
									<IonLabel position="floating">
										{translationWithErrorHandling(
											intl,
											'UserGroupSetupDetails.groupName'
										)}
									</IonLabel>
									<IonInput
										onIonChange={(selected: any) => {
											onChangeInput('name', {
												name:
													selected.detail.value ||
													selected.target.children[0]?.value,
											});
										}}
										autofocus
										required
										type="text"
										name="userName"
										value={groupName}
										maxlength={InputLimit}
									/>
								</IonItem>
							</IonList>

							<br />
							<span className={classes.inGroupLabel}>
								{translationWithErrorHandling(
									intl,
									'UserGroupSetupDetails.inGroup'
								)}
								:
							</span>

							{userGroup?.usersIds &&
								userGroup?.usersIds !== null &&
								userGroup?.usersIds.length > 0 && (
									<IonRow className={classes.devicesRow}>
										{userGroup?.usersIds
											.filter(
												(item: any) =>
													removedFromGroup.includes(item) === false
											)
											.map((item: any, index: number) => {
												return (
													<IonChip
														className={classes.IonChip}
														key={index}
													>
														<IonLabel className={classes.IonChipLabel}>
															{item}{' '}
														</IonLabel>

														<Tooltip
															direction="up"
															content={translationWithErrorHandling(
																intl,
																'UserGroupSetupDetails.removeFromGroup'
															)}
														>
															<IonIcon
																onClick={() =>
																	removeUserFromGroup(item)
																}
																className={classes.close}
																icon={close}
															/>
														</Tooltip>
													</IonChip>
												);
											})}
									</IonRow>
								)}

							<br />

							<br />
							<IonButton
								className={classes.createBtn}
								fill="outline"
								onClick={openInviteUser}
							>
								<IonIcon slot="icon-only" icon={add} className={classes.addIcon} />
								<span className={classes.initialText}>
									{translationWithErrorHandling(
										intl,
										'UserGroupSetupDetails.inviteUser'
									)}
								</span>
							</IonButton>
						</IonCol>
						<IonCol />
					</IonRow>
				) : null}

				<IonRow>
					<IonCol className={classes.linkContainer}>
						<div onClick={() => setCreateModal(true)}>
							<IonIcon size="small" icon={add} />
							<IonLabel className={classNames('ion-float-right', classes.link)}>
								<FormattedMessage {...Messages.createNewGroup} />
							</IonLabel>
						</div>
					</IonCol>
					<IonCol />
				</IonRow>
			</IonGrid>

			<CreateGroupModal
				isOpen={showCreateModal}
				onDismiss={() => setCreateModal(false)}
				orgId={props.orgId}
				title={translationWithErrorHandling(intl, 'UserGroups.createModal.title')}
				titleHint={translationWithErrorHandling(intl, 'UserGroups.createModal.titleHint')}
			/>
		</>
	);
};

const mapStateToProps = (state: any) => ({});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(UserGroupSetupDetails), 'UserGroupSetupDetails'));
