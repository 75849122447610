import React, { FC, useEffect, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { anonymous, subscribeToAccountCreate } from '../../providers/mqtt';

import { setParameter } from '../../actions/setParam';
import {
	ACCOUNT_CREATE_DETAILS_UPDATED,
	ACCOUNT_CREATE_REQUEST_ID_CREATED,
	ACCOUNT_CREATE_STATUS_UPDATED,
	SET_MQTTCLIENT,
} from '../../actions/types';
import { publish } from '../../actions/publish';
import isAuthenticated from '../../components/Authentication/Authenticated';
import { AccountCreatedStatus } from '../../reducers/accountCreateReducer';

import { IonCol, IonContent, IonGrid, IonRow } from '@ionic/react';

import brandingImage from '../../assets/images/gobe-cover-01.png';
import { FMBrandedSplitPane, FMBrandedSplitPaneImage } from '../../ui-elements/FMBrandedSplitPane';
import { FMFooterLogo } from '../../ui-elements/FMFooterLogo';
import { FMHeaderGoBe } from '../../ui-elements/FMHeaderGoBe';
import { FMButtonPrimary } from '../../ui-elements/FMButtons';

import classNames from 'classnames';
import classes from './AccountCreate.module.css';
import FormInputListItem from '../../components/FormInputListItem/FormInputListItem';
import PrivacyPolicyModal from '../../components/PrivacyPolicyModal/PrivacyPolicyModal';
import { CountrySelect } from '../../components/CustomFormComponents';
import ErrorModal from '../../components/ErrorModal/ErrorModal';
import { EmailInputLimit, InputLimit, ShortInputLimit } from '../../utils/validator';
import { translationWithErrorHandling } from '../../translation/handleMissingTranslation';

interface AccountCreatePageProps {
	accountCreatedStatus: AccountCreatedStatus;
	client: any;
	intl: any;
	setParameter: any;
}

const AccountCreatePage: FC<AccountCreatePageProps> = ({
	accountCreatedStatus,
	client,
	intl,
	setParameter,
}) => {
	const [publishTopic, setPublishTopic] = useState<string>('');
	const [privacyPolicyModalIsOpen, setPrivacyPolicyModalIsOpen] = useState<boolean>(false);

	const { control, handleSubmit, errors } = useForm({});
	const [errorModalAlreadyConfirmedIsOpen, setErrorModalAlreadyConfirmedIsOpen] = useState<
		boolean
	>(false);
	const [errorModalAccountCreationIsOpen, setErrorModalAccountCreationIsOpen] = useState<boolean>(
		false
	);
	const [successModalIsOpen, setSuccessModalIsOpen] = useState<boolean>(false);

	function onSubmit(values: any) {
		if (accountCreatedStatus !== AccountCreatedStatus.WAITING_FOR_USER_INPUT) {
			return;
		}

		values = { ...values, country: values.country.value, domainUrl: window.location.hostname };

		setParameter('details', ACCOUNT_CREATE_DETAILS_UPDATED, values);

		const requestId = window
			.btoa(unescape(encodeURIComponent(JSON.stringify(values))))
			.substring(0, 32);

		setParameter('requestId', ACCOUNT_CREATE_REQUEST_ID_CREATED, requestId);

		publish(publishTopic, {
			data: { ...values },
			requestId,
		});

		setParameter('status', ACCOUNT_CREATE_STATUS_UPDATED, AccountCreatedStatus.PENDING);
	}

	function hasErrors() {
		return Object.keys(errors).length > 0;
	}

	useEffect(() => {
		anonymous((client: any) => {
			setParameter('client', SET_MQTTCLIENT, client);
		});
	}, [setParameter]);

	useEffect(() => {
		if (client.connected === false || typeof client.listen !== 'function') {
			return;
		}

		const { publishTopic, unsubscribeCallback } = subscribeToAccountCreate(client);
		setPublishTopic(publishTopic);

		return unsubscribeCallback;
	}, [client]);

	useEffect(() => {
		if (accountCreatedStatus !== AccountCreatedStatus.SUCCESS) {
			return;
		}

		setParameter(
			'status',
			ACCOUNT_CREATE_STATUS_UPDATED,
			AccountCreatedStatus.WAITING_FOR_USER_INPUT
		);

		setSuccessModalIsOpen(true);
	});

	useEffect(() => {
		if (accountCreatedStatus !== AccountCreatedStatus.ERROR) {
			return;
		}

		setParameter(
			'status',
			ACCOUNT_CREATE_STATUS_UPDATED,
			AccountCreatedStatus.WAITING_FOR_USER_INPUT
		);

		setErrorModalAccountCreationIsOpen(true);
		window.location.reload();
	});

	useEffect(() => {
		if (accountCreatedStatus !== AccountCreatedStatus.FAIL) {
			return;
		}

		setParameter(
			'status',
			ACCOUNT_CREATE_STATUS_UPDATED,
			AccountCreatedStatus.WAITING_FOR_USER_INPUT
		);
		setErrorModalAlreadyConfirmedIsOpen(true);
	});

	const pending = accountCreatedStatus === AccountCreatedStatus.PENDING;

	return (
		<>
			{errorModalAlreadyConfirmedIsOpen ? (
				<ErrorModal
					isOpen={true}
					onConfirm={() => setErrorModalAlreadyConfirmedIsOpen(false)}
					onDismiss={() => setErrorModalAlreadyConfirmedIsOpen(false)}
					type={'accountAlreadyConfirmed'}
				/>
			) : (
				''
			)}
			{errorModalAccountCreationIsOpen ? (
				<ErrorModal
					isOpen={true}
					onConfirm={() => setErrorModalAccountCreationIsOpen(false)}
					onDismiss={() => setErrorModalAccountCreationIsOpen(false)}
					type={'accountCreationFailed'}
				/>
			) : (
				''
			)}
			{successModalIsOpen ? (
				<ErrorModal
					isOpen={true}
					onConfirm={() => window.location.replace('https://www.gobe-robots.com/')}
					onDismiss={() => window.location.replace('https://www.gobe-robots.com/')}
					type={'success'}
				/>
			) : (
				''
			)}
			<PrivacyPolicyModal
				agreementId="privacy-and-cookie-policy_160374175000_en_gobe"
				isOpen={privacyPolicyModalIsOpen}
				onAccept={() => {
					setPrivacyPolicyModalIsOpen(false);
				}}
				onDismiss={() => {
					setPrivacyPolicyModalIsOpen(false);
				}}
			/>
			<FMBrandedSplitPane contentId="accountCreateForm">
				<FMBrandedSplitPaneImage url={brandingImage} />
				<IonContent id="accountCreateForm">
					<div className={classes.contentContainer}>
						<div className={classes.headerContainer}>
							<FMHeaderGoBe>
								<FormattedMessage id="AccountCreatePage.welcome" />
							</FMHeaderGoBe>
						</div>
						<div
							className={
								hasErrors()
									? classNames(classes.formContainer, classes.formContainerError)
									: classes.formContainer
							}
						>
							<IonGrid>
								<IonRow>
									<IonCol>
										<FormInputListItem
											onChange={() => {}}
											type="text"
											name="companyName"
											defaultValue=""
											label={translationWithErrorHandling(
												intl,
												'ContactPerson.companyName'
											)}
											placeholderText={translationWithErrorHandling(
												intl,
												'ContactPerson.companyNameHint'
											)}
											control={control}
											rules={{ required: true }}
											required
											errorMsg={
												errors.companyName &&
												translationWithErrorHandling(
													intl,
													'AccountCreateForm.companyNameError'
												)
											}
											disabled={pending}
											maxLength={InputLimit}
										/>
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol>
										<FormInputListItem
											onChange={() => {}}
											type="text"
											name="firstName"
											defaultValue=""
											label={translationWithErrorHandling(
												intl,
												'ContactPerson.firstName'
											)}
											placeholderText={translationWithErrorHandling(
												intl,
												'ContactPerson.firstNameHint'
											)}
											control={control}
											rules={{ required: true }}
											required
											errorMsg={
												errors.firstName &&
												translationWithErrorHandling(
													intl,
													'AccountCreateForm.firstNameError'
												)
											}
											disabled={pending}
											maxLength={ShortInputLimit}
										/>
									</IonCol>
									<IonCol>
										<FormInputListItem
											onChange={() => {}}
											type="text"
											name="lastName"
											defaultValue=""
											label={translationWithErrorHandling(
												intl,
												'ContactPerson.lastName'
											)}
											placeholderText={translationWithErrorHandling(
												intl,
												'ContactPerson.lastNameHint'
											)}
											control={control}
											rules={{ required: true }}
											required
											errorMsg={
												errors.lastName &&
												translationWithErrorHandling(
													intl,
													'AccountCreateForm.lastNameError'
												)
											}
											maxLength={ShortInputLimit}
											disabled={pending}
										/>
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol>
										<FormInputListItem
											onChange={() => {}}
											type="email"
											name="email"
											defaultValue=""
											label={translationWithErrorHandling(
												intl,
												'ContactPerson.email'
											)}
											placeholderText={translationWithErrorHandling(
												intl,
												'ContactPerson.emailHint'
											)}
											control={control}
											rules={{
												required: true,
												pattern: {
													value: /^[-a-z0-9+_-]+(\.[-a-z0-9+_-]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/,
												},
											}}
											required
											errorMsg={
												errors.email &&
												translationWithErrorHandling(
													intl,
													'AccountCreateForm.emailError'
												)
											}
											maxLength={EmailInputLimit}
											disabled={pending}
										/>
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol>
										<FormInputListItem
											onChange={() => {}}
											type="text"
											name="address"
											defaultValue=""
											label={translationWithErrorHandling(
												intl,
												'Address.street'
											)}
											placeholderText={translationWithErrorHandling(
												intl,
												'Address.streetHint'
											)}
											control={control}
											rules={{ required: true }}
											required
											errorMsg={
												errors.address &&
												translationWithErrorHandling(
													intl,
													'AccountCreateForm.addressError'
												)
											}
											disabled={pending}
											maxLength={InputLimit}
										/>
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol>
										<FormInputListItem
											onChange={() => {}}
											type="text"
											name="address2"
											defaultValue=""
											label={translationWithErrorHandling(
												intl,
												'Address.street2'
											)}
											placeholderText={translationWithErrorHandling(
												intl,
												'Address.street2Hint'
											)}
											control={control}
											disabled={pending}
											maxLength={InputLimit}
										/>
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol>
										<FormInputListItem
											onChange={() => {}}
											type="text"
											name="zip"
											defaultValue=""
											label={translationWithErrorHandling(
												intl,
												'Address.zip'
											)}
											placeholderText={translationWithErrorHandling(
												intl,
												'Address.zipHint'
											)}
											control={control}
											rules={{ required: true }}
											required
											errorMsg={
												errors.zip &&
												translationWithErrorHandling(
													intl,
													'AccountCreateForm.zipError'
												)
											}
											disabled={pending}
											maxLength={InputLimit}
										/>
									</IonCol>
									<IonCol>
										<FormInputListItem
											onChange={() => {}}
											type="text"
											name="city"
											defaultValue=""
											label={translationWithErrorHandling(
												intl,
												'Address.city'
											)}
											placeholderText={translationWithErrorHandling(
												intl,
												'Address.cityHint'
											)}
											control={control}
											rules={{ required: true }}
											required
											errorMsg={
												errors.city &&
												translationWithErrorHandling(
													intl,
													'AccountCreateForm.cityError'
												)
											}
											disabled={pending}
											maxLength={InputLimit}
										/>
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol>
										<CountrySelect
											control={control}
											name="country"
											errors={errors}
											menuPlacement="top"
											required
											disabled={pending}
											dataCy="country"
										/>
									</IonCol>
								</IonRow>
							</IonGrid>
							{pending || (
								<>
									<div>
										<div className={classes.disclaimer}>
											<FormattedMessage
												id="AccountCreatePage.disclaimer"
												values={{
													a: (msg: string) => (
														<div
															className={classes.privacyPolicyButton}
															onClick={() =>
																setPrivacyPolicyModalIsOpen(true)
															}
														>
															{msg}
														</div>
													),
												}}
											/>
										</div>
										<div className={classes.buttonsContainer}>
											<div>
												<FormattedMessage
													id="AccountCreatePage.loginAction"
													values={{
														a: (msg: string) => (
															<Link to="/">{msg}</Link>
														),
													}}
												/>
											</div>
											<FMButtonPrimary
												onClick={() => handleSubmit(onSubmit)()}
											>
												<FormattedMessage id="AccountCreatePage.createAction" />
											</FMButtonPrimary>
										</div>
									</div>
								</>
							)}
						</div>
						<footer className={classes.footerContainer}>
							<FMFooterLogo />
						</footer>
					</div>
				</IonContent>
			</FMBrandedSplitPane>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	accountCreatedStatus: state.accountCreateState.status,
	client: state.mqttState.client,
});

export default injectIntl(
	isAuthenticated(
		connect(mapStateToProps, { setParameter })(AccountCreatePage),
		'AccountCreatePage'
	)
);
