import React, { FC, useEffect, useState } from 'react';
import { IonList, IonButton, IonItem, IonRow } from '@ionic/react';
import Config from '../../config';
import { injectIntl, FormattedMessage } from 'react-intl';
import isAuthenticated from '../../components/Authentication/Authenticated';
import FormInputListItem from '../FormInputListItem/FormInputListItem';
import { CountrySelect, LanguageSelect, Select } from '../CustomFormComponents';
import { useForm } from 'react-hook-form';

import classes from './AddOrganizationModal.module.css';
import classNames from 'classnames';
import { useTypedSelector } from '../../reducers';

import Messages from '../AddOrganizationModal/AddOrganizationModal.messages';
import { InputLimit } from '../../utils/validator';
import { translationWithErrorHandling } from '../../translation/handleMissingTranslation';

interface AddContactFormProps {
	orgTypes: [];
	onSubmit: (data: any) => void;
	onCancel: () => void;
	onValid: () => void;
	// onGetValues: () => void;
}

const AddOrganizationForm: FC<AddContactFormProps> = (props: any) => {
	const { intl, orgTypes } = props;
	const format = intl.formatMessage;
	const { handleSubmit, control, errors, formState } = useForm();
	const [orgType, setOrgType] = useState(orgTypes[0]);

	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;

	useEffect(() => {
		props.onValid(formState.isValid, formState.isSubmitted, formState.dirty);
	}, [formState, props]);

	const onFormSubmit = handleSubmit((data: any) => {
		if (data.type === undefined) {
			data.type = orgType;
		}
		props.onSubmit(data);
	});

	const getOptions = (types: []) => {
		return types.map((type: string) => {
			return { value: type, label: format({ id: 'Organizations.types.' + type }) };
		});
	};

	return (
		<form onSubmit={onFormSubmit} className={classes.addForm}>
			<IonList className={classNames(classes.formList, 'ion-padding')}>
				<IonRow className={classes.modalContentContainer}>
					<IonRow className={classes.rowContainer} data-cy="orgTypes">
						{orgTypes.length > 1 ? (
							<Select
								control={control}
								initializedValue="solutionProvider"
								name="orgTypes"
								label={translationWithErrorHandling(intl, 'Organizations.type')}
								options={getOptions(orgTypes)}
								errors={errors}
								menuPlacement="auto"
								required
							/>
						) : (
							<FormInputListItem
								type="text"
								name="type"
								label={translationWithErrorHandling(intl, 'Organizations.type')}
								placeholderText={translationWithErrorHandling(
									intl,
									'Organizations.typeHint'
								)}
								defaultValue={orgTypes[0]}
								control={control}
								disabled
								maxLength={InputLimit}
							/>
						)}
					</IonRow>
					<IonRow className={classes.rowContainer}>
						<FormInputListItem
							type="text"
							name="name"
							label={translationWithErrorHandling(intl, 'Organizations.name')}
							placeholderText={translationWithErrorHandling(
								intl,
								'Organizations.nameHint'
							)}
							control={control}
							rules={{ required: true }}
							required
							errorMsg={
								errors.name &&
								translationWithErrorHandling(intl, 'Organizations.nameError')
							}
							maxLength={InputLimit}
							dataCy="name"
						/>
					</IonRow>
					{spinoutType !== 'beam' && (
						<IonRow className={classes.rowContainer}>
							<LanguageSelect
								control={control}
								initializedValue="en"
								name="language"
								errors={errors}
								menuPlacement="auto"
								required
							/>
						</IonRow>
					)}
					<IonRow className={classes.rowContainer}>
						<FormInputListItem
							type="text"
							name="address"
							label={translationWithErrorHandling(intl, 'Address.address')}
							placeholderText={translationWithErrorHandling(
								intl,
								'Address.addressHint'
							)}
							control={control}
							rules={{ required: true }}
							required
							errorMsg={errors.address && 'Address is required'}
							maxLength={InputLimit}
							dataCy="address"
						/>
					</IonRow>
					<IonRow className={classes.rowContainer}>
						<div className={classes.zipCityContainer}>
							<FormInputListItem
								type="text"
								name="zip"
								label={translationWithErrorHandling(intl, 'Address.zip')}
								control={control}
								rules={{ required: false }}
								maxLength={InputLimit}
								dataCy="zip"
							/>
							<FormInputListItem
								type="text"
								name="city"
								label={translationWithErrorHandling(intl, 'Address.city')}
								control={control}
								rules={{ required: false }}
								maxLength={InputLimit}
								dataCy="city"
							/>
						</div>
					</IonRow>
					<IonRow className={classes.rowContainer}>
						<FormInputListItem
							type="text"
							name="state"
							label={translationWithErrorHandling(intl, 'Address.state')}
							control={control}
							rules={{ required: false }}
							maxLength={InputLimit}
							dataCy="state"
						/>
					</IonRow>
					<IonRow className={classes.rowContainer}>
						<CountrySelect
							control={control}
							name="country"
							errors={errors}
							menuPlacement="top"
							dataCy="country"
							required
						/>
					</IonRow>
					{orgType === 'solutionProvider' ? (
						<IonRow className={classes.rowContainer}>
							<FormInputListItem
								type="text"
								minLength={36}
								maxLength={36}
								placeholderText={translationWithErrorHandling(
									intl,
									'Organizations.enterSupportKey'
								)}
								name="childSupportKey"
								defaultValue={Config.defaultSupportKey}
								label={translationWithErrorHandling(
									intl,
									'Organizations.supportKey'
								)}
								control={control}
								required
								rules={{ required: true }}
								errorMsg={
									errors.childSupportKey &&
									translationWithErrorHandling(
										intl,
										'Organizations.supportKeyError'
									)
								}
							/>
						</IonRow>
					) : null}
				</IonRow>
				<IonRow className={classNames(classes.rowContainer, classes.btnContainer)}>
					<IonItem lines="none" className={classes.btnRow}>
						<IonButton
							id="add-org-form-org-cancel-btn"
							className={classes.cancelBtn}
							expand="block"
							shape="round"
							size="large"
							fill="outline"
							onClick={props.onCancel}
						>
							<FormattedMessage {...Messages.cancel} />
						</IonButton>
						<IonButton
							id="add-org-form-org-next-btn"
							className={classes.submitBtn}
							expand="block"
							shape="round"
							type="submit"
							size="large"
							fill="outline"
							data-cy="create_organization_next_button"
						>
							<FormattedMessage {...Messages.next} />
						</IonButton>
					</IonItem>
				</IonRow>
			</IonList>
		</form>
	);
};

export default injectIntl(isAuthenticated(AddOrganizationForm, 'AddOrganizationForm'));
