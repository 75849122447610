import React, { FC, useEffect } from 'react';
import {
	IonIcon,
	IonLabel,
	IonList,
	IonItem,
	IonListHeader,
	IonCol,
	IonRow,
	IonGrid,
} from '@ionic/react';
import { business } from 'ionicons/icons';
import FormInputListItem from '../FormInputListItem/FormInputListItem';

import { injectIntl, FormattedMessage, FormattedDate } from 'react-intl';
import isAuthenticated from '../Authentication/Authenticated';
import { useForm } from 'react-hook-form';
import { LanguageSelect } from '../CustomFormComponents';

import classes from './OrganizationSetup.module.css';
import classNames from 'classnames';
import Messages from './OrganizationSetup.messages';
import { InputLimit } from '../../utils/validator';
import { translationWithErrorHandling } from '../../translation/handleMissingTranslation';

interface OrganizationDetailsProps {
	organization: any;
	goTo?: () => void;
	monitorizeFormChange?: any;
}

const OrganizationDetails: FC<OrganizationDetailsProps> = (props: any) => {
	const { intl, organization, monitorizeFormChange } = props;
	const { control, watch, errors } = useForm();
	const language = watch('language');

	useEffect(() => {
		if (!language || !organization || language === organization.language) {
			return;
		}
		monitorizeFormChange('language', 'en');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [language]);

	const onCompanyNameChange = (label: string, value: any) => {
		if (!value?.name || value.name === organization.name) {
			return;
		}
		monitorizeFormChange(label, value);
	};

	if (!organization) {
		return null;
	}

	return (
		<IonGrid className={classes.formGrid}>
			<IonRow>
				<IonList className="ion-padding">
					<IonListHeader>
						<IonIcon className={classes.icon} size="small" icon={business} />
						<IonLabel className={classes.detailHeader}>
							<FormattedMessage {...Messages.organizationDetails} />
						</IonLabel>
					</IonListHeader>
				</IonList>
			</IonRow>
			<IonRow>
				<IonCol className={classes.firstCol}>
					<IonList>
						<FormInputListItem
							onChange={(selected: any) => {
								onCompanyNameChange('name', {
									name:
										selected.detail.value || selected.target.children[0]?.value,
								});
							}}
							type="text"
							name="companyName"
							defaultValue={organization?.name}
							label={translationWithErrorHandling(intl, 'Organizations.companyName')}
							placeholderText={translationWithErrorHandling(
								intl,
								'Organizations.companyName'
							)}
							control={control}
							rules={{ required: true }}
							className={classes.formItem}
							maxLength={InputLimit}
							dataCy="companyName"
						/>
						<FormInputListItem
							type="text"
							name="orgId"
							control={control}
							defaultValue={organization.orgId}
							label={translationWithErrorHandling(intl, 'Organizations.companyId')}
							disabled
							className={classes.formItem}
							maxLength={InputLimit}
							dataCy="orgId"
						/>
						<IonItem
							lines="none"
							className={classNames(classes.selectContainer, 'ion-no-padding')}
						>
							<div className={classes.customFormComponentContainer}>
								<LanguageSelect
									control={control}
									initializedValue="en"
									name="language"
									errors={errors}
									menuPlacement="auto"
									marginLess
									required
								/>
							</div>
						</IonItem>
						<IonItem lines="none" className="ion-no-padding">
							<IonLabel className={classes.createdAt}>
								<FormattedMessage {...Messages.createdAt} />:{' '}
								<FormattedDate
									value={organization.createdAt}
									year="numeric"
									month="2-digit"
									day="2-digit"
								/>
							</IonLabel>
						</IonItem>
					</IonList>
				</IonCol>
				<IonCol>{/* TODO: Implement organization profile picture upload */}</IonCol>
			</IonRow>
		</IonGrid>
	);
};

export default injectIntl(isAuthenticated(OrganizationDetails, 'OrganizationDetails'));
