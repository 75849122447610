import React, { FC, useState, useEffect } from 'react';
import { IonItem, IonCheckbox, IonGrid, IonRow, IonCol, IonLabel } from '@ionic/react';
import { injectIntl, FormattedMessage } from 'react-intl';
import isAuthenticated from '../Authentication/Authenticated';

import classes from './AccountSetup.module.css';
import Messages from './AccountSetup.messages';

interface NotificationSettingsProps {
	intl: any;
	initChecked: string[];
	updateChecked: (checked: string[]) => undefined;
	showAdminSettings: boolean;
}

const NotificationSettings: FC<NotificationSettingsProps> = (props: any) => {
	const { intl, initChecked, updateChecked, showAdminSettings } = props;

	const [checked, setChecked] = useState<string[]>([...initChecked]);

	const labelMsg = Messages.notificationItems;
	const settings = {
		// Group 1
		password_reset_required: {
			name: 'password_reset_required',
			isAdminSetting: false,
			label: intl.formatMessage(labelMsg.passwordResetRequired), //LS - This file is behind, fix it
			checked: false,
			col: 1,
			group: 1,
			divider: true,
		},

		// Group 2
		user_added_to_robot_group: {
			name: 'user_added_to_robot_group',
			isAdminSetting: false,
			label: intl.formatMessage(labelMsg.userAddedToRobotGroup),
			checked: false,
			col: 1,
			group: 2,
			divider: false,
		},
		user_removed_from_robot_group: {
			name: 'user_removed_from_robot_group',
			isAdminSetting: false,
			label: intl.formatMessage(labelMsg.userRemovedFromRobotGroup),
			checked: false,
			col: 1,
			group: 2,
			divider: false,
		},
		user_added_to_user_group: {
			name: 'user_added_to_user_group',
			isAdminSetting: false,
			label: intl.formatMessage(labelMsg.userAddedToUserGroup),
			checked: false,
			col: 1,
			group: 2,
			divider: false,
		},
		user_removed_from_user_group: {
			name: 'user_removed_from_user_group',
			isAdminSetting: false,
			label: intl.formatMessage(labelMsg.userRemovedFromUserGroup),
			checked: false,
			col: 1,
			group: 2,
			divider: true,
		},

		// Group 3
		user_added_to_organization: {
			name: 'user_added_to_organization',
			isAdminSetting: false,
			label: intl.formatMessage(labelMsg.userAddedToOrganization),
			checked: false,
			col: 1,
			group: 3,
			divider: false,
		},
		user_removed_from_organization: {
			name: 'user_removed_from_organization',
			isAdminSetting: false,
			label: intl.formatMessage(labelMsg.userRemovedFromOrganization),
			checked: false,
			col: 1,
			group: 3,
			divider: true,
		},

		// Group 4
		software_released: {
			name: 'software_released',
			isAdminSetting: false,
			label: intl.formatMessage(labelMsg.softwareReleased),
			checked: false,
			col: 1,
			group: 4,
			divider: false,
		},

		// Group 5
		organization_settings_change: {
			name: 'organization_settings_change',
			isAdminSetting: true,
			label: intl.formatMessage(labelMsg.organizationSettingsChange),
			checked: false,
			col: 2,
			group: 5,
			divider: true,
		},

		// Group 6
		users_administrator_role_changed: {
			name: 'users_administrator_role_changed',
			isAdminSetting: true,
			label: intl.formatMessage(labelMsg.usersAdministratorRoleChanged),
			checked: false,
			col: 2,
			group: 6,
			divider: false,
		},
		administrator_roles_changed_in_organization: {
			name: 'administrator_roles_changed_in_organization',
			isAdminSetting: true,
			label: intl.formatMessage(labelMsg.administratorRolesChangedInOrganization),
			checked: false,
			col: 2,
			group: 6,
			divider: false,
		},
		user_robot_groups_edited: {
			name: 'user_robot_groups_edited',
			isAdminSetting: true,
			label: intl.formatMessage(labelMsg.userRobotGroupsEdited),
			checked: false,
			col: 2,
			group: 6,
			divider: false,
		},
		robot_added_or_removed_from_organization: {
			name: 'robot_added_or_removed_from_organization',
			isAdminSetting: true,
			label: intl.formatMessage(labelMsg.robotAddedOrRemovedFromOrganization),
			checked: false,
			col: 2,
			group: 6,
			divider: true,
		},

		// Group 7
		robot_shut_down_or_restarted: {
			name: 'robot_shut_down_or_restarted',
			isAdminSetting: true,
			label: intl.formatMessage(labelMsg.robotShutDownOrRestarted),
			checked: false,
			col: 2,
			group: 7,
			divider: false,
		},
		robot_settings_changed: {
			name: 'robot_settings_changed',
			isAdminSetting: true,
			label: intl.formatMessage(labelMsg.robotSettingsChanged),
			checked: false,
			col: 2,
			group: 7,
			divider: true,
		},

		// Group 8
		robot_outside_charging_dock: {
			name: 'robot_outside_charging_dock',
			isAdminSetting: true,
			label: intl.formatMessage(labelMsg.robotOutsideChargingDock),
			checked: false,
			col: 2,
			group: 8,
			divider: false,
		},
	};

	function colCheckboxChangeHandler(event: CustomEvent) {
		const { checked: isChecked, value: col } = event.detail;
		let nextChecked = [...checked];
		for (const [name, setting] of Object.entries(settings)) {
			if (setting.col !== parseInt(col)) {
				continue;
			}
			if (isChecked && !checked.includes(name)) {
				nextChecked.push(name);
			}
			if (!isChecked && checked.includes(name)) {
				nextChecked = nextChecked.filter(n => n !== name);
			}
		}
		setChecked(nextChecked);
	}

	function toggleCheckbox(name: string) {
		if (checked.includes(name)) {
			setChecked(checked.filter(n => n !== name));
			return;
		}
		setChecked([...checked, name]);
	}

	useEffect(() => {
		if (typeof updateChecked !== 'function') {
			return;
		}
		updateChecked(checked);
	}, [checked, updateChecked]);

	const col1Items: any[] = [];
	const col2Items: any[] = [];

	for (const [name, setting] of Object.entries(settings)) {
		if (setting.isAdminSetting && !showAdminSettings) {
			continue;
		}
		const item = (
			<div key={name}>
				<IonItem lines="none">
					<IonCheckbox
						name={name}
						value={name}
						checked={checked.includes(name)}
						// eslint-disable-next-line no-loop-func
						onClick={() => {
							toggleCheckbox(name);
						}}
					/>
					<IonLabel>
						<span dangerouslySetInnerHTML={{ __html: setting.label }} />
					</IonLabel>
				</IonItem>
				{setting.divider && <hr />}
			</div>
		);
		if (setting.col === 1) {
			col1Items.push(item);
		} else {
			col2Items.push(item);
		}
	}

	return (
		<IonGrid className={classes.notificationsGrid}>
			<IonRow className={classes.notificationsGridHeader}>
				<IonCol>
					<IonCheckbox onIonChange={colCheckboxChangeHandler} value="1" />
					<IonLabel>
						<FormattedMessage {...Messages.notificationItems.notifyWhen} />
					</IonLabel>
				</IonCol>
				<IonCol>
					{showAdminSettings && (
						<>
							<IonCheckbox onIonChange={colCheckboxChangeHandler} value="2" />
							<IonLabel>
								<FormattedMessage {...Messages.notificationItems.notifyWhen} />
							</IonLabel>
						</>
					)}
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol>{col1Items}</IonCol>
				<IonCol>{col2Items}</IonCol>
			</IonRow>
		</IonGrid>
	);
};

export default injectIntl(isAuthenticated(NotificationSettings, 'NotificationSettings'));
