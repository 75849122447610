import React, { FC, useState, useCallback, Fragment, useEffect } from 'react';
import { IonButton, IonIcon, IonLabel, IonAvatar } from '@ionic/react';
import { add, checkmarkCircleOutline } from 'ionicons/icons';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { publish } from '../../actions/publish';
import { setParameter } from '../../actions/setParam';
import { sortedCollection } from '../../selectors';
import { timeUuid } from '../../actions/timeUuid';
import isAuthenticated from '../Authentication/Authenticated';
import Tooltip from 'react-tooltip-lite';

import ListGrid from '../ListGrid/ListGrid';
import SearchBar from '../SearchBar/SearchBar';
import FilterList from '../FilterList/FilterList';
import CreateModal from '../CreateAddItemsModal/CreateAddItemsModal';
import AddItemsModal from '../CreateAddItemsModal/CreateAddItemsModal';
import RobotGroupEdit from './RobotGroupEdit';
import EditGroupModal from './EditGroupModal';

import classes from './RobotGroups.module.css';
import classNames from 'classnames';

import Messages from './RobotGroups.messages';
import { useTypedSelector } from '../../reducers';
import { getCircularReplacer, getDevices } from '../../utils/conformState';

import { formatTimeAgo } from '../../utils/formatTime';
import { b64EncodeUnicode } from '../../utils/encoding';
import { store } from '../../store/store';
import { Device } from '../../types/types';
import { Divider, MenuItem } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import robotGroupAvatar from '../../assets/images/robotGroupAvatar.svg';
import EllipsisText from '../EllipsisText/EllipsisText';
import { translationWithErrorHandling } from '../../translation/handleMissingTranslation';

const deviceIcons: Record<string, string> = {
	beam: './assets/img/beam-logo-white.svg',
	gobe: './assets/icons/GOBE-ROBOTS-logo-neg.svg',
};

const headers = [
	{ title: Messages.name, property: 'name', style: { minWidth: '50%' } },
	{ title: Messages.linkedUserGroups, property: 'userGroups' },
	{ title: Messages.dateAdded, property: 'createdAt' },
	{ title: Messages.robots, property: 'devicesIds', centered: true },
];

interface RobotGroupsProps {
	filterValues: any;
	orgId: string;
	editableRobotGroup?: any;
}
const StyledMenuItem = styled(MenuItem)(() => ({
	paddingLeft: 10,
	borderRadius: 5,
}));
const RobotGroups: FC<RobotGroupsProps> = (props: any) => {
	const { intl, deviceGroups, history, userGroups, orgId, editableRobotGroup } = props;
	const { deviceGroupsByOrganizationId, createdGroup, addDevicesOpen, sortParams } = deviceGroups;
	const { userGroupsByOrganizationId } = userGroups;
	const username = useTypedSelector(state => state.accountState.user.username);
	const encodedUser = b64EncodeUnicode(username);
	const selectedOrganizationId = useTypedSelector(
		state => state.selectedOrganizationState.organization.orgId
	);
	const deviceGroupsState = useTypedSelector(
		state => state.deviceGroupsState.deviceGroupsByOrganizationId
	);

	const [groupData, setGroupData] = useState([]);
	const [isEditing, setIsEditing] = useState(false);
	const [selectedGroup, setSelectedGroup] = useState<any>(null);
	const [showCreateModal, setCreateModal] = useState(false);
	const [showAddRobotsModal, setAddRobotsModal] = useState(false);
	const [showEditGroupModal, setEditGroupModal] = useState(false);
	const [availableRobots, setAvailableRobots] = useState<any[]>([]);
	const [createdIndex, setCreatedIndex] = useState(-1);
	const [searchText, setSearchText] = useState('');
	const [deviceGroupNames, setDeviceGroupNames] = useState<string[]>([]);
	const [filterValue, setFilterValue] = useState<string>('all');
	const [alreadyTakenNames, setAlreadyTakenNames] = useState<string[]>([]);
	const { setError } = useForm();

	const [pageSize] = useState(11);

	const devicesByOrganizationId = useTypedSelector(state => getDevices(state, orgId));
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;

	// Data for grid
	useEffect(() => {
		if (!deviceGroupsByOrganizationId[orgId]) return;
		if (searchText) {
			let searchedRobotGroups = JSON.parse(
				JSON.stringify(deviceGroupsByOrganizationId[orgId], getCircularReplacer())
			);
			for (const key in searchedRobotGroups) {
				let name = searchedRobotGroups[key].name;
				if (name.toLowerCase().indexOf(searchText.toLowerCase()) < 0)
					delete searchedRobotGroups[key];
			}
			setGroupData(searchedRobotGroups);
		} else {
			setGroupData(deviceGroupsByOrganizationId[orgId]);
		}
	}, [orgId, deviceGroupsByOrganizationId, searchText]);

	// useEffect(() => {
	// 	if (!deviceGroupsByOrganizationId[orgId]) return;

	// 	let names: any = Object.values(deviceGroupsByOrganizationId[orgId]).map(
	// 		(deviceGroup: any) => deviceGroup.name
	// 	);

	// 	const filteredNames = names.filter((name: string) => name !== 'Unassigned');
	// 	props.filterValues.splice(1, 0, filteredNames);
	// 	setDeviceGroupNames(names);
	// }, [deviceGroupsByOrganizationId, props.filterValues, orgId]);

	useEffect(() => {
		if (filterValue === undefined) return;

		let all: any = deviceGroupsByOrganizationId[orgId] || {};
		switch (filterValue) {
			case 'all':
				setGroupData(all);
				break;
			default:
				if (deviceGroupNames.findIndex((name: any) => name === filterValue) > -1) {
					let byGroup = JSON.parse(JSON.stringify(all, getCircularReplacer()));
					for (const key in byGroup) {
						if (byGroup[key].name !== filterValue) delete byGroup[key];
					}
					setGroupData(byGroup);
				}
				break;
		}
	}, [deviceGroupNames, deviceGroupsByOrganizationId, filterValue, intl, orgId]);

	useEffect(() => {
		if (!editableRobotGroup) return;
		setSelectedGroup(editableRobotGroup);
		setIsEditing(true);
	}, [editableRobotGroup]);

	useEffect(() => {
		if (history.location.data?.editableRobotGroup) {
			const robotGroup = history.location.data.editableRobotGroup;
			setSelectedGroup(JSON.parse(JSON.stringify(robotGroup, getCircularReplacer())));
			history.location.data.editableRobotGroup = undefined;
		}
	}, [history.location.data]);

	// Create device group
	useEffect(() => {
		if (createdGroup) {
			const sortedGroupData = sortedCollection({
				items: groupData ? formatData(Object.values(groupData)) : [],
				sortParams: sortParams,
			});

			setCreatedIndex(
				(sortedGroupData ? sortedGroupData : []).findIndex(
					(gr: any) => gr.deviceGroupId === createdGroup.deviceGroupId
				)
			);
			setTimeout(() => {
				props.setParameter('createdGroup', 'CREATED_NEW_DEVICE_GROUP', null);
				setCreatedIndex(-1);
			}, 5000);
		}
	}, [createdGroup, groupData, props]);

	// Add robots to group
	useEffect(() => {
		let devices: any = [];

		let devicesThatHaveGroups: any = [];

		if (deviceGroupsByOrganizationId) {
			Object.keys(deviceGroupsByOrganizationId).forEach(orgIdKey => {
				const deviceGroupsByOrg = deviceGroupsByOrganizationId[orgIdKey];

				Object.keys(deviceGroupsByOrg).forEach(deviceGroupIdKey => {
					const deviceGroup = deviceGroupsByOrg[deviceGroupIdKey];

					if (deviceGroup.devicesIds && deviceGroup.devicesIds.length > 0) {
						deviceGroup.devicesIds.forEach((deviceId: any) => {
							devicesThatHaveGroups.push(deviceId);
						});
					}
				});
			});
		}

		if (selectedGroup && selectedGroup.devicesIds) {
			devices = devicesByOrganizationId.filter(
				(d: any) =>
					selectedGroup.devicesIds.findIndex((id: string) => id === d.deviceId) < 0 &&
					!devicesThatHaveGroups.find((id: string) => id === d.deviceId)
			);
		}

		if (addDevicesOpen) {
			if (!selectedGroup) return;
			setAvailableRobots(
				devicesByOrganizationId
					? !selectedGroup.devicesIds
						? devicesByOrganizationId
						: devices
					: []
			);
			setAddRobotsModal(true);
		} else {
			// Get available devices
			setAvailableRobots(devicesByOrganizationId ? devicesByOrganizationId : []);
			setAddRobotsModal(false);
		}
	}, [addDevicesOpen, selectedGroup, devicesByOrganizationId]);

	const goToGroup = useCallback(
		(group: any) => {
			history.push({
				pathname: '/fleetManagement',
				data: { tab: 'userGroups', orgId: orgId, editableUserGroup: group },
			});
		},
		[history, orgId]
	);

	const formatTemplate = useCallback(
		(item: any, property: string, index: number) => {
			switch (property) {
				case 'name':
					return (
						<div className={classes.nameContainer}>
							<IonAvatar className={classes.groupAvatar}>
								{item.pictureLink ? (
									<img
										className={classes.groupPic}
										src={item.pictureLink}
										alt="Avatar"
									/>
								) : (
									<IonIcon
										className={classNames(classes.profilePic)}
										size="large"
										icon={robotGroupAvatar}
									/>
								)}
							</IonAvatar>
							<IonLabel className={classes.nameLb}>
								<EllipsisText text={item?.name} />
							</IonLabel>
						</div>
					);
				case 'userGroups':
					if (item.userGroups?.length > 1) {
						return (
							<IonLabel className={classes.groupNameLb}>
								<Tooltip
									direction="up"
									content={
										<ul className={classes.groupNameItem}>
											{item.userGroups.map((gr: any, i: number) => {
												return (
													<div
														key={i}
														className={classes.userGroupTooltip}
														onClick={(event: any) => {
															event.stopPropagation();
															goToGroup(gr);
														}}
													>
														<li className={classes.userGroupTitle}>
															<IonLabel>{gr.name}</IonLabel>
															<br />
														</li>
													</div>
												);
											})}
										</ul>
									}
								>
									{item.userGroupName}
								</Tooltip>
							</IonLabel>
						);
					} else
						return (
							<div
								className={classes.groupLink}
								onClick={(event: any) => {
									event.stopPropagation();
									if (item.userGroups.length > 0) goToGroup(item.userGroups[0]);
								}}
							>
								{item.userGroupName?.length > 15
									? item?.userGroupName.substring(0, 15) + '...'
									: item?.userGroupName}
							</div>
						);
				case 'createdAt':
					if (createdIndex === index) {
						return (
							<IonLabel className={classes.createdUserGroup} color="primary">
								<FormattedMessage {...Messages.justCreated} />
							</IonLabel>
						);
					} else if (item.createdAt) {
						const now = Date.now(); // for testing => new Date('2020-04-27T10:03:48.114Z').getTime();
						const nowDt = new Date(now);
						const dateJoined = new Date(item.createdAt);

						const yearDiff = Math.abs(
							dateJoined.getUTCFullYear() - nowDt.getUTCFullYear()
						);

						if (yearDiff > 0) {
							return (
								<FormattedMessage
									{...Messages.yearsAgo}
									values={{ number: yearDiff }}
								/>
							);
						} else return formatTimeAgo(dateJoined, now);
					} else return null;
				case 'devicesIds':
					const s: any = new Set(item[property]);
					item[property] = [...s];
					return (
						<IonLabel className={classes.robotsCount}>
							{item[property] ? item[property].length : null}
						</IonLabel>
					);
				default:
					return item[property];
			}
		},
		[createdIndex, goToGroup]
	);

	const onEdit = (group: any) => {
		setIsEditing(prev => !prev);
		setSelectedGroup(group);
	};

	const onCancelEdit = () => {
		props.setParameter('addRobotsCount', 'DEVICES_ADDED_TO_GROUP_STATE', 0);
		// setIsEditing(false);
		setEditGroupModal(false);
	};

	const onCreate = (data: any, selectedItems: any) => {
		data.name = data.name.trim();
		if (selectedItems.length > 0 && orgId) {
			//make sure to remove robots that is already a part of a device group from the old group.
			const groupedDevices: Record<string, Array<string>> = {};
			const devices = store.getState().deviceState.devicesByOrganizationId[orgId];
			selectedItems.forEach((element: any) => {
				const e = devices[element.deviceId] as Device;
				if (e && e.deviceGroupsIds) {
					if (Array.isArray(groupedDevices[e.deviceGroupsIds[0]]))
						groupedDevices[e.deviceGroupsIds[0]].push(e.deviceId);
					else groupedDevices[e.deviceGroupsIds[0]] = [e.deviceId];
				}
			});
			for (const key in groupedDevices) {
				publish(`microservice/${orgId}/${encodedUser}/updateDeviceGroupDevices`, {
					requestId: 'updateDeviceGroupDevicesId',
					data: {
						deviceGroupId: key,
						remove: groupedDevices[key],
					},
				});
			}
		}

		const robotGroupId = timeUuid();
		publish(`microservice/${orgId}/${encodedUser}/createDeviceGroup`, {
			data: {
				deviceGroupId: robotGroupId,
				name: data.name,
				pictureLink: null,
				devicesIds:
					selectedItems.length > 0 ? selectedItems.map((u: any) => u.deviceId) : null,
			},
			requestId: 'newDeviceGroup',
		});

		props.setParameter('createdGroup', 'CREATED_NEW_DEVICE_GROUP', {
			deviceGroupId: robotGroupId,
			name: data.name,
		});
		setCreateModal(false);
	};

	const openAddRobots = (group: any) => {
		props.setParameter('deviceGroups', 'CHANGE_ADD_DEVICES_TO_GROUP_STATE', true);
		setSelectedGroup(group);
	};
	const onAddRobots = (selectedItems: any) => {
		publish(`microservice/${orgId}/${encodedUser}/updateDeviceGroupDevices`, {
			data: {
				deviceGroupId: selectedGroup.deviceGroupId,
				add: selectedItems.map((u: any) => u.deviceId),
			},
			requestId: 'updateDeviceGroup',
		});
		props.setParameter('deviceGroups', 'CHANGE_ADD_DEVICES_TO_GROUP_STATE', false);

		if (selectedItems.length > 0) {
			props.setParameter(
				'addRobotsCount',
				'DEVICES_ADDED_TO_GROUP_STATE',
				selectedItems.length
			);
		}
	};

	const onCloseEdit = () => {
		setIsEditing(false);
		setSelectedGroup(null);
	};

	const onRemoval = () => {
		setEditGroupModal(false);
		setIsEditing(false);
		setSelectedGroup(null);
	};

	const formatData = useCallback(
		(data: any) => {
			return data.map((d: any) => {
				// userGroups & userGroupName
				let groups: any[] = [];
				if (d.userGroupsIds) {
					for (let userGroupId of d.userGroupsIds) {
						if (
							userGroupsByOrganizationId != null &&
							userGroupsByOrganizationId[orgId] != null &&
							userGroupsByOrganizationId[orgId][userGroupId] != null
						) {
							groups.push(userGroupsByOrganizationId[orgId][userGroupId]);
						}
					}
				}
				d.userGroups = groups;
				d.userGroupName =
					groups.length > 1
						? translationWithErrorHandling(intl, 'nrGroups')
						: groups.length > 0
						? groups[0].name
						: '';
				return d;
			});
		},
		[intl, orgId, userGroupsByOrganizationId]
	);

	const openCreateModal = () => {
		let deviceGroupsByOrganizationId = deviceGroupsState;
		let takenNames: string[] = [];
		if (deviceGroupsByOrganizationId[selectedOrganizationId]) {
			Object.values(deviceGroupsByOrganizationId[selectedOrganizationId]).forEach(element => {
				takenNames.push(element.name);
			});
		}
		setAlreadyTakenNames(takenNames);
		setCreateModal(true);
	};

	return (
		<>
			{!isEditing ? (
				<Fragment>
					<div className={classes.leftSide}>
						<div className={classes.createBtnContainer}>
							<IonIcon
								slot="icon-only"
								color="primary"
								className={classes.createIcon}
								src="./assets/icons/robotgroup-add.svg"
							/>
							<IonLabel color="primary" className={classes.createLb}>
								<FormattedMessage id="UserGroups.create" />
							</IonLabel>
							<IonButton
								className={classes.createBtn}
								shape="round"
								onClick={() => openCreateModal()}
								data-cy="create_robot_group_button"
							>
								<IonIcon slot="icon-only" size="small" icon={add} />
							</IonButton>
						</div>
						{createdGroup ? (
							<IonLabel className={classes.createdUserGroup}>
								<IonIcon
									slot="end"
									size="small"
									color="primary"
									icon={checkmarkCircleOutline}
								/>
								<FormattedMessage
									{...Messages.created}
									values={{ name: <span>{createdGroup.name}</span> }}
								/>
							</IonLabel>
						) : null}
						<SearchBar
							searchText={searchText}
							placeholder={translationWithErrorHandling(intl, 'RobotsGroup.search')}
							onChange={(value: any) => setSearchText(value)}
						/>
					</div>
					<div className={classes.rightSide}>
						<ListGrid
							id="robot-group-list-grid"
							headers={headers}
							data={sortedCollection({
								items: groupData ? formatData(Object.values(groupData)) : [],
								sortParams: sortParams,
							})}
							pageSize={pageSize}
							itemTemplate={(item, property, index) => {
								return formatTemplate(item, property, index);
							}}
							moreContent={(item: any, onClosePopover: any) => {
								return (
									<>
										<StyledMenuItem onClick={() => onEdit(item)}>
											<FormattedMessage {...Messages.edit} />
										</StyledMenuItem>
										<Divider />
										<StyledMenuItem
											onClick={() => {
												openAddRobots(item);
												onClosePopover();
											}}
										>
											<FormattedMessage {...Messages.addRobots} />
										</StyledMenuItem>
									</>
								);
								return (
									<table className={classes.moreList}>
										<tbody>
											<tr>
												<td className={classes.itemContent}>
													<IonLabel
														className={classes.itemLb}
														onClick={() => onEdit(item)}
													>
														<FormattedMessage {...Messages.edit} />
													</IonLabel>
												</td>
											</tr>
											<tr>
												<td className={classes.itemContent}>
													<IonLabel
														className={classes.itemLb}
														onClick={() => openAddRobots(item)}
													>
														<FormattedMessage {...Messages.addRobots} />
													</IonLabel>
												</td>
											</tr>
										</tbody>
									</table>
								);
							}}
							onRowClick={onEdit}
							createdIndex={createdIndex}
							sortType="SET_DEVICE_GROUPS_SORT_PARAMS"
							listType="Robot Groups"
						/>
					</div>
					{showCreateModal && (
						<CreateModal
							isOpen={showCreateModal}
							showHeaderIcon
							headerIcon="./assets/icons/robotgroup-add.svg"
							itemIcon={deviceIcons[spinoutType]}
							itemIconStyle={{ backgroundColor: 'black' }}
							title={<FormattedMessage {...Messages.createGroup} />}
							titleHint={<FormattedMessage {...Messages.createGroupHint} />}
							availableTitle={<FormattedMessage {...Messages.availableRobots} />}
							selectedTitle={<FormattedMessage {...Messages.selectedRobots} />}
							selectedTxt={Messages.robotsSelected}
							noSelection={Messages.noSelection}
							noAvailable={Messages.noAvailable}
							searchMessageId="RobotsGroup.searchRobots"
							orgId={orgId}
							initialData={selectedGroup}
							nameProperty="name"
							takenNames={alreadyTakenNames}
							availableItems={availableRobots}
							onCreate={onCreate}
							onDismiss={() => setCreateModal(false)}
						/>
					)}
				</Fragment>
			) : (
				<RobotGroupEdit
					group={selectedGroup}
					onClose={onCloseEdit}
					onEditOpen={() => setEditGroupModal(true)}
					history={history}
				/>
			)}
			{showAddRobotsModal && (
				<AddItemsModal
					isOpen={showAddRobotsModal}
					title={<FormattedMessage {...Messages.editGroupModal.addRobots} />}
					titleHint={
						<FormattedMessage
							{...Messages.addToGroupModal.addToGroupsHint}
							values={{
								group: selectedGroup ? (
									<span className={classes.groupName}>{selectedGroup.name}</span>
								) : (
									''
								),
							}}
						/>
					}
					availableTitle={<FormattedMessage {...Messages.availableRobots} />}
					selectedTitle={<FormattedMessage {...Messages.selectedRobots} />}
					selectedTxt={Messages.robotsSelected}
					noSelection={Messages.noSelection}
					searchMessageId="RobotsGroup.searchRobots"
					orgId={orgId}
					initialData={selectedGroup}
					nameProperty="name"
					availableItems={availableRobots}
					noAvailable={Messages.noAvailable}
					addBtnTitle={Messages.addRobots}
					onAdd={onAddRobots}
					onDismiss={() =>
						props.setParameter(
							'deviceGroups',
							'CHANGE_ADD_DEVICES_TO_GROUP_STATE',
							false
						)
					}
				/>
			)}
			<EditGroupModal
				isOpen={showEditGroupModal}
				onDismiss={onCancelEdit}
				onRemove={onRemoval}
				orgId={orgId}
				initialGroup={selectedGroup}
			/>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	// collection: sortedCollection(state.deviceGroupsState),
	// sortParams: state.usersState.sortParams,
	deviceGroups: state.deviceGroupsState,
	userGroups: state.userGroupsState,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(RobotGroups), 'RobotGroups'));
