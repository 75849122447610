import { IonButtons, IonHeader, IonMenuButton, IonToolbar } from '@ionic/react';
import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { injectIntl, useIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import classes from './Header.module.css';
import HeaderList from './HeaderList';
import { useTypedSelector } from '../../reducers';

import styled from 'styled-components';
import { publish } from '../../actions/publish';
import { RecreateMqttClient, signOut, subscribe, subscribedDevices } from '../../providers/mqtt';
import { store } from '../../store/store';
import { b64EncodeUnicode } from '../../utils/encoding';
import StayLoginModal from '../StayLoginModal/StayLoginModal';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { options, CountryOption, shorthandTable } from '../../translation/defs';

const StyledTextField = styled(TextField)(() => ({
	'& .MuiInput-underline:before': {
		borderBottom: 'none',
	},
	'& .MuiInput-underline:after': {
		borderBottom: 'none',
	},
	'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
		borderBottom: 'none',
	},
}));

const StyledAutocomplete = styled(Autocomplete)({
	'& .MuiAutocomplete-listbox': {
		backgroundColor: 'lightgrey',
	},
	'&:hover': {
		backgroundColor: 'transparent',
	},
	'& .MuiInputBase-root': {
		width: '250px',
	},
});

interface HeaderProps extends WrappedComponentProps, RouteComponentProps {}

const Header: FC<HeaderProps> = (props: any) => {
	const intl = useIntl();
	const user = useTypedSelector(state => state.accountState.user);
	let encodedUser = window.btoa(user.username);

	const [showHeader, setShowHeader] = useState(true);
	const { location } = props;
	const [searchText, setSearchText] = useState('');
	const [isSearchHidden, setIsSearchHidden] = useState(true);
	const [searchBarValue, setSearchBarValue] = useState('');
	const [hashEventExists, setHashEventExists] = useState(false);
	const [showStayLoginModal, setShowStayLoginModal] = useState(false);
	const [stayLoginDetails, setStayLoginDetails]: any = useState();
	const [selectedLanguageOption, setSelectedLanguageOption] = useState<CountryOption>(
		options.find(
			option => shorthandTable[option.value as keyof typeof shorthandTable] === intl.locale
		) || options[0]
	);

	useEffect(() => {
		if (!hashEventExists && location.pathname.includes('/gobe')) {
			setHashEventExists(true);
			window.addEventListener('hashchange', () => {
				// if we are in the session page (either legacy or new one with hardware acceleration)
				if (window.location.pathname.includes('/gobe/session')) {
					setShowHeader(false);
				} else {
					setShowHeader(true);
				}
			});
		}
	}, [hashEventExists, location.pathname]);

	const handleStayLoginSessionEvent = (event: any) => {
		setShowStayLoginModal(true);
		if (event.detail) {
			setStayLoginDetails(event.detail);
		}
	};

	useEffect(() => {
		updateUserLanguage(user.language); // In case language was changed on login screen

		window.addEventListener('stayLoginSession', handleStayLoginSessionEvent, false);
		return () => {
			document.removeEventListener('stayLoginSession', handleStayLoginSessionEvent, false);
		};
	}, []);

	const onConfirmStayLogin = () => {
		if (stayLoginDetails && showStayLoginModal) {
			RecreateMqttClient();
			subscribe(stayLoginDetails.client, stayLoginDetails?.userName.toLowerCase());
			// Flag subscribed devices to false for re subscription on refresh end
			Object.keys(subscribedDevices).forEach(deviceId => {
				(subscribedDevices as { [id: string]: boolean })[deviceId] = false;
			});
			const accountState = store.getState().accountState;
			publish(
				`microservice/${b64EncodeUnicode(accountState.user.username)}/getOwnAccountInfo`,
				JSON.stringify({ requestId: 'someId' })
			);
			setShowStayLoginModal(false);
		}
	};

	const onDismissStayLogin = () => {
		if (stayLoginDetails && showStayLoginModal) {
			signOut(stayLoginDetails?.client);
			store.dispatch({
				type: 'SIGN_OUT_USER',
				payload: {},
			});
			setShowStayLoginModal(false);
			window.location.reload();
			console.debug(`Client ${stayLoginDetails?.clientID} has been DISCONNECTED from broker`);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, false);
		return () => {
			document.removeEventListener('click', handleClickOutside, false);
		};
	}, []);

	const handleClickOutside = (event: any) => {
		if (
			event != null &&
			event.toElement != null &&
			event.toElement.className != null &&
			event.toElement.className !== 'searchbar-input sc-ion-searchbar-md'
		) {
			setIsSearchHidden(true);
			setSearchBarValue('');
		}
	};

	const hide = () => {
		setIsSearchHidden(true);
	};

	const clearText = () => {
		console.log('clearText');
		setSearchBarValue('');
		setSearchText('');
	};

	const updateUserLanguage = (language: any) => {
		const dataObj = {
			user: user,
			updates: {
				language: language,
			},
		};

		user.language = language;
		(window as any).changeAppLanguage(shorthandTable[language as keyof typeof shorthandTable]);

		publish(`microservice/${encodedUser}/updateOwnInfo`, {
			data: dataObj,
			requestId: 'updateOwnInfoId',
		});
	};
	useEffect(() => {
		console.log('Language changed to: ', selectedLanguageOption.value);
		updateUserLanguage(selectedLanguageOption.value);
	}, [selectedLanguageOption]);

	if (!showHeader) {
		return <div />;
	} else
		return (
			<IonHeader>
				<StayLoginModal
					isOpen={showStayLoginModal}
					onDismiss={onDismissStayLogin}
					onConfirm={onConfirmStayLogin}
				/>
				<IonToolbar color="grey">
					<IonButtons style={{ justifyContent: 'right' }}>
						<div
							style={{
								width: '250px',
							}}
						>
							<StyledAutocomplete
								disableClearable={true}
								value={selectedLanguageOption}
								options={options}
								getOptionLabel={(option: any) => option.label}
								renderOption={(option: any) => (
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											width: '100%',
										}}
									>
										<img
											src={option.image}
											alt={option.label}
											style={{ width: 30, height: 20 }}
										/>
										<span>{option.label}</span>
									</div>
								)}
								onChange={(event, value: any) => {
									if (value != null) {
										setSelectedLanguageOption(value);
									}
								}}
								renderInput={params => (
									<StyledTextField
										{...params}
										placeholder="Select Language"
										variant="standard"
										InputProps={{
											...params.InputProps,
											startAdornment: selectedLanguageOption ? (
												<img
													src={selectedLanguageOption.image}
													alt={selectedLanguageOption.label}
													style={{
														width: 30,
														height: 20,
														marginRight: 10,
													}}
												/>
											) : null,
										}}
									/>
								)}
								style={{ width: 200 }}
							/>
						</div>
						<div className={classes.logo} />
					</IonButtons>
				</IonToolbar>
				{isSearchHidden ? null : (
					<HeaderList
						hideList={isSearchHidden}
						setHideList={hide}
						inputValue={searchBarValue}
						clearText={clearText}
					/>
				)}
			</IonHeader>
		);
};

export default injectIntl(withRouter(Header));
