import React, { FC, useState, useMemo } from 'react';
import {
	IonIcon,
	IonLabel,
	IonList,
	IonListHeader,
	IonInput,
	IonCol,
	IonRow,
	IonGrid,
	IonButton,
	IonText,
} from '@ionic/react';
import { checkmarkCircle } from 'ionicons/icons';
import { injectIntl, FormattedMessage } from 'react-intl';
import isAuthenticated from '../Authentication/Authenticated';
import { useForm } from 'react-hook-form';
import { useFormik } from 'formik';
import classes from './ProfileSettings.module.css';

import Messages from './ProfileSettings.messages';
import passwordMsg from '../PasswordRequirements/PasswordRequirements.messages';
import { eyeSharp, eyeOffSharp } from 'ionicons/icons';
import { useTypedSelector } from '../../reducers';
import { publish } from '../../actions/publish';
import {
	checkOneLowerCase,
	checkOneNumeric,
	checkOneUpperCase,
	checkPassword,
	checkLength,
} from '../../utils/validator';
import PasswordRequirementPopper from '../PasswordRequirementPopper/PasswordRequirementPopper';

import { TextField, makeStyles } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { Snackbar } from '../Snackbar';
import { translationWithErrorHandling } from '../../translation/handleMissingTranslation';

interface ChangePasswordSubmenuProps {
	goTo?: () => void;
}
const StyledWrapper = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
}));
const StyledIconsFlex = styled('div')(() => ({
	marginBottom: 3,
	display: 'flex',
	flexDirection: 'row',
}));
const StyledTextWrapper = styled('div')(() => ({
	marginBottom: 21,
	display: 'flex',
	flexDirection: 'column',
}));
const StyledTextField = styled(TextField)(() => ({
	'&.Mui-focused': {
		color: '#56AE4D',
	},
	'& .MuiInput-underline:before': {
		borderBottomColor: '#eeeeee',
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: '#eeeeee',
	},
}));

const useStyles = makeStyles({
	valueLabel: {
		color: '#56AE4D',
		'&.Mui-focused': {
			color: '#56AE4D',
		},
	},
	noValueLabel: {
		color: '1E1F22',
		'&.Mui-focused': {
			color: '#56AE4D',
		},
	},
	errorColor: {
		color: 'var(--form-field-error-color)',
		paddingTop: '5px',
	},
});
const StyledCircleIcon = styled('div')(() => ({
	marginRight: 5,
}));
type UserFrom = {
	password: string;
	retypedPassword: string;
};

const ChangePasswordSubmenu: FC<ChangePasswordSubmenuProps> = (props: any) => {
	const { intl } = props;
	const user = useTypedSelector(state => state.accountState.user);

	const [showPasswordText, setShowPasswordText] = useState(false);
	const [showReTypePasswordText, setShowRetypePasswordText] = useState(false);
	const [passwordSavedSnackBarOpen, setPasswordSavedSnackbarOpen] = useState(false);

	const username = useTypedSelector(state => state.accountState.user.username);

	let encodedUser = window.btoa(username);

	const isSso = useTypedSelector(state => state.accountState.user.isSso);

	const formik = useFormik({
		initialValues: {
			password: '',
			retypedPassword: '',
		},

		validate: values => {
			let errors: any = {};
			if (values.password == '' || values.password.length < 8) {
				errors.password = translationWithErrorHandling(intl, 'ConfirmPage.passwordLength');
			} else if (!checkOneLowerCase(values.password)) {
				errors.password = translationWithErrorHandling(
					intl,
					'ConfirmPage.passwordLowerCase'
				);
			} else if (!checkOneNumeric(values.password)) {
				errors.password = translationWithErrorHandling(intl, 'ConfirmPage.passwordNumeric');
			} else if (!checkOneUpperCase(values.password)) {
				errors.password = translationWithErrorHandling(
					intl,
					'ConfirmPage.passwordUpperCase'
				);
			}
			if (values.retypedPassword == '' || values.retypedPassword.length < 8) {
				errors.retypedPassword = translationWithErrorHandling(
					intl,
					'ConfirmPage.passwordLength'
				);
			} else if (values.retypedPassword !== values.password) {
				errors.retypedPassword = translationWithErrorHandling(
					intl,
					'ConfirmPage.passwordNoMatch'
				);
			}
			return errors;
		},
		onSubmit: (values: any) => {
			updateUserProfile(values);
			// resetPassword(values);
		},
	});

	const updateUserProfile = (values: any) => {
		const dataObj = {
			user: user,
			updates: {},
		};
		const password = values.password;
		const retypedPassword = values.retypedPassword;

		if (password && retypedPassword && password === retypedPassword) {
			publish(`microservice/${encodedUser}/updatePassword`, {
				data: { username: username, password: retypedPassword },
				requestId: 'updatePasswordId',
			});
			window.localStorage.setItem('psw', window.btoa(retypedPassword));
			formik.resetForm();
		}

		publish(`microservice/${encodedUser}/updateOwnInfo`, {
			data: dataObj,
			requestId: 'updateOwnInfoId',
		});
		setPasswordSavedSnackbarOpen(true);
	};
	const [passwordAnchorEl, setPasswordAnchorEl] = useState(null);
	const [passwordPopperVisible, setPasswordPopperVisible] = useState(false);

	const togglePasswordEye = () => {
		setShowPasswordText(!showPasswordText);
	};
	const toggleRetypePasswordEye = () => {
		setShowRetypePasswordText(!showReTypePasswordText);
	};

	const handleFocus = (event: any, fieldType: any) => {
		if (fieldType === 'password') {
			setPasswordAnchorEl(event.currentTarget);
			setPasswordPopperVisible(true);
		}
	};
	const passwordListValues = useMemo(() => {
		let passwordValue = formik.values.password;
		return [
			{
				value: <FormattedMessage {...passwordMsg.passwordLength} />,
				valid: checkLength(passwordValue),
			},
			{
				value: <FormattedMessage {...passwordMsg.passwordLowerCase} />,
				valid: checkOneLowerCase(passwordValue),
			},
			{
				value: <FormattedMessage {...passwordMsg.passwordUpperCase} />,
				valid: checkOneUpperCase(passwordValue),
			},
			{
				value: <FormattedMessage {...passwordMsg.passwordNumeric} />,
				valid: checkOneNumeric(passwordValue),
			},
		];
	}, [formik.values.password]);

	const handleBlur = (fieldType: any) => {
		if (fieldType === 'password') {
			setPasswordAnchorEl(null);
			setPasswordPopperVisible(false);
		}
	};

	const Class = useStyles();
	return (
		<div>
			<Snackbar
				open={passwordSavedSnackBarOpen}
				onClose={() => setPasswordSavedSnackbarOpen(false)}
				message={translationWithErrorHandling(intl, 'ConfirmPage.changesSaved')}
				snackbarType="success"
			/>
			<IonGrid className={classes.formGrid}>
				<IonRow>
					<IonList className="ion-padding">
						<IonListHeader>
							<IonLabel className={classes.labelFont}>
								<FormattedMessage {...Messages.changePasswordSettings} />
							</IonLabel>
						</IonListHeader>
					</IonList>
				</IonRow>
				<IonRow>
					<IonCol className={classes.firstCol}>
						<IonList>
							<StyledWrapper>
								{!isSso && (
									<StyledWrapper>
										<StyledTextWrapper>
											<StyledTextField
												style={{ marginTop: 14 }}
												type={showPasswordText ? 'text' : 'password'}
												label={translationWithErrorHandling(
													intl,
													'fmSettings.enterNewPassword'
												)}
												name="password"
												onFocus={e => handleFocus(e, 'password')}
												onBlur={e => handleBlur('password')}
												onChange={e => {
													formik.handleChange(e);
												}}
												placeholder="Enter your new password"
												value={formik.values.password}
												InputLabelProps={{
													className: formik.values.password
														? Class.valueLabel
														: Class.noValueLabel,
												}}
												InputProps={{
													endAdornment: (
														<StyledIconsFlex>
															{!formik.errors.password &&
																formik.values.password.length >
																	0 && (
																	<StyledCircleIcon>
																		<IonIcon
																			size="small"
																			color="primary"
																			icon={checkmarkCircle}
																		/>
																	</StyledCircleIcon>
																)}
															<StyledCircleIcon>
																<IonIcon
																	size="small"
																	onClick={togglePasswordEye}
																	icon={
																		showPasswordText
																			? eyeOffSharp
																			: eyeSharp
																	}
																/>
															</StyledCircleIcon>
														</StyledIconsFlex>
													),
												}}
											/>
											{formik?.errors?.password && (
												<IonText className={Class.errorColor}>
													<FormattedMessage
														id="changePassword.password"
														defaultMessage={formik?.errors?.password}
													/>
												</IonText>
											)}
										</StyledTextWrapper>
										<PasswordRequirementPopper
											anchorEl={passwordAnchorEl}
											open={passwordPopperVisible}
											valueList={passwordListValues}
										/>

										<StyledTextWrapper>
											<StyledTextField
												type={showReTypePasswordText ? 'text' : 'password'}
												label={translationWithErrorHandling(
													intl,
													'fmSettings.enterNewPasswordAgain'
												)}
												name="retypedPassword"
												onChange={e => {
													formik.handleChange(e);
												}}
												value={formik.values.retypedPassword}
												InputLabelProps={{
													className: formik.values.retypedPassword
														? Class.valueLabel
														: Class.noValueLabel,
												}}
												placeholder="Enter your password again"
												InputProps={{
													endAdornment: (
														<StyledIconsFlex>
															{!formik.errors.retypedPassword &&
																formik.values.retypedPassword
																	.length > 0 && (
																	<StyledCircleIcon>
																		<IonIcon
																			size="small"
																			color="primary"
																			icon={checkmarkCircle}
																		/>
																	</StyledCircleIcon>
																)}
															<StyledCircleIcon>
																<IonIcon
																	size="small"
																	onClick={
																		toggleRetypePasswordEye
																	}
																	icon={
																		showReTypePasswordText
																			? eyeOffSharp
																			: eyeSharp
																	}
																/>
															</StyledCircleIcon>
														</StyledIconsFlex>
													),
												}}
											/>
											{formik?.errors?.retypedPassword && (
												<IonText className={Class.errorColor}>
													<FormattedMessage
														id="changePassword.retypedPassword"
														defaultMessage={
															formik?.errors?.retypedPassword
														}
													/>
												</IonText>
											)}
										</StyledTextWrapper>
									</StyledWrapper>
								)}
							</StyledWrapper>
						</IonList>
					</IonCol>
					<IonCol></IonCol>
				</IonRow>
				<IonRow className={classes.btnRow}>
					<IonCol className={classes.submitCol}>
						<IonButton
							expand="block"
							shape="round"
							type="submit"
							size="large"
							fill="outline"
							onClick={() => formik.handleSubmit()}
						>
							<FormattedMessage {...Messages.save} />
						</IonButton>
					</IonCol>
				</IonRow>
			</IonGrid>
		</div>
	);
};

export default injectIntl(isAuthenticated(ChangePasswordSubmenu, 'ChangePasswordSubmenu'));
