import { AcceptedAgreementsPayload, AgreementsPayload, Account } from '../reducers/accountReducers';

export const getRole = (accountRoles: Account['roles']) => {
	const roles = accountRoles;
	let roleLevel = 0;
	let role = null;
	if (roles === null || roles === undefined) return role;
	for (const key in roles) {
		if (roles[key].roleId.match(/borSuperAdmin|borAdmin/g) && roleLevel < 4) {
			role = roles[key].roleId;
			roleLevel = 4;
		}
		if (roles[key].roleId.match(/spinoutSuperAdmin|spinoutAdmin/g) && roleLevel < 3) {
			role = roles[key].roleId;
			roleLevel = 3;
		}
		if (
			roles[key].roleId.match(/solutionProviderSuperAdmin|solutionProviderAdmin/g) &&
			roleLevel < 2
		) {
			role = roles[key].roleId;
			roleLevel = 2;
		}
		if (roles[key].roleId.match(/customerSuperAdmin|customerAdmin/g) && roleLevel < 1) {
			role = roles[key].roleId;
			roleLevel = 1;
		}
	}

	return role;
};

export const getCurrentRolesOrgId = (account: Account) => {
	const roles = account.roles;
	let roleLevel = 0;
	let organizationId = null;
	if (roles === null || roles === undefined) return organizationId;
	for (const key in roles) {
		if (roles[key].roleId.match(/borSuperAdmin|borAdmin/g) && roleLevel < 4) {
			organizationId = roles[key].orgId;
			roleLevel = 4;
		}
		if (roles[key].roleId.match(/spinoutSuperAdmin|spinoutAdmin/g) && roleLevel < 3) {
			organizationId = roles[key].orgId;
			roleLevel = 3;
		}
		if (
			roles[key].roleId.match(/solutionProviderSuperAdmin|solutionProviderAdmin/g) &&
			roleLevel < 2
		) {
			organizationId = roles[key].orgId;
			roleLevel = 2;
		}
		if (roles[key].roleId.match(/customerSuperAdmin|customerAdmin/g) && roleLevel < 1) {
			organizationId = roles[key].orgId;
			roleLevel = 1;
		}
	}
	return organizationId;
};

export const isAgreementsAccepted = (user: any) => {
	const agreementIds: Record<string, boolean> = {};
	const acceptedAgreementIds: Record<string, boolean> = {};

	user.acceptedAgreements.forEach((element: AcceptedAgreementsPayload) => {
		acceptedAgreementIds[element.agreementId] = true;
	});
	user.agreements.forEach((element: AgreementsPayload) => {
		agreementIds[element.id] = true;
	});

	for (const key in agreementIds) {
		if (key.includes('safety')) continue;
		if (acceptedAgreementIds[key] !== true) return false;
	}

	return true;
};
