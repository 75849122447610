import { injectIntl } from 'react-intl';
import React, { FC, useEffect, useState } from 'react';
import isAuthenticated from '../Authentication/Authenticated';
import { IonList } from '@ionic/react';
import FormInputListItem from '../FormInputListItem/FormInputListItem';

import classes from './AddNewRobot.module.css';
import classNames from 'classnames';
import { useTypedSelector } from '../../reducers';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';

import { eyeSharp, eyeOffSharp } from 'ionicons/icons';
import { RobotPasswordRequirements } from '../PasswordRequirements/PasswordRequirements';
import { InputLimit } from '../../utils/validator';
import { translationWithErrorHandling } from '../../translation/handleMissingTranslation';

interface AddNewRobotFromProps {
	control: any;
	errors: any;
}

const AddNewRobotForm: FC<AddNewRobotFromProps> = (props: any) => {
	const { intl, control, watch, errors } = props;
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType);
	const [passwordType, setPasswordType] = useState(true);
	const password = watch('robotPassword');
	const displayPassword = () => {
		setPasswordType(prev => !prev);
	};

	return (
		<IonList className={classNames(classes.formList)}>
			<FormInputListItem
				type="text"
				name="name"
				label={translationWithErrorHandling(intl, 'RobotsPage.name')}
				placeholderText={translationWithErrorHandling(intl, 'RobotsPage.nameHint')}
				control={control}
				rules={{ required: true }}
				required
				errorMsg={
					(errors.name && translationWithErrorHandling(intl, 'RobotsPage.nameError')) ||
					(errors.nameUnique &&
						translationWithErrorHandling(intl, 'RobotsPage.nameNotUnique'))
				}
				maxLength={InputLimit}
				dataCy="name"
			/>
			{spinoutType !== 'uvd' ? (
				<>
					<FormInputListItem
						type="text"
						name="saId"
						label={translationWithErrorHandling(intl, 'RobotsPage.serviceAccessId')}
						placeholderText={translationWithErrorHandling(
							intl,
							'RobotsPage.serviceAccessIdHint'
						)}
						control={control}
						rules={{ required: true }}
						required
						errorMsg={
							errors.saId &&
							translationWithErrorHandling(intl, 'RobotsPage.serviceAccessIdError')
						}
						pattern="[0-9a-zA-Z_.-]*"
						maxLength={InputLimit}
						dataCy="saId"
					/>
					<FormInputListItem
						type="text"
						name="authId"
						label={translationWithErrorHandling(intl, 'RobotsPage.authenticationId')}
						placeholderText={translationWithErrorHandling(
							intl,
							'RobotsPage.authenticationIdHint'
						)}
						control={control}
						rules={{ required: true }}
						required
						errorMsg={
							errors.authId &&
							translationWithErrorHandling(intl, 'RobotsPage.authenticationIdError')
						}
						pattern="[0-9a-zA-Z_.-]*"
						maxLength={InputLimit}
						dataCy="authId"
					/>
				</>
			) : null}
			<FormInputListItem
				type="text"
				name="serialNumber"
				label={translationWithErrorHandling(intl, 'RobotsPage.serialNumber')}
				placeholderText={translationWithErrorHandling(intl, 'RobotsPage.serialNumberHint')}
				control={control}
				rules={{ required: true }}
				required
				errorMsg={
					errors.serialNumber
						? translationWithErrorHandling(intl, 'RobotsPage.serialNumberError')
						: errors.serialNumberUnique
						? translationWithErrorHandling(intl, 'RobotsPage.serialNumberUnique')
						: ''
				}
				pattern="[0-9a-zA-Z]*"
				title={translationWithErrorHandling(intl, 'RobotsPage.onlyLettersCharacters')}
				maxLength={InputLimit}
				dataCy="serialNumber"
			/>

			{spinoutType !== 'uvd' ? (
				<>
					<div>
						{password && (
							<RobotPasswordRequirements
								customContainer={classes.passwordTooltip}
								checkMarkContainer={classes.checkMarkContainer}
								password={password}
							/>
						)}
						<FormInputListItem
							type={passwordType ? 'password' : 'text'}
							name="robotPassword"
							label={translationWithErrorHandling(intl, 'RobotsPage.password')}
							placeholderText={translationWithErrorHandling(
								intl,
								'RobotsPage.passwordHint'
							)}
							control={control}
							rules={{ required: true }}
							required
							autocomplete="new-password"
							errorMsg={
								errors.robotPassword &&
								translationWithErrorHandling(intl, 'RobotsPage.passwordError')
							}
							iconUrl={passwordType ? eyeOffSharp : eyeSharp}
							iconCallback={displayPassword}
							maxLength={InputLimit}
							dataCy="password"
						/>
					</div>
				</>
			) : null}

			<FormInputListItem
				type="text"
				name="location"
				label={translationWithErrorHandling(intl, 'RobotsPage.location')}
				placeholderText={translationWithErrorHandling(intl, 'RobotsPage.locationHint')}
				control={control}
				pattern="[0-9a-zA-Z]*"
				title={translationWithErrorHandling(intl, 'RobotsPage.onlyLettersCharacters')}
				maxLength={InputLimit}
				dataCy="location"
			/>
		</IonList>
	);
};

const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
	devices: state.deviceState,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(AddNewRobotForm), 'AddNewRobotForm'));
