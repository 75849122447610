import React from 'react';
import { Device } from '../../../../../../types/types';
import { LaunchRow } from './LaunchRow';
import '../index.scss';
import './LaunchRow/index.scss';
import { EmptyListView } from './EmptyListView';
import { useIntl } from 'react-intl';
import { translationWithErrorHandling } from '../../../../../../translation/handleMissingTranslation';

export type RoasterDevicesViewProps = {
	devicesIds: string[];
	deviceGroupsNamesPerDeviceId: any;
	orgIdPerDeviceId: Record<string, string>;
	onClickStartSession: (device: Device) => void;
	publishFavouriteDevice: (device: Device) => void;
	preSessionState: { robotId: string; loading: boolean; error: unknown } | null;
	isLoading: boolean;
};

export const ListView = ({
	devicesIds,
	deviceGroupsNamesPerDeviceId,
	orgIdPerDeviceId,
	onClickStartSession,
	publishFavouriteDevice,
	preSessionState,
	isLoading,
}: RoasterDevicesViewProps) => {
	const intl = useIntl();
	return (
		<div className="rosterListViewContainer">
			<div className="rosterListHeader">
				<div className="rosterListCellXSmall"></div>
				<div className="rosterListHeaderCell rosterListRowCell">
					{translationWithErrorHandling(intl, 'roaster.name')}
				</div>
				<div className="rosterListHeaderCell rosterListRowCell">
					{translationWithErrorHandling(intl, 'roaster.status')}
				</div>
				<div className="rosterListHeaderCell rosterListRowCell">
					{translationWithErrorHandling(intl, 'roaster.batteryStatus')}
				</div>
				<div className="rosterListHeaderCell rosterListRowCell">
					{translationWithErrorHandling(intl, 'roaster.location')}
				</div>
				<div className="rosterListHeaderCell rosterListRowCell">
					{translationWithErrorHandling(intl, 'roaster.group')}
				</div>
			</div>
			{devicesIds.length > 0 ? (
				devicesIds.map(deviceId => (
					<LaunchRow
						key={deviceId}
						deviceId={deviceId}
						publishFavouriteDevice={publishFavouriteDevice}
						orgId={orgIdPerDeviceId[deviceId]}
						deviceGroupsNames={deviceGroupsNamesPerDeviceId[deviceId]}
						onClickStartSession={onClickStartSession}
						isCalling={
							preSessionState?.robotId === deviceId && preSessionState?.loading
						}
					/>
				))
			) : isLoading ? null : (
				<EmptyListView />
			)}
		</div>
	);
};
