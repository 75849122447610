import { LanguageOption } from '../types/types';

const LanguageOptions: LanguageOption[] = [
	{ labelId: 'LanguageOptions.english', value: 'en' },
	{ labelId: 'LanguageOptions.japanese', value: 'jp' },
	{ labelId: 'LanguageOptions.danish', value: 'dk' },
	// { labelId: 'LanguageOptions.arabic', value: 'ar' },
	// { labelId: 'LanguageOptions.german', value: 'de' },
	// { labelId: 'LanguageOptions.chinese', value: 'zh' },
];

export default LanguageOptions;
