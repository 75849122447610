import classNames from 'classnames';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { IoTodayOutline } from 'react-icons/io5';
import { Reservation } from '../../../../../../../../utils/statusConverter';
import './index.scss';
import { useIntl } from 'react-intl';
import { translationWithErrorHandling } from '../../../../../../../../../../../translation/handleMissingTranslation';

interface Props {
	othersNextReservation: Reservation | null | undefined;
	myNextReservation: Reservation | null | undefined;
	isPermanentDevice: boolean | undefined;
}

export const ReservationWithPopover = ({
	othersNextReservation,
	myNextReservation,
	isPermanentDevice,
}: Props) => {
	const intl = useIntl();
	const [showPopover, setShowPopover] = useState(false);

	const isOthersReservationWithinSevenDays = othersNextReservation
		? moment().isSameOrAfter(
				moment(othersNextReservation?.startDate).subtract(
					7,
					translationWithErrorHandling(intl, 'reservationWithPopover.days') as any
				),
				translationWithErrorHandling(intl, 'reservationWithPopover.day') as any
		  )
		: false;

	const showOthersReservations =
		!!othersNextReservation && isOthersReservationWithinSevenDays && isPermanentDevice;
	const showMyReservations = !!myNextReservation;

	const myNextReservationLabel = useMemo(() => {
		if (!myNextReservation) return null;

		const start = myNextReservation.startDate;
		const end = myNextReservation.endDate;

		switch (myNextReservation.status) {
			case 'beforeReservationDay':
				return {
					label: moment(start).format('D MMMM'),
					date: `${moment(start).format('h:mm')}-${moment(end).format('h:mma')}`,
				};
			case 'reservationDay':
				return {
					label: translationWithErrorHandling(intl, 'reservationWithPopover.today'),
					date: `${moment(start).format('h:mm')}-${moment(end).format('h:mma')}`,
				};
			case 'reservationTime':
				return {
					label: translationWithErrorHandling(intl, 'reservationWithPopover.now'),
					date:
						translationWithErrorHandling(intl, 'reservationWithPopover.until') +
						' ' +
						moment(end).format('h:mma'),
				};
			case 'inSession':
				return {
					label: translationWithErrorHandling(intl, 'reservationWithPopover.now'),
					date:
						translationWithErrorHandling(intl, 'reservationWithPopover.until') +
						' ' +
						moment(end).format('h:mma'),
				};
			default:
				return { label: '', date: '' };
		}
	}, [myNextReservation]);

	const othersNextReservationLabel = useMemo(() => {
		if (!othersNextReservation) return null;

		const start = othersNextReservation.startDate;
		const end = othersNextReservation.endDate;

		switch (othersNextReservation.status) {
			case 'beforeReservationDay':
				return {
					label: moment(start).format('D MMMM'),
					date: `${moment(start).format('h:mm')}-${moment(end).format('h:mma')}`,
					helperText: translationWithErrorHandling(
						intl,
						'reservationWithPopover.pilotsInAnOngoingSession'
					),
				};
			case 'reservationDay':
				return {
					label: translationWithErrorHandling(intl, 'reservationWithPopover.today'),
					date: `${moment(start).format('h:mm')}-${moment(end).format('h:mma')}`,
					helperText: translationWithErrorHandling(
						intl,
						'reservationWithPopover.pilotsInAnOngoingSession'
					),
				};
			case 'reservationTime':
				return {
					label: translationWithErrorHandling(intl, 'reservationWithPopover.now'),
					date:
						translationWithErrorHandling(intl, 'reservationWithPopover.until') +
						' ' +
						moment(end).format('h:mma'),
					helperText: translationWithErrorHandling(
						intl,
						'reservationWithPopover.youCannotStartASession'
					),
				};
			case 'inSession':
				return {
					label: translationWithErrorHandling(intl, 'reservationWithPopover.now'),
					date:
						translationWithErrorHandling(intl, 'reservationWithPopover.until') +
						' ' +
						moment(end).format('h:mma'),
					helperText: translationWithErrorHandling(
						intl,
						'reservationWithPopover.youCannotStartASession'
					),
				};
			default:
				return { label: '', date: '', helperText: '' };
		}
	}, [othersNextReservation]);

	if (!showMyReservations && !showOthersReservations)
		return <div className="noReservationHeader" />;

	return (
		<div
			className={classNames('reservationOverview')}
			onMouseOver={(e: any) => {
				setShowPopover(true);
			}}
			onMouseOut={(e: any) => {
				setShowPopover(false);
			}}
		>
			<div className="reservedWrapper">
				<IoTodayOutline
					className={classNames('calendarIcon', {
						redCalendarIcon:
							!showMyReservations &&
							!!othersNextReservation &&
							(othersNextReservation!.status === 'inSession' ||
								othersNextReservation!.status === 'reservationTime'),
						greenCalendarIcon: !!showMyReservations,
					})}
				/>
			</div>
			<div
				className={classNames('popoverWrapper', {
					popoverHide: !showPopover,
				})}
			>
				{!showMyReservations ? null : (
					<>
						<span className="sectionHeader">
							{translationWithErrorHandling(
								intl,
								'reservationWithPopover.myNextReservation'
							)}
						</span>
						<div className="dateWrapper">
							<span className="bodyM goBePrimaryColor">
								{myNextReservationLabel?.label}
							</span>
							<span className="bodyM goBePrimaryColor">
								{myNextReservationLabel?.date}
							</span>
						</div>
						<p className="caption" style={{ color: '#AAAAAA', marginTop: 6 }}>
							{translationWithErrorHandling(
								intl,
								'reservationWithPopover.youHaveExclusiveAccess'
							)}
						</p>
					</>
				)}
				{!showOthersReservations ? null : (
					<>
						{!showMyReservations ? null : <div className="divider"></div>}
						<span className="sectionHeader">
							{translationWithErrorHandling(
								intl,
								'reservationWithPopover.nextReservationForOthers'
							)}
						</span>
						<div className="dateWrapper">
							<span className="bodyM">{othersNextReservationLabel?.label}</span>
							<span className="bodyM">{othersNextReservationLabel?.date}</span>
						</div>
						<p className="caption" style={{ color: '#AAAAAA', marginTop: 6 }}>
							{othersNextReservationLabel?.helperText}
						</p>
					</>
				)}
			</div>
		</div>
	);
};
