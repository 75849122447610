import React, { FC, useEffect, useState, useCallback } from 'react';
import { IonGrid, IonRow, IonInput, IonList, IonItem, IonLabel, IonCol } from '@ionic/react';
import { useForm } from 'react-hook-form';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import isAuthenticated from '../../components/Authentication/Authenticated';
import { injectIntl, FormattedMessage } from 'react-intl';
import Messages from './OrganizationForm.messages';
import { publish } from '../../actions/publish';

import classes from './OrganizationForm.module.css';
import { useFormik } from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useTypedSelector } from '../../reducers';
import { b64EncodeUnicode } from '../../utils/encoding';
import ReactSelect from '../ReactSelect/ReactSelect';
import { EmailInputLimit, InputLimit } from '../../utils/validator';
import Select from 'react-select';
import { translationWithErrorHandling } from '../../translation/handleMissingTranslation';

interface OrganizationContactFormProps {
	organization: any;
	isEditable?: boolean;
	contactSave?: boolean;
	onSubmit: (data: any) => void;
	saved: boolean;
	segment: string;
}

const styles = {
	option: (provided: any, state: any) => ({
		...provided,
		fontSize: '15px',
		color: 'var(--ion-text-color)',
		backgroundColor: state.isFocused ? 'var(--form-field-hover-color)' : null,
	}),
	singleValue: (provided: any, state: any) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = 'opacity 300ms';

		return { ...provided, opacity, transition, fontSize: '15px', marginLeft: 0 };
	},
	valueContainer: (provided: any, state: any) => ({
		...provided,
		paddingLeft: '10px',
		padding: '0',
	}),
};

const OrganizationContactForm: FC<OrganizationContactFormProps> = (props: any) => {
	const { isEditable, organization, intl, saved, segment, contactSave } = props;

	const allUsers = useTypedSelector(state => state.usersState.items);
	const getOrganizationUsers = useCallback((organization: any) => {
		const orgId = organization.orgId;

		const users = allUsers.filter(user => user.orgId === orgId);

		const usersList = users.map(user => {
			return {
				label: user.username,
				value: user.username,
			};
		});
		return usersList || [];
	}, []);

	const handleReactSelect = (value: any) => {
		const orgId = organization.orgId;
		const contactPerson = allUsers.filter(user => user.username === value);
		if (contactPerson && contactPerson.length > 0) {
			formik.setFieldValue('firstName', contactPerson[0].firstName);
			formik.setFieldValue('lastName', contactPerson[0].lastName);
			formik.setFieldValue('phoneNumber', '');
		}
		formik.setFieldValue('email', value);
		setChangingContact(contactPerson && contactPerson.length > 0 ? contactPerson[0] : null);
	};

	const [userList, setUserList] = useState(getOrganizationUsers(organization));

	const username = useTypedSelector(state => state.accountState.user.username);
	const encodedUser = b64EncodeUnicode(username);
	const { register, getValues } = useForm();

	const parseContactPerson = useCallback(
		(organization: any) => {
			const contactPerson = JSON.parse(JSON.stringify(organization))?.contactPerson;

			return contactPerson || {};
		},
		[organization]
	);

	const [changingContact, setChangingContact] = useState(parseContactPerson(organization));
	const formik = useFormik({
		initialValues: {
			...changingContact,
		},
		onSubmit: (values: any) => {
			onEditContactPersonSubmit(values);
		},
	});

	// when click on save if error
	useEffect(() => {
		if (contactSave) {
			props.setContactSave(false);
		}
	}, [formik.values]);

	// on save button click from parent
	useEffect(() => {
		if (contactSave) {
			formik.handleSubmit();
		}
	}, [contactSave]);

	useEffect(() => {
		if (!isEditable) {
			setChangingContact(parseContactPerson(organization));
			formik.resetForm();
		}
	}, [isEditable, organization, parseContactPerson]);

	const onEditContactPersonSubmit = (data: any) => {
		const contactPerson: any = data || {};
		publish(`microservice/${organization.orgId}/${encodedUser}/updateOrgBasicInfo`, {
			data: {
				orgId: organization.orgId,
				contactPerson: contactPerson,
			},
			requestId: 'updateOrgBasicInfoId',
		});
		props.isSubmitForm(true);
	};
	const onChangePhoneNumber = (value: any, data: any, event: any, formattedValue: any) => {
		if (value) {
			changingContact.phoneNumber = formattedValue;
			formik.setFieldValue('phoneNumber', formattedValue);
		} else {
			changingContact.phoneNumber = '';
			formik.setFieldValue('phoneNumber', '');
		}
	};

	return (
		<form onSubmit={formik.handleSubmit} className={classes.editForm}>
			<IonGrid className={classes.formGrid}>
				<IonRow className={classes.detailsHeader}>
					<IonLabel className={classes.labelFont}>
						<FormattedMessage {...Messages.contactPerson} />
					</IonLabel>
				</IonRow>
				<IonRow>
					<IonCol className={classes.detailsCol}>
						<IonList>
							<IonItem disabled={!isEditable}>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.firstName} />
								</IonLabel>
								<IonLabel className={classes.inputLbContact}>
									{changingContact?.firstName}
								</IonLabel>
							</IonItem>
							<IonItem disabled={!isEditable}>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.lastName} />
								</IonLabel>
								<IonLabel className={classes.inputLbContact}>
									{changingContact?.lastName}
								</IonLabel>
							</IonItem>
							<IonItem disabled={!isEditable}>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.jobTitle} />
								</IonLabel>
								<IonLabel
									className={
										!isEditable ? classes.inputLbContact : classes.hidden
									}
								>
									{changingContact?.jobTitle}
								</IonLabel>

								<IonInput
									className={isEditable ? classes.editable : classes.hidden}
									name="jobTitle"
									placeholder={translationWithErrorHandling(
										intl,
										'ContactPerson.jobTitleHint'
									)}
									value={formik?.values?.jobTitle}
									readonly={!isEditable}
									onIonChange={e => {
										formik.handleChange(e);
									}}
									maxlength={InputLimit}
								/>
							</IonItem>
							<div className={classes.emailSelectItem}>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.email} />
								</IonLabel>
								<IonLabel
									className={
										!isEditable ? classes.inputLbContact : classes.hidden
									}
								>
									{changingContact?.email || 'Please select contact person'}
								</IonLabel>

								<div
									className={isEditable ? '' : classes.hidden}
									style={{ width: '300px' }}
									data-cy="email"
								>
									<Select
										value={userList.find(
											option => option.value === formik?.values.email
										)}
										styles={styles}
										menuPlacement="top"
										options={userList}
										name="email"
										placeholder={translationWithErrorHandling(
											intl,
											'ContactPerson.emailHint'
										)}
										onChange={(selected: any) => {
											handleReactSelect(selected.value);
										}}
										data-cy="email"
									/>
								</div>
							</div>
							<div className={classes.phoneItem}>
								<IonLabel position="fixed" className={classes.formLb}>
									<FormattedMessage {...Messages.phoneNumber} />
								</IonLabel>
								<IonLabel
									className={
										!isEditable ? classes.inputLbContact : classes.hidden
									}
								>
									{changingContact?.phoneNumber}
								</IonLabel>

								<div className={isEditable ? '' : classes.hidden}>
									<PhoneInput
										country="dk"
										value={
											changingContact?.phoneNumber
												? changingContact.phoneNumber
												: ''
										}
										onChange={(value, data, event, formattedValue) =>
											onChangePhoneNumber(value, data, event, formattedValue)
										}
									/>
									<IonInput
										className={classes.hidden}
										name="phoneNumber"
										value={changingContact?.phoneNumber}
										ref={register({ required: true })}
										readonly={!isEditable}
										maxlength={InputLimit}
									/>
								</div>
							</div>
						</IonList>
					</IonCol>
				</IonRow>
			</IonGrid>
		</form>
	);
};
const mapStateToProps = (state: any) => ({
	organizations: state.organizationState.organizations,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(
	isAuthenticated(enhance(OrganizationContactForm), 'OrganizationContactForm')
);
