import {
	IonContent,
	IonPage,
	IonItem,
	IonLabel,
	IonButton,
	IonGrid,
	IonRow,
	IonCol,
	IonIcon,
	IonList,
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { checkLogin } from '../../providers/mqtt';
import classes from './Login.module.css';
import classNames from 'classnames';
import isAuthenticated from '../../components/Authentication/Authenticated';
import Checkbox from '../../components/Checkbox/Checkbox';
import { FMFooterLogo } from '../../ui-elements/FMFooterLogo';

import { setParameter } from '../../actions/setParam';
import { SET_MQTTCLIENT, UPDATE_USER, UPDATE_ISSSO, SIGN_OUT_USER } from '../../actions/types';
import FormInputListItem from '../../components/FormInputListItem/FormInputListItem';

import { eyeSharp, eyeOffSharp } from 'ionicons/icons';
import LoginForwarding from './LoginForwarding';
import { useTypedSelector } from '../../reducers';
import { b64DecodeUnicode, b64EncodeUnicode } from '../../utils/encoding';
import { Link, useLocation } from 'react-router-dom';

import { useParams } from 'react-router';
import JWTDecode from 'jwt-decode';
import Config from '../../config';

import { fetchConfig } from '../../providers/mqtt';
import { EmailInputLimit, InputLimit } from '../../utils/validator';
import { translationWithErrorHandling } from '../../translation/handleMissingTranslation';

type FormData = {
	email: string;
	password: string;
};

const LoginSSOPage: React.FC = (props: any) => {
	const { setParameter, fetchData } = props;
	const [rememberChecked, setRememberChecked] = useState(false);
	const [customErrors, setCustomErrors] = useState('');
	const [readyForLogin, setReadyForLogin] = useState(false);
	const [passwordType, setPasswordType] = useState(true);
	const remember = localStorage.getItem('rememberMe');
	const { control, handleSubmit, errors } = useForm<FormData>();
	const [savedSpinoutType, setSavedSpinoutType] = useState('');
	let { token } = useParams<Record<string, any>>();
	const urlSearch = useLocation().search;

	useEffect(() => {
		if (token) {
			let decodedToken: any = JWTDecode(token);
			if (decodedToken.username && decodedToken.temp_password) {
				let email = decodedToken.username;
				let password = decodedToken.temp_password;
				checkLogin(email, password, (response: any) => {
					if (response && response.connected) {
						setParameter('showLoader', 'CHANGE_LOADER', true);
						setReadyForLogin(true);
						const userInfo = user as any;
						userInfo.loggedIn = true;
						userInfo.username = email;
						userInfo.password = password;
						setParameter('user', UPDATE_USER, userInfo);
						fetchConfig(() => {}); //trigger update of mqttstate.mqttConfig redux state to prevent white screen of death
						setParameter('isSso', UPDATE_ISSSO, true);
						setParameter('client', SET_MQTTCLIENT, response.client);
					} else {
						setParameter('client', SET_MQTTCLIENT, { connected: false });
						setCustomErrors(response.error);
					}
				});
			}
		}
	}, []);

	useEffect(() => {
		props.setParameter('', SIGN_OUT_USER, {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const user = useTypedSelector(state => state.accountState.user);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType);

	useEffect(() => {
		if (spinoutType) {
			setSavedSpinoutType(spinoutType);
		}
	}, [spinoutType]);

	useEffect(() => {
		if (fetchData.showLoader) {
			setParameter('showLoader', 'CHANGE_LOADER', false);
		}
		if (remember === 'true') {
			let email: any = localStorage.getItem('u');
			email = email.toLowerCase();

			let password = localStorage.getItem('p');
			if (!password || !email) return;
			email = b64DecodeUnicode(email);
			password = b64DecodeUnicode(password);

			checkLogin(email, password, (response: any) => {
				if (response && response.connected) {
					setParameter('showLoader', 'CHANGE_LOADER', true);
					setReadyForLogin(true);
					const userInfo = user as any;
					userInfo.loggedIn = true;
					userInfo.username = email;
					userInfo.password = password;
					setParameter('user', UPDATE_USER, userInfo);
					setParameter('client', SET_MQTTCLIENT, response.client);
				} else {
					setParameter('client', SET_MQTTCLIENT, { connected: false });
					setCustomErrors(response.error);
				}
			});
		}

		let link = document.getElementById('jsd-widget');
		if (link) {
			link.style.display = 'none';
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const displayPassword = () => {
		setPasswordType(prev => !prev);
	};

	// useEffect(() => {
	// 	if (user.loggedIn && !readyForLogin) {
	// 		props.setParameter('', SIGN_OUT_USER, {});
	// 	}
	// }, [props, readyForLogin, user.loggedIn]);

	const urlSearchParams = new URLSearchParams(urlSearch);
	const ssoLoginError = urlSearchParams.get('err');
	const ssoLoginErrorMessage = urlSearchParams.get('err_message');
	useEffect(() => {
		if (ssoLoginError) {
			setCustomErrors(
				ssoLoginError === 'SSO_ERR_012_USER_SIGNED_IN_WITH_DIFFERENT_ID'
					? (ssoLoginErrorMessage as string)
					: translationWithErrorHandling(props.intl, 'Error.SSOLogin')
			);
		}
	}, [ssoLoginError]);

	const loginUser = handleSubmit(data => {
		const email = encodeURIComponent(data.email);
		window.location.href = `${Config.ssoBaseUrl}login?email=${email}`;
	});

	const backToLoginWithPassword = () => {
		props.history.push('/login');
	};

	if (remember === 'true') {
		return (
			<div>
				<LoginForwarding inheritedProps={props} />
			</div>
		);
	} else
		return (
			<IonPage>
				<LoginForwarding inheritedProps={props} />
				<IonContent>
					<IonGrid className={classNames(classes.loginGrid, 'ion-no-padding')}>
						<IonRow className={classes.loginGridRow}>
							<IonCol
								className={classNames(
									classes.leftContainer,
									'ion-hide-sm-down ion-hide-md-down'
								)}
							>
								<div className={classNames(classes.homeCover)} />
							</IonCol>
							<IonCol className={classes.rightContainer}>
								<IonRow className={classes.loginFormContainer}>
									<IonGrid>
										<IonRow
											className={classNames(
												classes.topLogoContainer,
												'ion-justify-content-center ion-padding'
											)}
										>
											<div className={classNames(classes.logo)} />
											{/* <div className={classes.logo}>GoBe Robots</div> */}
											<IonLabel
												className={classNames(
													'ion-text-center',
													classes.welcomeLb
												)}
											>
												<FormattedMessage id="loginPage.welcome" />
											</IonLabel>
										</IonRow>
										<IonRow className={classNames(classes.ssoRow)}>
											<IonCol>
												<IonRow
													className={classNames(
														'ion-padding',
														classes.ssoPadding
													)}
												>
													<IonCol>
														<IonLabel
															className={classNames(
																'ion-text-start',
																classes.ssoElem,
																classes.provideEmailFont
															)}
														>
															<FormattedMessage id="sso.provideEmail" />
														</IonLabel>
													</IonCol>
												</IonRow>
											</IonCol>
										</IonRow>
										<form onSubmit={loginUser} className={classes.loginForm}>
											<IonRow>
												<IonLabel className={classes.errorContainer}>
													{customErrors}
												</IonLabel>
											</IonRow>
											<IonRow className="ion-justify-content-center">
												<IonList
													className={classNames(
														classes.loginList,
														'ion-padding'
													)}
												>
													<FormInputListItem
														name="email"
														type="email"
														control={control}
														onKeyPress={event => {
															if (event.key === 'Enter')
																document
																	.getElementById('login_submit')
																	?.click();
														}}
														rules={{ required: true }}
														errorMsg={
															errors.email && 'Email is invalid'
														}
														required
														label={translationWithErrorHandling(
															props.intl,
															'sso.email'
														)}
														placeholderText={translationWithErrorHandling(
															props.intl,
															'sso.emailHint'
														)}
														maxLength={EmailInputLimit}
													/>
												</IonList>
											</IonRow>
											<IonRow className="ion-justify-content-center">
												<IonCol>
													<IonItem
														lines="none"
														/* RememberMe checkbox hidden, re-include it in the future */
														hidden
														className={classes.chkContainer}
													>
														<Checkbox
															label={
																<FormattedMessage id="LoginPage.rememberme" />
															}
															onChange={(e: any) =>
																setRememberChecked(e.target.value)
															}
														/>
													</IonItem>
												</IonCol>
											</IonRow>
											<IonRow
												className={classNames(
													'ion-align-items-center',
													classes.ssoPadding
												)}
											>
												<IonCol>
													<IonRow
														className={classNames(
															'ion-align-items-center',
															classes.ssoLoginBtnRow
														)}
													>
														<IonCol>
															<IonLabel
																className={classNames(
																	classes.forgotPswLink
																)}
																onClick={backToLoginWithPassword}
															>
																<FormattedMessage id="sso.backToLoginWithPassword" />
															</IonLabel>
														</IonCol>
														<IonCol>
															<IonButton
																id="login_submit"
																size="large"
																type="submit"
																shape="round"
																fill="outline"
																className={classNames(
																	classes.loginBtn
																)}
															>
																<FormattedMessage id="sso.login" />
															</IonButton>
														</IonCol>
													</IonRow>
												</IonCol>
											</IonRow>
											<IonRow className={classes.btnRow}>
												<IonCol>
													{/*		<p
														hidden={savedSpinoutType === 'beam'}
														className={classes.noAccountAction}
													>
														<FormattedMessage
															id="LoginPage.noAccountAction"
															values={{
																a: (msg: string) => (
																	<Link to="/account-create">
																		{msg}
																	</Link>
																),
															}}
														/>
													</p>*/}
												</IonCol>
											</IonRow>
										</form>
									</IonGrid>
								</IonRow>
								<footer className={classes.footerContainer}>
									<FMFooterLogo />
								</footer>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonContent>
			</IonPage>
		);
};

const mapStateToProps = (state: any) => ({
	fetchData: state.fetchDataState,
});

export default injectIntl(
	isAuthenticated(connect(mapStateToProps, { setParameter })(LoginSSOPage), 'LoginSSOPage')
);
