import {
	IonContent,
	IonPage,
	IonItem,
	IonLabel,
	IonButton,
	IonGrid,
	IonRow,
	IonCol,
	IonIcon,
	IonList,
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { checkLogin } from '../../providers/mqtt';
import classes from './Login.module.css';
import classNames from 'classnames';
import isAuthenticated from '../../components/Authentication/Authenticated';
import Checkbox from '../../components/Checkbox/Checkbox';
import { FMFooterLogo } from '../../ui-elements/FMFooterLogo';

import { setParameter } from '../../actions/setParam';
import { SET_MQTTCLIENT, UPDATE_USER, SIGN_OUT_USER } from '../../actions/types';
import FormInputListItem from '../../components/FormInputListItem/FormInputListItem';

import { eyeSharp, eyeOffSharp, key } from 'ionicons/icons';
import LoginForwarding from './LoginForwarding';
import { useTypedSelector } from '../../reducers';
import { b64DecodeUnicode, b64EncodeUnicode } from '../../utils/encoding';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Icon } from 'ionicons/dist/types/components/icon/icon';
import { EmailInputLimit, InputLimit } from '../../utils/validator';
import { options, shorthandTable } from '../../translation/defs';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { translationWithErrorHandling } from '../../translation/handleMissingTranslation';

const AlphaOverlay = styled.div`
	position: absolute;
	top: 16px;
	right: 16px;
	color: black;
	z-index: 100;
	opacity: 0.5;
	font-weight: 100;
	font-family: 'Industry';
`;

type FormData = {
	email: string;
	password: string;
};

const StyledAutocomplete = styled(Autocomplete)({
	'& .MuiAutocomplete-listbox': {
		backgroundColor: 'lightgrey',
	},
	'&:hover': {
		backgroundColor: 'transparent',
	},
});

const StyledTextField = styled(TextField)(() => ({
	'& .MuiInput-underline:before': {
		borderBottom: 'none',
	},
	'& .MuiInput-underline:after': {
		borderBottom: 'none',
	},
	'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
		borderBottom: 'none',
	},
}));

const LoginPage: React.FC = (props: any) => {
	const { setParameter, fetchData, language } = props;
	const [rememberChecked, setRememberChecked] = useState(false);
	const [customErrors, setCustomErrors] = useState('');
	const [readyForLogin, setReadyForLogin] = useState(false);
	const [passwordType, setPasswordType] = useState(true);
	const remember = localStorage.getItem('rememberMe');
	const { control, handleSubmit, errors } = useForm<FormData>();
	const [savedSpinoutType, setSavedSpinoutType] = useState('');

	useEffect(() => {
		props.setParameter('', SIGN_OUT_USER, {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const user = useTypedSelector(state => state.accountState.user);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType);

	useEffect(() => {
		if (spinoutType) {
			setSavedSpinoutType(spinoutType);
		}
	}, [spinoutType]);

	useEffect(() => {
		if (fetchData.showLoader) {
			setParameter('showLoader', 'CHANGE_LOADER', false);
		}
		if (remember === 'true') {
			let email: any = localStorage.getItem('u');
			email = email.toLowerCase();

			let password = localStorage.getItem('p');
			if (!password || !email) return;
			email = b64DecodeUnicode(email);
			password = b64DecodeUnicode(password);

			checkLogin(email, password, (response: any) => {
				if (response && response.connected) {
					setParameter('showLoader', 'CHANGE_LOADER', true);
					setReadyForLogin(true);
					const userInfo = user as any;
					userInfo.loggedIn = true;
					userInfo.username = email;
					userInfo.password = password;
					setParameter('user', UPDATE_USER, userInfo);
					setParameter('client', SET_MQTTCLIENT, response.client);
				} else {
					setParameter('client', SET_MQTTCLIENT, { connected: false });
					if (response.error) {
						setCustomErrors(response.error);
					}
				}
			});
		}

		let link = document.getElementById('jsd-widget');
		if (link) {
			link.style.display = 'none';
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const displayPassword = () => {
		setPasswordType(prev => !prev);
	};

	/* useEffect(() => {
		if (user.loggedIn && !readyForLogin) {
			props.setParameter('', SIGN_OUT_USER, {});
		}
	}, [props, readyForLogin, user.loggedIn]); */

	const loginUser = handleSubmit(data => {
		checkLogin(data.email, data.password, async (response: any) => {
			if (response && response.connected) {
				props.setParameter('showLoader', 'CHANGE_LOADER', true);
				setReadyForLogin(true);
				const account = user as any;
				if (rememberChecked) {
					window.localStorage.setItem('rememberMe', 'true');
					// this section needs to be revisited PWA needs to have a cookie policy, and other devices needs to store cookies only
					window.localStorage.setItem('u', b64EncodeUnicode(data.email.toLowerCase()));
					window.localStorage.setItem('p', b64EncodeUnicode(data.password));
				}
				account.loggedIn = true;
				account.username = data.email.toLowerCase();
				account.password = data.password;
				props.setParameter('user', UPDATE_USER, account);
				props.setParameter('client', SET_MQTTCLIENT, response.client);
			} else {
				props.setParameter('client', SET_MQTTCLIENT, { connected: false });
				if (response.error) {
					setCustomErrors(
						response.error.includes('Bad User Name') ? (
							<FormattedMessage id="loginPage.invalidLogin" />
						) : (
							response.error
						)
					);
				}
			}
		});
	});

	const forgotPassword = () => {
		props.history.push('/forgotpassword');
	};

	if (remember === 'true') {
		return (
			<div>
				<LoginForwarding inheritedProps={props} />
			</div>
		);
	} else
		return (
			<IonPage>
				<LoginForwarding inheritedProps={props} />
				<IonContent>
					<IonGrid className={classNames(classes.loginGrid, 'ion-no-padding')}>
						<IonRow className={classes.loginGridRow}>
							<IonCol
								className={classNames(
									classes.leftContainer,
									'ion-hide-sm-down ion-hide-md-down'
								)}
							>
								<div className={classNames(classes.homeCover)} />
							</IonCol>
							<IonCol className={classes.rightContainer}>
								<IonRow className={classes.loginFormContainer}>
									<IonGrid>
										<IonRow
											className="ion-justify-content-end"
											style={{
												paddingTop: '16px',
												paddingBottom: '16px',
												paddingRight: '60px',
											}}
										>
											<StyledAutocomplete
												disableClearable={true}
												value={options.find(
													option =>
														option.value ===
														Object.keys(shorthandTable).find(
															key =>
																(shorthandTable as any)[key] ===
																language.toLowerCase()
														)
												)}
												options={options}
												getOptionLabel={(option: any) => option.label}
												renderOption={(option: any) => (
													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between',
															width: '100%',
														}}
													>
														<img
															src={option.image}
															alt={option.label}
															style={{ width: 30, height: 20 }}
														/>
														<span>{option.label}</span>
													</div>
												)}
												onChange={(event, value: any) => {
													if (value != null) {
														(window as any).changeAppLanguage(
															shorthandTable[
																value.value as keyof typeof shorthandTable
															]
														);
													}
												}}
												renderInput={params => (
													<StyledTextField
														{...params}
														placeholder="Select Language"
														variant="standard"
														InputProps={{
															...params.InputProps,
															startAdornment: language ? (
																<img
																	src={
																		options.find(
																			option =>
																				shorthandTable[
																					option.value as keyof typeof shorthandTable
																				] === language
																		)?.image
																	}
																	style={{
																		width: 30,
																		height: 20,
																		marginRight: 10,
																	}}
																/>
															) : null,
														}}
													/>
												)}
												style={{ width: 200 }}
											/>
										</IonRow>
										<IonRow
											className={classNames(
												classes.topLogoContainer,
												'ion-justify-content-center ion-padding'
											)}
										>
											<div className={classNames(classes.logo)} />
											{/* <div className={classes.logo}>GoBe Robots</div> */}
											<IonLabel
												className={classNames(
													'ion-text-center',
													classes.welcomeLb
												)}
											>
												<FormattedMessage id="loginPage.welcome" />
											</IonLabel>
										</IonRow>
										<form onSubmit={loginUser} className={classes.loginForm}>
											<IonRow className={classes.loginRow}>
												<IonLabel className={classes.errorContainer}>
													{customErrors}
												</IonLabel>
											</IonRow>
											<IonRow className="ion-justify-content-center">
												<IonList
													className={classNames(
														classes.loginList,
														'ion-padding',
														classes.loginRow
													)}
												>
													<FormInputListItem
														name="email"
														type="email"
														control={control}
														onKeyPress={event => {
															if (event.key === 'Enter')
																document
																	.getElementById('login_submit')
																	?.click();
														}}
														rules={{ required: true }}
														errorMsg={
															errors.email && 'Email is invalid'
														}
														required
														label={translationWithErrorHandling(
															props.intl,
															'loginPage.username'
														)}
														placeholderText={translationWithErrorHandling(
															props.intl,
															'loginPage.usernameHint'
														)}
														onChange={() => setCustomErrors('')}
														maxLength={EmailInputLimit}
														dataCy="email"
													/>
													<FormInputListItem
														type={passwordType ? 'password' : 'text'}
														name="password"
														control={control}
														onKeyPress={event => {
															if (event.key === 'Enter')
																document
																	.getElementById('login_submit')
																	?.click();
														}}
														rules={{ required: true }}
														errorMsg={
															errors.password &&
															'Password is required'
														}
														required
														label={translationWithErrorHandling(
															props.intl,
															'loginPage.password'
														)}
														placeholderText={translationWithErrorHandling(
															props.intl,
															'loginPage.passwordHint'
														)}
														iconUrl={
															passwordType ? eyeOffSharp : eyeSharp
														}
														maxLength={InputLimit}
														iconCallback={displayPassword}
														onChange={() => setCustomErrors('')}
														dataCy="password"
													/>
												</IonList>
											</IonRow>
											<IonRow
												className={classNames(
													'ion-justify-content-center',
													'ion-padding'
												)}
											>
												<IonCol>
													<IonItem
														lines="none"
														/* RememberMe checkbox hidden, re-include it in the future */
														hidden
														className={classes.chkContainer}
													>
														<Checkbox
															label={
																<FormattedMessage id="loginPage.rememberme" />
															}
															onChange={(e: any) =>
																setRememberChecked(e.target.value)
															}
														/>
													</IonItem>
												</IonCol>
												<IonCol className="ion-align-self-center">
													<IonLabel
														className={classNames(
															'ion-float-right',
															classes.ssoElem,
															classes.forgotPswLink
														)}
														onClick={forgotPassword}
													>
														<FormattedMessage id="loginPage.forgot" />
													</IonLabel>
												</IonCol>
											</IonRow>
											<IonRow className={classes.btnRow}>
												<IonCol>
													<IonCol>
														<IonButton
															id="login_submit"
															size="large"
															type="submit"
															shape="round"
															fill="outline"
															className={classes.loginBtn}
															data-cy="login_submit"
														>
															<FormattedMessage id="loginPage.login" />
														</IonButton>
													</IonCol>
													{/*<p
														hidden={savedSpinoutType === 'beam'}
														className={classes.noAccountAction}
													>

														<FormattedMessage id="loginPage.login" />
														*/}
													{/*</IonButton>*/}
													{/* <p
														hidden={savedSpinoutType === 'beam'}
														className={classes.noAccountAction}
													>
														<FormattedMessage
															id="loginPage.noAccountAction"
															values={{
																a: (msg: string) => (
																	<Link to="/account-create">
																		{msg}
																	</Link>
																),
															}}
														/>
													</p> */}
												</IonCol>
											</IonRow>
										</form>
										<IonRow className={classNames(classes.ssoRow)}>
											<IonCol>
												<IonRow
													className={classNames(
														'ion-padding',
														'ion-padding',
														classes.dividerContainer
													)}
												>
													<IonCol>
														<IonRow className={classes.ssoElem}>
															<IonCol>
																<hr
																	className={classNames(
																		classes.divider
																	)}
																/>
															</IonCol>
															<IonCol
																size="auto"
																className={classes.dividerFont}
															>
																<FormattedMessage id="loginPage.orAccessQuickly" />
															</IonCol>
															<IonCol>
																<hr className={classes.divider} />
															</IonCol>
														</IonRow>
													</IonCol>
												</IonRow>
											</IonCol>
										</IonRow>
										<IonRow className={classNames(classes.ssoRow)}>
											<IonCol>
												<IonRow className={classNames('ion-padding')}>
													<IonCol>
														<IonButton
															id="sso_button"
															size="large"
															type="button"
															shape="round"
															text-align="right"
															fill="outline"
															className={classes.loginSSOBtn}
															onClick={() => {
																props.history.push('/sso-login');
															}}
														>
															{/* <div className={classes.ssoTxt}>
																<FormattedMessage id="loginPage.sso" />
															</div> */}
															<IonIcon
																// className={classes.ssoIcon}
																slot="start"
																icon={key}
															></IonIcon>
															<FormattedMessage id="loginPage.sso" />
														</IonButton>
													</IonCol>
												</IonRow>
											</IonCol>
										</IonRow>
									</IonGrid>
								</IonRow>
								<footer className={(classes.footerContainer, classes.ssoPadding)}>
									<FMFooterLogo />
								</footer>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonContent>
			</IonPage>
		);
};

const mapStateToProps = (state: any) => ({
	fetchData: state.fetchDataState,
});

export default injectIntl(
	isAuthenticated(connect(mapStateToProps, { setParameter })(LoginPage), 'LoginPage')
);
