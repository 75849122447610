import * as ICAL from 'ical';

export function processRobotAccessHours(accessHoursEvents: any, robotSerialNumber: string) {
	let AccessHoursNotBlocking = true;
	let formattedStartTime = '';
	let formattedEndTime = '';

	let days: string[] = [];

	const event = ICAL.parseICS(accessHoursEvents[robotSerialNumber]);

	const UUID: string = Object.keys(event)[0];

	//@ts-ignore
	const rruleString: string = event[UUID].rrule;

	const match = rruleString.match(/BYDAY=([^;]+)/); // Extract days from the rrule string
	if (!match) {
		AccessHoursNotBlocking = false;
	} else {
		//Verify if current day is one where access is available
		days = match[1].split(',');
		const daysArray = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
		const currentDayIndex = new Date().getDay();
		const currentDay = daysArray[currentDayIndex - 1];

		if (!days.includes(currentDay)) {
			AccessHoursNotBlocking = false;
		}
		let nowHours = new Date().getHours();
		let nowMinutes = new Date().getMinutes();
		let nowDate = new Date();
		nowDate.setHours(nowHours, nowMinutes, 0, 0);

		let endHours = event[UUID]['end']!.getHours();
		let endMinutes = event[UUID]['end']!.getMinutes();
		let endDate = new Date();
		endDate.setHours(endHours, endMinutes, 0, 0);

		let startHours = event[UUID]['start']!.getHours();
		let startMinutes = event[UUID]['start']!.getMinutes();
		let startDate = new Date();
		startDate.setHours(startHours, startMinutes, 0, 0);

		if (!(endDate.getTime() > nowDate.getTime() && startDate.getTime() < nowDate.getTime())) {
			AccessHoursNotBlocking = false;
		}

		// Format times as strings for display
		const formatTime = (date: Date) => {
			return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
		};

		formattedStartTime = formatTime(event[UUID]['start']!);
		formattedEndTime = formatTime(event[UUID]['end']!);
	}
	return { AccessHoursNotBlocking, formattedStartTime, formattedEndTime };
}

export function isCallableChecker(
	accessHoursEvents: any,
	robotSerialNumber: string,
	AccessHoursNotBlocking: boolean,
	nextReservationExists: boolean,
	canCall: boolean
) {
	return (
		(canCall && !Object.keys(accessHoursEvents).includes(robotSerialNumber)) ||
		(Object.keys(accessHoursEvents).includes(robotSerialNumber) && AccessHoursNotBlocking) ||
		nextReservationExists
	);
}
