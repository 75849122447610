import classNames from 'classnames';
import React from 'react';
import { Device } from '../../../../../../../../../../types/types';
import { useDeviceCurrentState } from '../../../../../../../utils/statusConverter';
import '../../../index.scss';
import { DefaultStatus } from './DefaultStatus';
import { ReservationStatus } from './ReservationStatus';
import { useSelector } from 'react-redux';
import { selectAccessHoursEvents } from '../../../../../../../../../../selectors/accessHoursSelector';
import {
	processRobotAccessHours,
	isCallableChecker,
} from '../../../../../../../utils/accessHourLogic';
import { useIntl } from 'react-intl';
import { translationWithErrorHandling } from '../../../../../../../../../../translation/handleMissingTranslation';

interface Props {
	device: Partial<Device>;
	isCalling: boolean;
	onClickStartSession: (
		device: Device,
		reservationStartTime?: string,
		reservationEndTime?: string,
		isMyReservation?: boolean
	) => void;
}

export const Status = ({ device, isCalling, onClickStartSession }: Props) => {
	const intl = useIntl();
	const {
		canCall,
		deviceStatus,
		nextReservation,
		myNextReservation,
		othersNextReservation,
	} = useDeviceCurrentState(device, isCalling);

	const accessHoursEvents = useSelector(selectAccessHoursEvents);

	let AccessHoursNotBlocking = true;
	let formattedStartTime = '';
	let formattedEndTime = '';
	let days: string[] = [];
	if (Object.keys(accessHoursEvents.accessHoursEvents).includes(device.serialNumber)) {
		({ AccessHoursNotBlocking, formattedStartTime, formattedEndTime } = processRobotAccessHours(
			accessHoursEvents.accessHoursEvents,
			device.serialNumber
		));
	}

	//Check if access hours are blocking access to the robot. Access hours do not apply to reservations
	const isCallable: boolean = isCallableChecker(
		accessHoursEvents.accessHoursEvents,
		device.serialNumber,
		AccessHoursNotBlocking,
		!!nextReservation,
		canCall
	);

	return (
		<div
			className={classNames('content', {
				pointerCursor: canCall,
			})}
			onClick={() => {
				if (isCallable) {
					onClickStartSession(device as Device, myNextReservation, othersNextReservation);
				} else {
					if (!AccessHoursNotBlocking) {
						alert(
							`${translationWithErrorHandling(
								intl,
								'launchCardBodyStatus.thisRobotMayOnlyBeUsed'
							)} ${formattedStartTime} ${translationWithErrorHandling(
								intl,
								'launchCardBodyStatus.and'
							)} ${formattedEndTime} ${translationWithErrorHandling(
								intl,
								'launchCardBodyStatus.onDays'
							)} ${days.join(', ')} (${translationWithErrorHandling(
								intl,
								'launchCardBodyStatus.yourLocalTime'
							)})\n${translationWithErrorHandling(
								intl,
								'launchCardBodyStatus.contactYourRobotAdmin'
							)}`
						);
					}
					return;
				}
			}}
		>
			<div className="statusContentContainer">
				{!!nextReservation ? (
					<ReservationStatus
						reservation={nextReservation}
						deviceStatus={deviceStatus}
						isPermanentDevice={device.isPermanent}
						isCalling={isCalling}
					/>
				) : (
					<DefaultStatus status={deviceStatus} isCalling={isCalling} />
				)}
			</div>
			<p className="bodyM textAlignCenter location">
				{device?.location
					? device.location
					: translationWithErrorHandling(intl, 'launchCardBodyStatus.locationUnknown')}
			</p>
		</div>
	);
};
