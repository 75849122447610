import React from 'react';
import { IonLoading } from '@ionic/react';
import { useIntl } from 'react-intl';
import { translationWithErrorHandling } from '../../translation/handleMissingTranslation';

interface TranslatedIonLoadingProps {
	isOpen: boolean;
	messageId?: string;
	onDidDismiss: () => void;
}

const TranslatedIonLoading: React.FC<TranslatedIonLoadingProps> = ({
	isOpen,
	messageId,
	onDidDismiss,
}) => {
	const intl = useIntl();

	return (
		<IonLoading
			isOpen={isOpen}
			onDidDismiss={onDidDismiss}
			message={translationWithErrorHandling(intl, messageId ? (messageId as string) : '')}
		/>
	);
};

export default TranslatedIonLoading;
