import { injectIntl, FormattedMessage } from 'react-intl';
import React, { FC, useState } from 'react';
import isAuthenticated from '../Authentication/Authenticated';
import { IonGrid, IonRow, IonList, IonCol, IonText, IonLabel, IonInput } from '@ionic/react';
import FormInputListItem from '../FormInputListItem/FormInputListItem';
import classes from './InviteUser.module.css';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import { useTypedSelector } from '../../reducers';
import { AutoComplete } from '../AutoComplete/AutoComplete';
import { EmailInputLimit, InputLimit, ShortInputLimit } from '../../utils/validator';
import FormikInputListItem from '../FormikInputContainer/FormikInputListItem';
import { translationWithErrorHandling } from '../../translation/handleMissingTranslation';

interface InviteUserFormProps {
	control: any;
	errors: any;
	existentUser: any;
	onOrgIdChange: any;
}

const InviteUserForm: FC<InviteUserFormProps> = (props: any) => {
	const { intl, control, errors, existentUser, users, onOrgIdChange } = props;
	const [selectedOrgId, setSelectedOrgId] = useState('');
	const { inviteUserToUserGroupIds } = users;
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;

	/**
	 * @param {string} entity - The entity type to get current values for React-Select element
	 * @param {string} labelProperty - The property that contains the name of the entity
	 * @param {string} valueProperty - The property that contains the identifying value of the entity
	 */
	const selectOptions = (entity: string, labelProperty: string, valueProperty: string) => {
		let label;
		let value;

		const orgId = props.selectedOrganization.orgId;
		if (!selectedOrgId) {
			onOrgIdChange(orgId);
		}
		const options = [];
		let currentEntities: any;
		if (entity === 'userGroup') {
			currentEntities = props.userGroups.userGroupsByOrganizationId[orgId];
		} else if (entity === 'deviceGroup') {
			currentEntities = props.deviceGroups.deviceGroupsByOrganizationId[orgId];
		} else if (entity === 'organizations') {
			currentEntities = Object.assign(props.organization.organizations);
		}

		if (currentEntities === null) currentEntities = [];
		else {
			for (let i in currentEntities) {
				label = currentEntities[i][labelProperty];
				value = currentEntities[i][valueProperty];

				options.push({
					label,
					value,
				});
			}
		}
		return options;
	};

	/**
	 * @param {string} entity - The entity type to get current values for React-Select element
	 */
	const currentEntities = (entity: string, labelProperty: string, valueProperty: string) => {
		let selectedEntities = [];
		if (entity === 'organization') {
			selectedEntities.push(props.selectedOrganization);
		}

		const options = [];
		if (selectedEntities != null && selectedEntities.length > 0) {
			for (let key in selectedEntities) {
				options.push({
					label: selectedEntities[key][labelProperty],
					value: selectedEntities[key][valueProperty],
				});
			}
		}
		if (entity === 'organization') {
			return options.length > 0 && options[0];
		}

		return options;
	};

	const userGroupDefaultValue = () => {
		const options = selectOptions('userGroup', 'name', 'userGroupId');
		if (
			Array.isArray(inviteUserToUserGroupIds) === false ||
			inviteUserToUserGroupIds.length === 0
		) {
			return null;
		}
		const defaultValue = options.filter(option =>
			inviteUserToUserGroupIds.includes(option.value)
		);
		return defaultValue;
	};
	const onOrgChange = (orgId: string) => {
		setSelectedOrgId(orgId);
		onOrgIdChange(orgId);
	};

	return (
		<IonList className={classNames(classes.formList)}>
			<AutoComplete
				defaultValue={currentEntities('organization', 'name', 'orgId')}
				options={selectOptions('organizations', 'name', 'orgId')}
				label={translationWithErrorHandling(intl, 'InviteModal.organizationHint')}
				onChange={({ value, label }: any) => onOrgChange(value)}
				required={false}
				style={{ marginTop: 25 }}
			/>
			<IonGrid className="ion-no-padding">
				<IonRow className={classes.nameRow}>
					<IonCol>
						<FormikInputListItem
							type="text"
							name="firstName"
							label={translationWithErrorHandling(intl, 'InviteModal.firstname')}
							placeholderText={translationWithErrorHandling(
								intl,
								'InviteModal.firstnameHint'
							)}
							onChange={(e: any) => {
								control.handleChange(e);
							}}
							value={control.values.firstName}
							errorMsg={
								control.errors?.firstName ? (
									<FormattedMessage
										id="inviteModal.firstname"
										defaultMessage={control.errors?.firstName}
									/>
								) : (
									''
								)
							}
							maxLength={ShortInputLimit}
							dataCy="firstName"
						/>
					</IonCol>
					<IonCol>
						<FormikInputListItem
							type="text"
							name="lastName"
							label={translationWithErrorHandling(intl, 'InviteModal.lastname')}
							placeholderText={translationWithErrorHandling(
								intl,
								'InviteModal.lastnameHint'
							)}
							onChange={(e: any) => {
								console.log(e, 'e');
								control.handleChange(e);
							}}
							value={control.values.lastName}
							errorMsg={
								control.errors?.lastName ? (
									<FormattedMessage
										id="inviteModal.lastname"
										defaultMessage={control.errors.lastName}
									/>
								) : (
									''
								)
							}
							maxLength={ShortInputLimit}
							dataCy="lastName"
						/>
					</IonCol>
				</IonRow>
			</IonGrid>
			<FormikInputListItem
				type="text"
				name="email"
				label={translationWithErrorHandling(intl, 'InviteModal.email')}
				placeholderText={translationWithErrorHandling(intl, 'InviteModal.emailHint')}
				onChange={(e: any) => {
					control.handleChange(e);
				}}
				value={control.values.email}
				errorMsg={
					(control.errors?.email && (
						<FormattedMessage
							id="inviteModal.email"
							defaultMessage={control.errors.email}
						/>
					)) ||
					(existentUser && 'The user already exists within the organization')
				}
				maxLength={EmailInputLimit}
				dataCy="email"
			/>

			{/* <ReactSelect
				className={classes.select}
				options={selectOptions('deviceGroup', 'name', 'deviceGroupId')}
				placeHolder={
					<>
						<FormattedMessage id="InviteModal.deviceGroupHint" />
						<IonLabel>
							<FormattedMessage id="InviteModal.deviceGroupHint" />
						</IonLabel>
						<IonText color="primary"> *</IonText>
					</>
				}
				name="deviceGroupNames"
				control={control}
				isMulti
				// isRequired={false}
				errorMsg={errors.deviceGroups && 'Device Groups is required'}
				fullWidth
			/> */}
			{/* {spinoutType !== 'beam' && (
				<ReactSelect
					className={classes.select}
					options={selectOptions('userGroup', 'name', 'userGroupId')}
					defaultValue={userGroupDefaultValue()}
					placeHolder={translationWithErrorHandling( intl, 'InviteModal.userGroupsHint')}
					name="userGroupNames"
					control={control}
					isMulti
					isRequired={false}
					fullWidth
				/>
			)} */}
		</IonList>
	);
};

const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
	organization: state.organizationState,
	selectedOrganization: state.selectedOrganizationState.organization,
	userGroups: state.userGroupsState,
	deviceGroups: state.deviceGroupsState,
	users: state.usersState,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(isAuthenticated(enhance(InviteUserForm), 'InviteUserForm'));
