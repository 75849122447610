import {
	IonRow,
	IonCol,
	IonButton,
	IonIcon,
	IonLabel,
	IonGrid,
	IonList,
	IonListHeader,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { setParameter } from '../../actions/setParam';
import Checkbox from '../../components/Checkbox/Checkbox';
// Styling
import classes from './InviteUser.module.css';
// custom components
import isAuthenticated from '../Authentication/Authenticated';
import InviteUserForm from './InviteUserForm';
// icons
import { publish } from '../../actions/publish';
import { useForm } from 'react-hook-form';
import { personAdd } from 'ionicons/icons';
import { useTypedSelector } from '../../reducers';
import { b64EncodeUnicode } from '../../utils/encoding';
import BasicModal from '../BasicModal/BasicModal';
import { useFormik } from 'formik';
import { translationWithErrorHandling } from '../../translation/handleMissingTranslation';

const InviteUser: React.FC = (props: any) => {
	const { intl, users } = props;
	// const { handleSubmit, control, errors, watch, reset } = useForm();
	const [selectedOrgId, setSelectedOrgId] = useState('');
	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
		},

		validate: values => {
			let errors: any = {};
			const inputRegex = /^[a-zA-Z\-?\s]+$/;
			const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if (values.firstName && values.firstName != '' && !inputRegex.test(values.firstName)) {
				errors.firstName = 'Enter only letters and hyphen(-) characters';
			}
			if (!values.firstName) {
				errors.firstName = 'First name is required';
			}
			if (values.lastName && values.lastName != '' && !inputRegex.test(values.lastName)) {
				errors.lastName = 'Enter only letters and hyphen(-) characters';
			}
			if (!values.email) {
				errors.email = 'Email is required';
			} else if (values.email && values.email != '' && !emailRegex.test(values.email)) {
				errors.email = 'Email is Invalid';
			} else {
				const isExitingUser = users
					.filter((u: any) => u.status && u.orgId === selectedOrgId)
					.map((u: any) => u.username)
					.find((element: any) => element === values.email);

				if (isExitingUser) {
					errors.email = 'The user already exists within the organization';
				}
			}
			return errors;
		},
		onSubmit: values => {
			onUpdate(values);
		},
	});

	const username = useTypedSelector(state => state.accountState.user.username);
	let encodedUser = b64EncodeUnicode(username);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;
	useEffect(() => {
		formik.resetForm();
		formik.setErrors({});
	}, [props.isOpen]);
	const onUpdate = (data: any) => {
		data.firstName =
			data.firstName && typeof data.firstName === 'string' ? data.firstName.trim() : '';
		data.lastName =
			data.lastName && typeof data.lastName === 'string' ? data.lastName.trim() : '';
		data.email = data.email.toLowerCase().trim();

		if (!selectedOrgId) {
			console.log('Error: organization id not set');
			return;
		}

		const domainUrl = window.location.hostname;

		publish(`microservice/${selectedOrgId}/${encodedUser}/inviteUser`, {
			requestId: 'someId',
			data: {
				firstName: data.firstName,
				lastName: data.lastName,
				username: data.email,
				orgId: selectedOrgId,
				spinoutType,
				domainUrl,
			},
		});

		if (data.userGroupNames != null && data.userGroupNames.length > 0) {
			let selectedUserGroups: any = data.userGroupNames;
			for (let selectedUserGroup of selectedUserGroups) {
				publish(`microservice/${selectedOrgId}/${encodedUser}/updateUserGroupUsers`, {
					requestId: 'updateUserGroupUsers',
					data: {
						userGroupId: selectedUserGroup.value,
						add: [data.email],
					},
				});
			}
		}
		let deviceGroupIds = [];
		let selectedDeviceGroups: any = data.deviceGroupNames || [];
		for (let selectedDeviceGroup of selectedDeviceGroups) {
			deviceGroupIds.push(selectedDeviceGroup.value);
		}
		publish(`microservice/${selectedOrgId}/${encodedUser}/updateUserDeviceGroups`, {
			requestId: 'updateUserDeviceGroups',
			data: {
				userId: data.email,
				add: deviceGroupIds,
			},
		});
		props.setParameter(
			'users',
			'USER_INVITED',
			((data.firstName || '') + ' ' + (data.lastName || '')).trim()
		);

		formik.resetForm();
		formik.setErrors({});
		formik.setTouched({}, false);
		props.onDismiss();
	};

	return (
		<BasicModal
			open={props.isOpen}
			onClose={() => {
				formik.resetForm();
				formik.setErrors({});
				formik.setTouched({}, false);
				props.onDismiss();
			}}
		>
			<form onSubmit={formik.handleSubmit} className={classes.addForm}>
				<IonList>
					<IonListHeader className={classes.modalHeader} lines="none">
						<div className={classes.headerContainer}>
							<IonIcon
								className={classes.headerIcon}
								color="primary"
								size="large"
								icon={personAdd}
							/>
							<IonLabel color="primary" className={classes.headerTitle}>
								{translationWithErrorHandling(intl, 'InviteModal.inviteUserTitle')}
							</IonLabel>
						</div>
						<IonLabel className={classes.headerHint}>
							{translationWithErrorHandling(intl, 'InviteModal.hintMessage')}
						</IonLabel>
					</IonListHeader>
				</IonList>
				<IonGrid className={classes.modalContent}>
					<IonRow>
						<InviteUserForm
							control={formik}
							errors={formik.errors}
							onOrgIdChange={setSelectedOrgId}
						/>
					</IonRow>
					{/* <IonRow className={classes.chkRow}>
						<Checkbox
							label={translationWithErrorHandling( intl, 'InviteModal.emailCopy')}
							onChange={() => {}}
						/>
					</IonRow> */}
					<IonRow className={classes.btnRow}>
						<IonCol>
							<IonButton
								className={classes.cancelBtn}
								expand="block"
								shape="round"
								size="large"
								fill="outline"
								onClick={() => {
									formik.resetForm();
									formik.setErrors({});
									props.onDismiss();
								}}
							>
								{translationWithErrorHandling(intl, 'InviteModal.cancelBtn')}
							</IonButton>
						</IonCol>
						<IonCol className={classes.submitCol}>
							<IonButton
								expand="block"
								shape="round"
								type="submit"
								size="large"
								fill="outline"
							>
								{translationWithErrorHandling(intl, 'InviteModal.inviteBtn')}
							</IonButton>
						</IonCol>
					</IonRow>
				</IonGrid>
			</form>
		</BasicModal>
	);
};

const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
	selectedOrganization: state.selectedOrganizationState.organization,
	users: state.usersState.items,
});

export default injectIntl(
	isAuthenticated(connect(mapStateToProps, { setParameter })(InviteUser), 'InviteUser')
);
