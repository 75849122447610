import React, { FC, useEffect, useState } from 'react';
import {
	IonContent,
	IonPage,
	IonLabel,
	IonGrid,
	IonRow,
	IonCol,
	IonIcon,
	IonList,
	IonButton,
} from '@ionic/react';
import { UserInviteTokenData } from '../../types/types';
import { injectIntl, FormattedMessage } from 'react-intl';
import JWTDecode from 'jwt-decode';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import { FMFooterLogo } from '../../ui-elements/FMFooterLogo';
import classNames from 'classnames';
import classes from './AcceptInvite.module.css';

import FormInputListItem from '../../components/FormInputListItem/FormInputListItem';
import { RouteComponentProps, useParams, withRouter } from 'react-router';
import { automaticLogin } from '../../providers/mqtt';

import ErrorModal from '../../components/ErrorModal/ErrorModal';

import { eyeSharp, eyeOffSharp, checkmarkCircle } from 'ionicons/icons';
import { b64EncodeUnicode } from '../../utils/encoding';
import PasswordRequirements from '../../components/PasswordRequirements/PasswordRequirements';
import {
	checkOneLowerCase,
	checkOneNumeric,
	checkOneUpperCase,
	InputLimit,
} from '../../utils/validator';

import { SET_MQTTCLIENT, UPDATE_USER } from '../../actions/types';
import { useTypedSelector } from '../../reducers';
import FormikInputListItem from '../../components/FormikInputContainer/FormikInputListItem';
import { useFormik } from 'formik';
import { translationWithErrorHandling } from '../../translation/handleMissingTranslation';

interface AcceptInviteProps extends RouteComponentProps {}

// const AcceptInviteConfirmPage: FC<AcceptInviteProps> = (props: any) => {
const AcceptInviteConfirmPage: FC<AcceptInviteProps> = (props: any) => {
	const { intl, confirmAccount, history } = props;
	const [tokenError, setTokenError] = useState('');
	const [orgName, setOrgName] = useState('');
	const [firstName, setFirstName] = useState('');
	const [tokenObj, setTokenObj] = useState<any>();
	const [passwordType, setPasswordType] = useState(true);
	const [passwordTypeConfirm, setPasswordTypeConfirm] = useState(true);
	const [client, setClient] = useState<any>(null);
	let { token } = useParams<Record<string, any>>();

	const user = useTypedSelector(state => state.accountState.user);
	const formik = useFormik({
		initialValues: {
			password: '',
			confirmPassword: '',
		},

		validate: values => {
			let errors: any = {};
			if (values.password == '' || values.password.length < 8) {
				errors.password = translationWithErrorHandling(intl, 'ConfirmPage.passwordLength');
			} else if (!checkOneLowerCase(values.password)) {
				errors.password = translationWithErrorHandling(
					intl,
					'ConfirmPage.passwordLowerCase'
				);
			} else if (!checkOneNumeric(values.password)) {
				errors.password = translationWithErrorHandling(intl, 'ConfirmPage.passwordNumeric');
			} else if (!checkOneUpperCase(values.password)) {
				errors.password = translationWithErrorHandling(
					intl,
					'ConfirmPage.passwordUpperCase'
				);
			}
			if (values.confirmPassword == '' || values.confirmPassword.length < 8) {
				errors.confirmPassword = translationWithErrorHandling(
					intl,
					'ConfirmPage.passwordLength'
				);
			} else if (values.confirmPassword !== values.password) {
				errors.confirmPassword = translationWithErrorHandling(
					intl,
					'ConfirmPage.passwordNoMatch'
				);
			}
			return errors;
		},
		onSubmit: (values: any) => {
			resetPassword(values);
		},
	});

	useEffect(() => {
		if (!token) {
			return;
		}

		if (client) {
			return;
		}

		let tokenData: any;
		try {
			tokenData = JWTDecode(token);
			let orgName = tokenData.org.displayName
				? tokenData.org.displayName
				: tokenData.org.name;
			let firstName = tokenData.firstName ? tokenData.firstName : tokenData.first_name;
			setTokenObj(tokenData);
			setOrgName(orgName || '');
			setFirstName(firstName || '');
			if (tokenData.exp < new Date().getTime() / 1000) {
				setTokenError(translationWithErrorHandling(intl, 'ConfirmPage.tokenExpired'));
				return;
			}
		} catch (error) {
			setTokenError('');
			return history.push('/');
		}

		if (tokenData.isExistingUser || tokenData.is_sso_user) {
			if (tokenData.type === 'orgCreated') {
				history.push(`/gobe`);
			} else {
				history.push(`/accept-invite/${token}`);
			}
			return;
		}

		const { username, temp_password: password } = tokenData;

		automaticLogin(
			username,
			password,
			async (response: { client: any; connected: boolean }) => {
				if (response && response.connected) {
					setClient(response.client);
					setParameter('client', 'SET_MQTTCLIENT', response.client);
					return;
				}

				setClient(null);
				setParameter('client', 'SET_MQTTCLIENT', { connected: false });

				setTokenError(translationWithErrorHandling(intl, 'ConfirmPage.tokenExpired'));
				return history.push('/');
			}
		);
	}, [intl, history, token, client]);

	useEffect(() => {
		if (!tokenObj) {
			return;
		}

		const { type, id, success } = confirmAccount;
		if (!type || !id || !success) {
			return;
		}
		if (type !== 'ConfirmAccount') {
			return;
		}

		setParameter('', 'CLEAR_PASSWORD_REDUCER', {});

		if (success !== 'success') {
			alert('An error happened, please try setting your password again');
			window.location.reload();
			return;
		}

		alert('Password successfully confirmed, account now registered');

		automaticLogin(
			tokenObj.username,
			formik.values.password,
			async (response: { client: any; connected: boolean }) => {
				if (response && response.connected) {
					props.setParameter('showLoader', 'CHANGE_LOADER', true);
					const account = user as any;
					account.loggedIn = true;
					account.username = tokenObj.username;
					account.password = formik.values.password;
					props.setParameter('user', UPDATE_USER, account);
					props.setParameter('client', SET_MQTTCLIENT, response.client);

					if (tokenObj.type === 'inviteUser' || tokenObj.type === 'calendarInvitation') {
						history.push(`/gobe`);
					} else if (tokenObj.type === 'orgCreated') {
						// don't show org setup if user has no org (effectively deleted from the system), this is a workaround
						if (!user.organizations) {
							history.push(`/`);
						} else {
							history.push(`/gobe`);
						}
					}
				} else {
					props.setParameter('client', SET_MQTTCLIENT, { connected: false });
					alert('An error happened, please try setting your password again');
					window.location.reload();
					return;
				}
			}
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [confirmAccount.success, confirmAccount.type]);

	const resetPassword = (data: any) => {
		props.setParameter('request', 'CONFIRM_ACCOUNT', {
			id: 'ConfirmAccountID-' + tokenObj.username,
			type: 'ConfirmAccount',
		});
		if (token.includes('&source')) {
			token = token.split('&source')[0];
		}

		if (
			data.confirmPassword &&
			typeof data.confirmPassword === 'string' &&
			data.confirmPassword !== ''
		) {
			client.mqttClient.publish(
				`microservice/${b64EncodeUnicode(tokenObj.username)}/accountConfirmation`,
				JSON.stringify({
					requestId: 'ConfirmAccountID-' + tokenObj.username,
					data: { password: data.confirmPassword, token },
				})
			);
		}

		setTokenError('');
	};

	return (
		<>
			{tokenError && tokenError.length > 0 ? (
				<ErrorModal
					isOpen
					onConfirm={() => props.history.push('/')}
					onDismiss={() => props.history.push('/')}
					type="token"
				/>
			) : (
				''
			)}
			<IonPage>
				<IonContent>
					<IonGrid className={classNames(classes.passwordGrid, 'ion-no-padding')}>
						<IonRow className={classes.passwordGridRow}>
							<IonCol
								className={classNames(
									classes.leftContainer,
									'ion-hide-sm-down ion-hide-md-down'
								)}
							/>
							<IonCol className={classes.rightContainer}>
								<IonRow className={classes.passwordFormContainer}>
									<IonGrid>
										<IonRow
											className={classNames(
												classes.topLogoContainer,
												'ion-justify-content-center ion-padding'
											)}
										>
											<div className={classes.logo} />
											<div>
												<IonLabel className={classes.lbInstruction}>
													{translationWithErrorHandling(
														intl,
														'SetPassword.setWelcome'
													)}{' '}
												</IonLabel>
											</div>
										</IonRow>
										<form className={classes.passwordForm}>
											<IonRow>
												<IonLabel className={classes.errorContainer}>
													{tokenError}
												</IonLabel>
											</IonRow>
											<IonRow className="ion-justify-content-center">
												<IonList
													className={classNames(
														classes.forgotPswList,
														'ion-padding'
													)}
												>
													<div>
														{formik.values.password && (
															<PasswordRequirements
																password={formik.values.password}
																checkMarkContainer={
																	formik.errors.password !== ''
																}
															/>
														)}
														<FormikInputListItem
															type={
																passwordType ? 'password' : 'text'
															}
															name="password"
															control={formik}
															value={formik.values.password}
															onChange={(e: any) => {
																formik.handleChange(e);
															}}
															errorMsg={
																formik?.errors?.password
																	? translationWithErrorHandling(
																			intl,
																			'SetPassword.password'
																	  )
																	: ''
															}
															label={translationWithErrorHandling(
																intl,
																'SetPassword.password'
															)}
															placeholderText={translationWithErrorHandling(
																intl,
																'LoginPage.passwordHint'
															)}
															iconUrl={
																passwordType
																	? eyeOffSharp
																	: eyeSharp
															}
															iconCallback={() =>
																setPasswordType(prev => !prev)
															}
															maxLength={InputLimit}
														/>
													</div>
													{!formik.errors?.confirmPassword &&
														formik.values.confirmPassword !== '' && (
															<div
																className={
																	classes.checkMarkConfirmationContainer
																}
															>
																<IonIcon
																	size="small"
																	color="primary"
																	icon={checkmarkCircle}
																/>
															</div>
														)}
													<FormikInputListItem
														type={
															passwordTypeConfirm
																? 'password'
																: 'text'
														}
														name="confirmPassword"
														control={formik}
														value={formik.values.confirmPassword}
														onChange={(e: any) => {
															formik.handleChange(e);
														}}
														errorMsg={
															formik.errors?.confirmPassword
																? translationWithErrorHandling(
																		intl,
																		'SetPassword.confirmPassword'
																  )
																: ''
														}
														label={translationWithErrorHandling(
															intl,
															'SetPassword.retypePassword'
														)}
														placeholderText={translationWithErrorHandling(
															intl,
															'LoginPage.passwordHint'
														)}
														iconUrl={
															passwordTypeConfirm
																? eyeOffSharp
																: eyeSharp
														}
														iconCallback={() =>
															setPasswordTypeConfirm(prev => !prev)
														}
														maxLength={InputLimit}
													/>
												</IonList>
											</IonRow>
											<IonRow className={classes.btnRow}>
												<IonCol size="12">
													<IonButton
														className="round"
														shape="round"
														size="large"
														onClick={() => formik.handleSubmit()}
													>
														{translationWithErrorHandling(
															intl,
															'SetPassword.continue'
														)}
													</IonButton>
												</IonCol>
											</IonRow>
										</form>
									</IonGrid>
								</IonRow>
								<footer className={classes.footerContainer}>
									<FMFooterLogo />
								</footer>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonContent>
			</IonPage>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
	confirmAccount: state.setPasswordState,
});
const enhance = connect(mapStateToProps, { setParameter });

export default injectIntl(withRouter(enhance(AcceptInviteConfirmPage)));
