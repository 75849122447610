import React, { FC, useState, useEffect, useMemo } from 'react';
import {
	IonIcon,
	IonLabel,
	IonList,
	IonItem,
	IonListHeader,
	IonAvatar,
	IonSegment,
	IonSegmentButton,
	IonInput,
	IonCol,
	IonRow,
	IonGrid,
	IonText,
	IonButton,
} from '@ionic/react';
import { camera, globe, checkmarkCircle, logoHtml5 } from 'ionicons/icons';
import FormInputListItem from '../FormInputListItem/FormInputListItem';
import { injectIntl, FormattedMessage } from 'react-intl';
import isAuthenticated from '../Authentication/Authenticated';
import isURL from 'validator/lib/isURL';
import scaleImage from '../../actions/imageScaler';
import { useForm } from 'react-hook-form';
import capitalize from '../../actions/capitalize';
import classes from './ProfileSettings.module.css';
import classNames from 'classnames';
import { useFormik } from 'formik';
import Messages from './ProfileSettings.messages';
import passwordMsg from '../PasswordRequirements/PasswordRequirements.messages';
import { eyeSharp, eyeOffSharp } from 'ionicons/icons';
import { useTypedSelector } from '../../reducers';
import { publish } from '../../actions/publish';
import { URLInputLimit } from '../../utils/validator';
import { ShortInputLimit } from '../../utils/validator';
import { TextField, MenuItem, makeStyles } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { Snackbar } from '../Snackbar';
import { translationWithErrorHandling } from '../../translation/handleMissingTranslation';

import userAvatar from '../../assets/images/userAvatar.svg';
interface ProfileSettingsSubmenuProps {
	goTo?: () => void;
}
const StyledWrapper = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
}));
const StyledIconsFlex = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'row',
}));
const StyledTextWrapper = styled('div')(() => ({
	marginBottom: 21,
	display: 'flex',
	flexDirection: 'column',
}));
const StyledTextField = styled(TextField)(() => ({
	'&.Mui-focused': {
		color: '#56AE4D',
	},
	'& .MuiInput-underline:before': {
		borderBottomColor: '#eeeeee',
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: '#eeeeee',
	},
}));

const useStyles = makeStyles({
	valueLabel: {
		color: '#56AE4D',
		'&.Mui-focused': {
			color: '#56AE4D',
		},
	},
	errorColor: {
		color: 'var(--form-field-error-color)',
		paddingTop: '5px',
	},
	noValueLabel: {
		color: '1E1F22',
		'&.Mui-focused': {
			color: '#56AE4D',
		},
	},
});
const StyledCircleIcon = styled('div')(() => ({
	marginRight: 5,
}));
type UserFrom = {
	firstName: string;
	lastName: string;
	password: string;
	retypedPassword: string;
};

const ProfileSettingsSubmenu: FC<ProfileSettingsSubmenuProps> = (props: any) => {
	const { intl } = props;
	const user = useTypedSelector(state => state.accountState.user);

	// set this to false when the proper service is up and running
	const [expandUrl, setExpandUrl] = useState(true);
	const [editHidden, setEditHidden] = useState(true);
	const [reader] = useState(new FileReader());
	const [passwordSavedSnackBarOpen, setPasswordSavedSnackbarOpen] = useState(false);

	const username = useTypedSelector(state => state.accountState.user.username);

	const profilePictureLink = useTypedSelector(
		state => state.accountState.user.profilePictureLink
	);
	const [validImageUrl, setValidImageUrl] = useState(true);
	const [profilePictureUrl, setProfilePictureUrl] = useState(profilePictureLink);
	let encodedUser = window.btoa(username);

	const spinoutType = useTypedSelector(state => state.versionState.spinoutType) as string;

	const isSso = useTypedSelector(state => state.accountState.user.isSso);

	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			profileUrl: '',
		},

		validate: values => {
			let errors: any = {};
			const inputRegex = /^[a-zA-Z\-?\s]+$/;
			if (values.firstName && values.firstName != '' && !inputRegex.test(values.firstName)) {
				errors.firstName = 'Enter only letters and hyphen(-) characters';
			}
			if (!values.firstName && values.firstName == '') {
				errors.firstName = 'First name is required';
			}
			if (values.lastName && values.lastName != '' && !inputRegex.test(values.lastName)) {
				errors.lastName = 'Enter only letters and hyphen(-) characters';
			}
			if (values.profileUrl && !validImageUrl) {
				errors.profileUrl = 'Please enter a valid image URL';
			}

			return errors;
		},
		onSubmit: values => {
			updateUserProfile(values);
		},
	});

	function checkIfUrlReturnsImage(url: any) {
		return new Promise(resolve => {
			const img = new Image();
			img.onload = () => resolve(true);
			img.onerror = () => resolve(false);
			img.src = url;
		});
	}
	useEffect(() => {
		formik.setValues({
			firstName: user.firstName,
			lastName: user.lastName,
			profileUrl: profilePictureUrl || profilePictureLink,
		});
	}, [user, user.language, profilePictureLink, profilePictureUrl]);

	const setPicture = (event: any) => {
		let f = event.target.files[0] || undefined;
		if (f === undefined) return;
		reader.onload = () => {
			scaleImage(reader.result, props, (srcObject: any) => {
				formik.setFieldValue('profileUrl', srcObject);
			});
		};
		reader.readAsDataURL(f);
		setEditHidden(true);
	};

	const handleDisableButton = () => {
		const hasChangedFirstName = user ? formik.values.firstName !== user.firstName : false;
		const hasChangedLastName = user ? formik.values.lastName !== user.lastName : false;
		const hasChangedPictureUrl = profilePictureLink !== formik.values.profileUrl;
		if (hasChangedFirstName || hasChangedLastName || hasChangedPictureUrl) {
			if (hasChangedFirstName && formik.values.firstName?.length > 0) return false;
			if (hasChangedLastName && formik.values.lastName?.length > 0) return false;
			if (validImageUrl && hasChangedPictureUrl && formik.values.profileUrl?.length > 0)
				return false;
			else return true;
		} else return true;
	};

	const onChangeContent = (value: string) => {
		switch (value) {
			case 'file':
				const e = document.getElementById('fileSelector') as HTMLInputElement;
				e.click();
				break;
			case 'url':
				// uncomment when the proper service is up and running
				// setExpandUrl(!expandUrl);
				break;
			default:
				break;
		}
	};

	const updateUserProfile = (values: any) => {
		const dataObj = {
			user: user,
			updates: {
				firstName: values.firstName ? values.firstName : user.firstName,
				lastName: values.lastName ? values.lastName : user.lastName,
				profilePictureLink:
					values.profileUrl === profilePictureLink ? undefined : values.profileUrl,
			},
		};

		publish(`microservice/${encodedUser}/updateOwnInfo`, {
			data: dataObj,
			requestId: 'updateOwnInfoId',
		});
		setDisplayIconFirstName(false);
		setDisplayIconLastName(false);
		setPasswordSavedSnackbarOpen(true);
	};
	const [displayIconFirstName, setDisplayIconFirstName] = useState(false);
	const [displayIconLastName, setDisplayIconLastName] = useState(false);
	const handleBlurOnNames = (nameType: any) => {
		if (nameType === 'firstName') {
			setDisplayIconFirstName(
				formik.values.firstName.length > 0 &&
					user?.firstName !== formik.values.firstName &&
					!formik.errors.firstName
			);
		}
		if (nameType === 'lastName') {
			setDisplayIconLastName(
				formik.values.lastName.length > 0 &&
					user?.lastName !== formik.values.lastName &&
					!formik.errors.lastName
			);
		}
	};

	const Class = useStyles();
	return (
		<div>
			<Snackbar
				open={passwordSavedSnackBarOpen}
				onClose={() => setPasswordSavedSnackbarOpen(false)}
				message={translationWithErrorHandling(intl, 'ConfirmPage.changesSaved')}
				snackbarType="success"
			/>
			<IonGrid className={classes.formGrid}>
				<IonRow>
					<IonList className="ion-padding">
						<IonListHeader>
							<IonLabel className={classes.labelFont}>
								<FormattedMessage {...Messages.accountSettings} />
							</IonLabel>
						</IonListHeader>
					</IonList>
				</IonRow>
				<IonRow>
					<IonCol className={classes.firstCol}>
						<IonList>
							<StyledWrapper>
								<StyledTextWrapper>
									<StyledTextField
										style={{ marginTop: 14 }}
										type="text"
										label={translationWithErrorHandling(
											intl,
											'fmSettings.firstname'
										)}
										name="firstName"
										placeholder={translationWithErrorHandling(
											intl,
											'fmSettings.firstnameHint'
										)}
										InputLabelProps={{
											className: formik.values.firstName
												? Class.valueLabel
												: Class.noValueLabel,
										}}
										onBlur={e => handleBlurOnNames('firstName')}
										onChange={e => {
											formik.handleChange(e);
										}}
										value={formik.values.firstName}
										InputProps={{
											endAdornment: (
												<div>
													{displayIconFirstName && (
														<StyledCircleIcon>
															<IonIcon
																size="small"
																color="primary"
																icon={checkmarkCircle}
															/>
														</StyledCircleIcon>
													)}
												</div>
											),
										}}
									/>
									{formik?.errors?.firstName && (
										<IonText className={Class.errorColor}>
											<FormattedMessage
												id="fmSettings.firstname"
												defaultMessage={formik?.errors?.firstName}
											/>
										</IonText>
									)}
								</StyledTextWrapper>
								<StyledTextWrapper>
									<StyledTextField
										type="text"
										name="lastName"
										label={translationWithErrorHandling(
											intl,
											'fmSettings.lastname'
										)}
										placeholder={translationWithErrorHandling(
											intl,
											'fmSettings.lastnameHint'
										)}
										InputLabelProps={{
											className: formik.values.lastName
												? Class.valueLabel
												: Class.noValueLabel,
										}}
										onChange={e => {
											formik.handleChange(e);
										}}
										onBlur={e => handleBlurOnNames('lastName')}
										value={formik.values.lastName}
										InputProps={{
											endAdornment: (
												<div>
													{displayIconLastName && (
														<StyledCircleIcon>
															<IonIcon
																size="small"
																color="primary"
																icon={checkmarkCircle}
															/>
														</StyledCircleIcon>
													)}
												</div>
											),
										}}
									/>
									{formik?.errors?.lastName && (
										<IonText className={Class.errorColor}>
											<FormattedMessage
												id="fmSettings.lastname"
												defaultMessage={formik?.errors?.lastName}
											/>
										</IonText>
									)}
								</StyledTextWrapper>
							</StyledWrapper>
						</IonList>
					</IonCol>
					<IonCol>
						<IonList className={classes.profileUrlContainer}>
							{spinoutType !== 'beam' && (
								<IonListHeader className={classes.profileListHeader} lines="none">
									<div className={classes.profileHeader}>
										<IonLabel>
											{user?.firstName} {user?.lastName}
										</IonLabel>
									</div>
								</IonListHeader>
							)}
							{spinoutType !== 'beam' && (
								<IonItem lines="none">
									<IonAvatar
										className={classNames(
											classes.userAvatar,
											'ion-margin-end ion-margin-start'
										)}
									>
										<img
											src={
												(validImageUrl && formik.values.profileUrl) ||
												userAvatar
											}
											alt="avatar"
										/>
									</IonAvatar>
								</IonItem>
							)}
							{spinoutType !== 'beam' && (
								<IonItem lines="none" className={classes.msgContainer}>
									<IonSegment
										className={classes.tabContainer}
										mode="ios"
										onIonChange={(e: any) => onChangeContent(e.detail.value)}
									>
										<IonSegmentButton
											className={
												expandUrl
													? classNames(classes.hidden)
													: 'ion-no-padding'
											}
											value="file"
											layout="icon-start"
										>
											<IonIcon size="small" icon={camera} />
											<IonLabel>
												<FormattedMessage {...Messages.upload} />
											</IonLabel>
										</IonSegmentButton>
										{expandUrl ? (
											<div className={classNames(classes.urlExpand)}>
												<div className={classNames(classes.iconContainer)}>
													<IonIcon
														className={classes.iconExpanded}
														size="small"
														icon={globe}
													/>
												</div>
												<StyledTextField
													className={classNames(classes.expandInput)}
													type="url"
													name="profileUrl"
													placeholder={translationWithErrorHandling(
														intl,
														'fmSettings.addUrl'
													)}
													onChange={e => {
														checkIfUrlReturnsImage(e.target.value).then(
															result => {
																setValidImageUrl(result as boolean);
																setProfilePictureUrl(
																	(e as any).target.value
																);
															}
														);
													}}
												/>
											</div>
										) : (
											<IonSegmentButton
												className="ion-no-padding"
												value="url"
												layout="icon-start"
											>
												<IonIcon size="small" icon={globe} />
												<IonLabel>
													<FormattedMessage {...Messages.web} />
												</IonLabel>
											</IonSegmentButton>
										)}
									</IonSegment>
								</IonItem>
							)}
							{formik?.errors?.profileUrl && (
								<IonItem>
									<IonText className={Class.errorColor}>
										<FormattedMessage
											id="inviteModal.profileUrl"
											defaultMessage={formik?.errors?.profileUrl}
										/>
									</IonText>
								</IonItem>
							)}
						</IonList>
					</IonCol>
				</IonRow>
				<IonRow className={classes.btnRow}>
					<IonCol className={classes.submitCol}>
						<IonButton
							disabled={handleDisableButton()}
							expand="block"
							shape="round"
							type="submit"
							size="large"
							fill="outline"
							onClick={() => formik.handleSubmit()}
						>
							<FormattedMessage {...Messages.save} />
						</IonButton>
					</IonCol>
				</IonRow>
			</IonGrid>
		</div>
	);
};

export default injectIntl(isAuthenticated(ProfileSettingsSubmenu, 'ProfileSettingsSubmenu'));
