import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import {
	IonList,
	IonButton,
	IonIcon,
	IonLabel,
	IonSegment,
	IonSegmentButton,
	IonItem,
	IonContent,
} from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import LoginTemplate from '../../components/LoginTemplate/LoginTemplate';
import { injectIntl, FormattedMessage } from 'react-intl';
import JWTDecode from 'jwt-decode';
import isAuthenticated from './../../components/Authentication/Authenticated';

import { useParams } from 'react-router';

import classNames from 'classnames';
import classes from './Agreements.module.css';

import LanguageOptions from '../../config/LanguageOptions';
import Messages from './Agreements.messages';
import { publish } from '../../actions/publish';
import AgreementsFrame from './AgreementsFrame';
import { isAgreementsAccepted } from '../../actions/accountActions';
import { useTypedSelector } from '../../reducers';
import { Account } from '../../reducers/accountReducers';
import { b64EncodeUnicode } from '../../utils/encoding';
import ErrorModal from '../../components/ErrorModal/ErrorModal';
import { translationWithErrorHandling } from '../../translation/handleMissingTranslation';

interface tabElement {
	[key: string]: any;
	value: string;
	message: object;
	description: string;
}
let tabs: Array<tabElement> = [];
let agreementIds: string[] = [];

const Agreements = (props: any) => {
	const [selectedSegment, setSelectedSegment] = useState<string>('');
	const [userType, setUserType] = useState('user');
	const [tokenError, setTokenError] = useState('');
	const [basicInfoRequested, setBasicInfoRequested] = useState(false);
	const [isAllAgreementRead, setAllAgreementRead] = useState(false);
	const user = useTypedSelector(state => state.accountState.user) as Account;
	const organization = useTypedSelector(state => state.organizationState.organizations);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType);
	const contentRef: any = useRef();
	let { token, language } = useParams<Record<any, any>>();

	if (!basicInfoRequested) {
		publish(
			`microservice/${b64EncodeUnicode(user.username)}/getOwnAccountInfo`,
			JSON.stringify({ requestId: 'someId' })
		);

		setBasicInfoRequested(true);
	}

	// The scroll listener
	const handleScroll = useCallback(e => {
		const scrollTop = e.target.scrollTop;
		const scrollHeight = e.target.scrollHeight;
		const clientHeight = e.target.clientHeight;
		if (scrollHeight <= scrollTop + clientHeight + 1) {
			setAllAgreementRead(true);
		}
	}, []);

	// Attach the scroll listener to the div
	useEffect(() => {
		const div: any = contentRef.current;
		// Attach a scroll listener to the div
		if (div) {
			div.addEventListener('scroll', handleScroll);
		}
	}, [handleScroll]);

	useEffect(() => {
		if (token && token !== 'noToken' && spinoutType) {
			try {
				const obj: {
					exp: number;
					org: { name: string; id: string; orgType: string; language: string };
					type: string;
					username: string;
				} = JWTDecode(token);
				let userTypeInToken;

				if (obj.exp < new Date().getTime() / 1000) {
					setTokenError(
						translationWithErrorHandling(props.intl, 'ConfirmPage.tokenExpired')
					);
				} else {
					if (obj.type) {
						const type = obj.type === 'orgCreated' ? obj.org.orgType : 'user';
						userTypeInToken = type;
						setUserType(type);
					}

					publish(
						`microservice/${b64EncodeUnicode(obj.username)}/getAcceptedAgreements`,
						{
							requestId: 'getAcceptedAgreementsId',
							data: {
								spinoutType: spinoutType ? spinoutType : '',
							},
						}
					);

					publish(
						`microservice/${obj.org.id}/${b64EncodeUnicode(
							obj.username
						)}/getAgreementsContent/${userTypeInToken}`,
						{
							requestId: 'someId',
							data: {
								lang: language,
								orgId: obj.org.id,
								spinoutType: spinoutType ? spinoutType : '',
							},
						}
					);
				}
			} catch (error) {
				setTokenError(translationWithErrorHandling(props.intl, 'ConfirmPage.tokenExpired'));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token, spinoutType]);

	useEffect(() => {
		if (
			user.agreements &&
			user.agreementsLoaded &&
			user.acceptedAgreements &&
			user.acceptedAgreementsLoaded
		) {
			if (isAgreementsAccepted(user)) {
				props.history.push(`/confirm-user/${token}`);
			} else {
				for (let agreement of user.agreements) {
					if (agreement.type === 'safety-agreement') continue;
					if (
						user.acceptedAgreements.find(
							a => a.orgId === agreement.orgId && a.agreementId === agreement.id
						)
					) {
						continue;
					}
					agreementIds.push(agreement.id);

					if (agreement.id.includes('data')) {
						tabs.push({
							value: 'dataProcessingAgreement',
							message: Messages.dataProcessingAgreement,
							description: agreement.content,
						});
					} else if (agreement.id.includes('sub')) {
						tabs.push({
							value: 'subProcessingAgreement',
							message: Messages.subProcessingAgreement,
							description: agreement.content,
						});
					} else if (agreement.id.includes('business')) {
						tabs.push({
							value: 'businessTermsAndConditions',
							message: Messages.businessTermsAndConditions,
							description: agreement.content,
						});
					} else if (agreement.id.includes('use')) {
						tabs.push({
							value: 'termsOfUse',
							message: Messages.termsOfUse,
							description: agreement.content,
						});
					} else if (agreement.id.includes('privacy')) {
						tabs.push({
							value: 'privacyPolicy',
							message: Messages.privacy,
							description: agreement.content,
						});
					}
				}
			}

			if (tabs && tabs.length > 0) setSelectedSegment(tabs[0].value);
		}
		return function cleanup() {
			tabs = [];
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.acceptedAgreements, user.agreements]);

	const onSegmentChange = (value: any) => {
		setSelectedSegment(value);

		let y = document.getElementById('row-' + value)?.offsetTop;
		let content = document.querySelectorAll('ion-content');
		var myDiv = document.getElementById('content');
		if (myDiv && y) {
			myDiv.scrollTop = y;
		}
		if (content) {
			content[1].scrollToPoint(0, y, 500);
		}
	};

	const onLanguage = () => {
		props.history.push(`/confirm-organization/${token}`);
	};

	const onAcceptContinue = () => {
		if (token !== 'noToken') {
			const tokenData: any = JWTDecode(token);

			if (!tokenData || !tokenData.orgId) {
				throw new Error('No orgId inside tokenData');
			}

			publish(
				`microservice/${tokenData.orgId}/${b64EncodeUnicode(
					user.username
				)}/acceptAgreements/${userType}`,
				{
					requestId: 'acceptAgreementsId',
					data: {
						language: 'en',
						ip_address: undefined,
						agreements_ids: agreementIds,
						spinout_type: spinoutType ? spinoutType : '',
					},
				}
			);

			props.history.push(`/confirm-user/${token}`);
		} else if (token === 'noToken') {
			let typeUser = userType;
			if (organization[user.selectedOrganizationId]) {
				if (
					user.username === organization[user.selectedOrganizationId].contactPerson?.email
				) {
					typeUser = user.organizations[user.selectedOrganizationId].orgType;
				}
			}
			publish(
				`microservice/${user.selectedOrganizationId}/${b64EncodeUnicode(
					user.username
				)}/acceptAgreements/${typeUser}`,
				{
					requestId: 'acceptAgreementsId',
					data: {
						language: 'en',
						ip_address: undefined,
						agreements_ids: agreementIds,
						spinout_type: spinoutType ? spinoutType : '',
					},
				}
			);

			if (spinoutType === 'gobe') {
				props.history.push('/gobe');
			} else {
				props.history.push('/fleetManagement');
			}
		}
	};

	return (
		<LoginTemplate
			mainContent={
				<>
					{tokenError && tokenError.length > 0 ? (
						<ErrorModal
							isOpen
							onConfirm={() => props.history.push('/')}
							onDismiss={() => props.history.push('/')}
							type="token"
						/>
					) : (
						''
					)}
					<IonList className={classes.contentList}>
						<IonLabel>
							<FormattedMessage {...Messages.hint} />
						</IonLabel>
						<IonSegment
							className={
								tabs.length > 1 ? classes.tabContainer : classes.tabContainerAlone
							}
							mode="ios"
							onIonChange={(e: CustomEvent) => onSegmentChange(e.detail.value)}
							value={selectedSegment}
						>
							{tabs.map((tab: any, index: number) => {
								if (tab.value !== 'safetyAgreement')
									return (
										<IonSegmentButton
											key={tab.value}
											value={tab.value}
											layout="icon-start"
											className={
												tab.value === 'dataProcessing'
													? classes.longerTab
													: ''
											}
										>
											<IonLabel className={classes.wrap}>
												<FormattedMessage {...tab.message} />
											</IonLabel>
										</IonSegmentButton>
									);
								else return null;
							})}
						</IonSegment>
						<IonContent className={classes.tabContent}>
							<div
								id="content"
								data-cy="agreements_container"
								className={classes.tabDiv}
								ref={contentRef}
							>
								<IonList>
									{tabs.map((tab: any, index: number) => {
										if (tab.value !== 'safetyAgreement')
											return (
												<div
													id={'row-' + tab.value}
													style={{
														display: 'block',
														textOverflow: 'ellipsis',
														whiteSpace: 'nowrap',
														overflow: 'hidden',
														padding: '0.5rem',
													}}
													key={'row' + index}
												>
													<div className={classes.descriptionContainer}>
														<IonItem
															className={classes.chkContainer}
															lines="none"
														>
															<IonLabel>
																<FormattedMessage
																	{...tab.message}
																/>
															</IonLabel>
														</IonItem>
														<br />
														<AgreementsFrame agreement={tab} />
													</div>
												</div>
											);
										else return null;
									})}
								</IonList>
							</div>
						</IonContent>
					</IonList>
				</>
			}
			btnContent={
				<>
					<div className={classes.btnContainer} data-cy="accept_submit">
						{LanguageOptions.length > 1 && token !== 'noToken' ? (
							<IonButton
								className={classNames('transparent', classes.languageBtn)}
								shape="round"
								size="large"
								onClick={() => onLanguage()}
							>
								<IonIcon
									className={classes.icon}
									slot="icon-only"
									size="small"
									icon={chevronBackOutline}
								/>
								<FormattedMessage {...Messages.language} />
							</IonButton>
						) : null}

						<IonButton
							className="round"
							shape="round"
							size="large"
							disabled={tabs.length > 0 ? (!isAllAgreementRead ? true : false) : true}
							onClick={() => onAcceptContinue()}
						>
							<FormattedMessage {...Messages.acceptContinue} />
						</IonButton>
					</div>
				</>
			}
		/>
	);
};

export default injectIntl(isAuthenticated(Agreements, 'Agreements'));
