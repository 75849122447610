import React, { FC, useEffect, useState } from 'react';
import { getCurrentRolesOrgId, getRole, isAgreementsAccepted } from '../../actions/accountActions';
import { useTypedSelector } from '../../reducers';
import { equalityFnc } from '../../utils/conformState';
import { Account } from '../../reducers/accountReducers';
import { publish } from '../../actions/publish';
import { injectIntl } from 'react-intl';
import { setParameter } from '../../actions/setParam';
import { connect } from 'react-redux';
import { b64EncodeUnicode } from '../../utils/encoding';

import { SIGN_OUT_USER } from '../../actions/types';
import { signOut as mqttSignOut } from '../../providers/mqtt';

interface LoginProps {
	inheritedProps: any;
	setParameter: any;
}

const LoginForwarding: FC<LoginProps> = props => {
	const { setParameter } = props;
	const { client } = props.inheritedProps;
	const [requestAgreements, setRequestAgreements] = useState(false);
	const user = useTypedSelector(
		state => state.accountState.user as Account,
		(left, right) => equalityFnc(left, right)
	);
	const organizations = useTypedSelector(
		state => state.organizationState.organizations,
		(left, right) => equalityFnc(left, right)
	);
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType);

	useEffect(() => {
		// request change of organization if the wrong organization Id is used;
		if (
			user.organizations &&
			Object.keys(user.organizations).length > 0 &&
			user.selectedOrganizationId &&
			!requestAgreements
		) {
			const roleId = getRole(user.roles as Account['roles']);
			const currentRole = user.roles ? user.roles[user.selectedOrganizationId]?.roleId : null;
			if ((roleId || '') === (currentRole || '')) {
				setRequestAgreements(true);
			} else {
				const orgId = getCurrentRolesOrgId(user);
				if (orgId) {
					user.selectedOrganizationId = orgId;
					setRequestAgreements(true);
				}
			}
		}
	}, [requestAgreements, user]);

	useEffect(() => {
		if (requestAgreements && spinoutType) {
			publish(`microservice/${b64EncodeUnicode(user.username)}/getAcceptedAgreements`, {
				requestId: 'getAcceptedAgreementsId',
				data: {
					spinoutType: spinoutType ? spinoutType : '',
				},
			});
			let userType = 'user';
			if (
				organizations[user.selectedOrganizationId] &&
				user.username === organizations[user.selectedOrganizationId].contactPerson?.email
			) {
				userType = user.organizations[user.selectedOrganizationId].orgType;
			}
			publish(
				`microservice/${user.selectedOrganizationId}/${b64EncodeUnicode(
					user.username
				)}/getAgreementsContent/${userType}`,
				{
					requestId: 'getAgreementsId',
					data: {
						lang: 'en',
						spinoutType: spinoutType ? spinoutType : '',
					},
				}
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		user.selectedOrganizationId,
		user.organizations,
		user.username,
		organizations,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		organizations[user.selectedOrganizationId],
		requestAgreements,
		spinoutType,
	]);

	useEffect(() => {
		if (!spinoutType) return;

		if (user.agreementsLoaded && user.acceptedAgreementsLoaded) {
			if (isAgreementsAccepted(user) && user.acceptedAgreements.length > 0) {
				setParameter('showLoader', 'CHANGE_LOADER', false);
				if (spinoutType === 'gobe') {
					props.inheritedProps.history.push('/gobe');
				} else if (spinoutType === 'uvd') {
					props.inheritedProps.history.push('/disinfection-dashboard');
				} else {
					props.inheritedProps.history.push('/fleetManagement');
				}
			} else {
				setParameter('showLoader', 'CHANGE_LOADER', false);
				props.inheritedProps.history.push('/agreements/en/noToken');
			}
		} else if (!user.organizations) {
			setParameter('showLoader', 'CHANGE_LOADER', false);
			if (spinoutType === 'gobe') {
				props.inheritedProps.history.push('/gobe');
			} else if (spinoutType === 'uvd') {
				props.inheritedProps.history.push('/disinfection-dashboard');
			} else {
				props.inheritedProps.history.push('/fleetManagement');
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, spinoutType, organizations]);

	return <div />;
};

const mapStateToProps = (state: any) => ({});

export default injectIntl(connect(mapStateToProps, { setParameter })(LoginForwarding));
