import React from 'react';
import { EmptyIcon } from './EmptyIcon';
import './index.scss';
import { useIntl } from 'react-intl';
import { translationWithErrorHandling } from '../../../../../../../translation/handleMissingTranslation';

export const EmptyCardView = () => {
	const intl = useIntl();
	return (
		<div className="emptyCardWrapper">
			<EmptyIcon />
			<span className="emptyCardText">
				{translationWithErrorHandling(intl, 'emptyCardView.noAccess')}
			</span>
		</div>
	);
};
