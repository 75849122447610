import React, { useState, useEffect } from 'react';
import { IonButton, IonCol } from '@ionic/react';
import LoginTemplate from '../../components/LoginTemplate/LoginTemplate';

import { injectIntl } from 'react-intl';
import { LanguageSelect } from '../../components/CustomFormComponents';
import LanguageOptions from '../../config/LanguageOptions';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import JWTDecode from 'jwt-decode';

import { useParams } from 'react-router';

import classes from './SetLanguage.module.css';

import { automaticLogin } from '../../providers/mqtt';
import { SET_MQTTCLIENT, UPDATE_USER } from '../../actions/types';
import { store } from '../../store/store';

import ErrorModal from '../../components/ErrorModal/ErrorModal';
import { useTypedSelector } from '../../reducers';
import { translationWithErrorHandling } from '../../translation/handleMissingTranslation';

const SetLanguage: React.FC = (props: any) => {
	const { intl, setParameter } = props;
	const [tokenValidationStatus, setTokenValidationStatus] = useState('pending');
	const [initializedLanguage, setInitializedLanguage] = useState('en');

	let { token } = useParams<Record<any, any>>();

	const { control, handleSubmit, errors } = useForm();
	const spinoutType = useTypedSelector(state => state.versionState.spinoutType);

	useEffect(() => {
		if (token && spinoutType) {
			try {
				const obj: {
					exp: number;
					firstName: string;
					lastName: string;
					userId: string;
					password: string;
					org: { name: string; org_id: string; org_type: string; language: string };
					type: string;
					isExistingUser: string;
				} = JWTDecode(token);

				if (obj.exp < new Date().getTime() / 1000) {
					setTokenValidationStatus('error');
				}

				if (obj.org) {
					setInitializedLanguage(obj.org.language);
				}
				createClient(obj);
			} catch (error) {
				setTokenValidationStatus('error');
				console.log("No Token Provided, can't continue setup!");
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token, spinoutType]);

	const createClient = (userData: any) => {
		let username = userData.userId ? userData.userId : userData.user_id;
		if (!username || !userData.temp_password) {
			return;
		}

		automaticLogin(username, userData.temp_password, async (response: any) => {
			if (response && response.connected) {
				const user = {
					loggedIn: false,
					selectedOrganizationId: '',
					org: {},
					username: '',
					password: '',
				};
				user.loggedIn = true;
				user.selectedOrganizationId = userData.org_id;
				user.org = userData.org;
				user.username = username;
				user.password = userData.temp_password;
				setParameter('user', UPDATE_USER, user);
				setParameter('client', SET_MQTTCLIENT, response.client);
				setTokenValidationStatus('success');
			} else {
				setParameter('client', SET_MQTTCLIENT, { connected: false });
				setTokenValidationStatus('error');
			}
		});
	};

	const onSubmit = (data: any) => {
		if (token.includes('&source')) {
			token = token.split('&source')[0];
		}

		if (data.languageDdl) {
			props.history.push(`/agreements/${data.languageDdl.value}/${token}`);
		} else {
			props.history.push(`/agreements/en/${token}`);
		}
	};

	if (tokenValidationStatus === 'pending') {
		return null;
	}

	if (tokenValidationStatus === 'success' && LanguageOptions.length === 1) {
		// There is only one available language - no point in making the user select
		handleSubmit(onSubmit)();
	}

	return (
		<>
			{tokenValidationStatus === 'error' ? (
				<ErrorModal
					isOpen
					onConfirm={() => props.history.push('/')}
					onDismiss={() => props.history.push('/')}
					type="token"
				/>
			) : (
				''
			)}
			<LoginTemplate
				hintMessage={translationWithErrorHandling(intl, 'SetLanguagePage.hint')}
				mainContent={
					<IonCol>
						<div className={classes.languageSelectContainer}>
							<LanguageSelect
								control={control}
								initializedValue={initializedLanguage}
								name="languageDdl"
								errors={errors}
								menuPlacement="auto"
								required
							/>
						</div>
					</IonCol>
				}
				btnContent={
					<IonButton className="round" shape="round" size="large" type="submit">
						{translationWithErrorHandling(intl, 'SetLanguagePage.continue')}
					</IonButton>
				}
				onSubmit={handleSubmit(onSubmit)}
			/>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	client: state.mqttState.client,
});

export default injectIntl(connect(mapStateToProps, { setParameter })(SetLanguage));
