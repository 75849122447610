import React, { FC, useState, useCallback, useEffect, useRef, useMemo } from 'react';

import { injectIntl, FormattedMessage } from 'react-intl';
import {
	IonLabel,
	IonList,
	IonItem,
	IonListHeader,
	IonCol,
	IonRow,
	IonGrid,
	IonSelect,
	IonSelectOption,
	IonIcon,
} from '@ionic/react';
import classes from './PilotAppSettings.module.css';
import Messages from './PilotAppSettings.messages';

import isAuthenticated from '../Authentication/Authenticated';
import { camera, mic, micOff, volumeHigh, volumeMute } from 'ionicons/icons';
import classNames from 'classnames';
import { setParameter } from '../../actions/setParam';
import Slider from '../Slider';
import { useTypedSelector } from '../../reducers';
import {
	UPDATE_MICROPHONE,
	UPDATE_SPEAKER,
	UPDATE_CAMERA,
	UPDATE_MICROPHONE_VOLUME,
	UPDATE_SPEAKERS_VOLUME,
} from '../../actions/types';
import { connect } from 'react-redux';
import { HardwareState } from '../../reducers/hardwareReducer';
import { SelectChangeEventDetail } from '@ionic/core';
import { debounce, throttle } from 'lodash';

interface AudioVideoSettingsProps {}

type MediaDevice = NonNullable<HardwareState['settings']['camera']>;
type SelectCustomEvent<T> = { detail: SelectChangeEventDetail<T> };

const AudioVideoSettings: FC<AudioVideoSettingsProps> = (props: any, localStream) => {
	const [speakersList, setSpeakersList] = useState<MediaDevice[]>([]);
	const [microphonesList, setMicrophonesList] = useState<MediaDevice[]>([]);
	const [camerasList, setCamerasList] = useState<MediaDevice[]>([]);

	const videoElementRef = useRef<HTMLVideoElement>(null);

	const hardwareSettings = useTypedSelector(
		state => (state.hardwareState as HardwareState).settings
	);

	const selectedCameraId = getSelectedDeviceId(hardwareSettings.camera, camerasList);
	const selectedMicrophoneId = getSelectedDeviceId(hardwareSettings.microphone, microphonesList);
	const selectedSpeakersId = getSelectedDeviceId(hardwareSettings.speakers, speakersList);

	const microphoneVolume = hardwareSettings.microphoneVolume;
	const speakersVolume = hardwareSettings.speakersVolume;

	/** Used to animated the audio levels of the analyser/equalizer */
	const [microphoneSound, setMicrophoneSound] = useState<number>(0);

	const isUpdatingDevicesListRef = useRef(false);
	const [isUpdatingDevicesList, setIsUpdatingDevicesList] = useState(false);
	const updateDevicesList = useCallback(() => {
		if (isUpdatingDevicesListRef.current === true) return;

		isUpdatingDevicesListRef.current = true;
		setIsUpdatingDevicesList(isUpdatingDevicesListRef.current);

		permissionAwareEnumerateDevices()
			.then(mediaDevices => {
				const microphones = mediaDevices
					.filter(device => device.kind === 'audioinput')
					.map((device, i) => ({
						id: device.deviceId,
						name: device.label || `Microphone ${i + 1}`,
					}));

				const speakers = mediaDevices
					.filter(device => device.kind === 'audiooutput')
					.map((device, i) => ({
						id: device.deviceId,
						name: device.label || `Speaker ${i + 1}`,
					}));

				const cameras = mediaDevices
					.filter(device => device.kind === 'videoinput')
					.map((device, i) => ({
						id: device.deviceId,
						name: device.label || `Camera ${i + 1}`,
					}));

				setMicrophonesList(microphones);
				setSpeakersList(speakers);
				setCamerasList(cameras);
			})
			.catch(error => {
				console.error('updateDevicesList::Error enumerating media devices', error);
				// todo: Display an error indicator to the user
			})
			.finally(() => {
				isUpdatingDevicesListRef.current = false;
				setIsUpdatingDevicesList(isUpdatingDevicesListRef.current);
			});
	}, []);

	// update the device list when the available media devices change - eg: when user plugs in a new device
	useEffect(() => {
		updateDevicesList();
		navigator.mediaDevices.addEventListener('devicechange', updateDevicesList);
		return () => navigator.mediaDevices.removeEventListener('devicechange', updateDevicesList);
	}, [updateDevicesList]);

	/** Media stream associated with the displayed camera view */
	const [videoMediaStream, setVideoMediaStream] = useState<MediaStream | null>(null);
	// update the displayed camera view when selected camera device change
	useEffect(() => {
		debouncedGetUserMedia({
			cameraId: selectedCameraId,
			onGotMediaStream: mediaStream => {
				videoElementRef.current!.srcObject = mediaStream;
				setVideoMediaStream(mediaStream);
			},
		});
	}, [debouncedGetUserMedia, selectedCameraId]);

	/** Media stream associated with the animated microphone input level */
	const [micMediaStream, setMicMediaStream] = useState<MediaStream | null>(null);
	useEffect(() => {
		debouncedGetUserMedia({
			microphoneId: selectedMicrophoneId,
			onGotMediaStream: setMicMediaStream,
		});
	}, [debouncedGetUserMedia, selectedMicrophoneId]);

	// display animated audio output levels of the microphone
	useEffect(() => {
		if (micMediaStream == null) return;

		let audioContext: AudioContext | undefined;
		let analyser: AnalyserNode | undefined;
		let streamSourceNode: MediaStreamAudioSourceNode | undefined;
		// FIXME: ScriptProcessorNode is deprecated. Use AudioWorklet instead https://developer.mozilla.org/en-US/docs/Web/API/AudioWorklet
		let javascriptNode: ScriptProcessorNode | undefined;

		const updateSoundLevel = throttle(setMicrophoneSound, 200);
		audioContext = new AudioContext();
		analyser = audioContext.createAnalyser();
		streamSourceNode = audioContext.createMediaStreamSource(micMediaStream);
		javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);
		analyser.smoothingTimeConstant = 0.8;
		analyser.fftSize = 1024;
		streamSourceNode.connect(analyser);
		analyser.connect(javascriptNode);
		javascriptNode.connect(audioContext.destination);
		javascriptNode.onaudioprocess = function() {
			if (!analyser) return;
			let array = new Uint8Array(analyser.frequencyBinCount);
			analyser.getByteFrequencyData(array);
			let values = 0;
			let length = array.length;
			for (let i = 0; i < length; i++) {
				values += array[i];
			}
			let average = values / length;
			if (average > 100) {
				average = 100;
			}
			updateSoundLevel(average);
			// changeHeight((average / 100) * 12);
		};
		return () => {
			if (javascriptNode) {
				javascriptNode.onaudioprocess = null;
				javascriptNode.disconnect();
			}
			analyser?.disconnect();
			streamSourceNode?.disconnect();
			audioContext?.close();
		};
	}, [micMediaStream]);

	const onMicrophoneVolumeChange = (deltaY: string) => {
		props.setParameter(
			'microphoneVolume',
			UPDATE_MICROPHONE_VOLUME,
			Number.parseFloat(`${deltaY}`)
		);
		localStorage.setItem('preferredMediaDevices.microphoneVolume', deltaY);
	};

	const onSpeakersVolumeChange = (deltaY: string) => {
		props.setParameter(
			'speakersVolume',
			UPDATE_SPEAKERS_VOLUME,
			Number.parseFloat(`${deltaY}`)
		);
		localStorage.setItem('preferredMediaDevices.speakersVolume', deltaY);
	};

	const onSelectedCameraChanged = (selectedCameraId: string | null) => {
		const camera = camerasList.find(({ id }) => id === selectedCameraId) ?? null;
		props.setParameter('camera', UPDATE_CAMERA, camera);
		localStorage.setItem('preferredMediaDevices.camera', JSON.stringify(camera));
	};

	const onSelectedMicrophoneChanged = (selectedMicrophoneId: string | null) => {
		const microphone = microphonesList.find(({ id }) => id === selectedMicrophoneId) ?? null;
		props.setParameter('microphone', UPDATE_MICROPHONE, microphone);
		localStorage.setItem('preferredMediaDevices.microphone', JSON.stringify(microphone));
	};

	const onSelectedSpeakerChanged = (selectedSpeakersId: string | null) => {
		const speakers = speakersList.find(({ id }) => id === selectedSpeakersId) ?? null;
		props.setParameter('speakers', UPDATE_SPEAKER, speakers);
		localStorage.setItem('preferredMediaDevices.speakers', JSON.stringify(speakers));
	};

	// cleanup media streams whenever a new one is set or component is unmounting
	useEffect(() => () => cleanupMediaStream(videoMediaStream), [videoMediaStream]);
	useEffect(() => () => cleanupMediaStream(micMediaStream), [micMediaStream]);

	return (
		<IonGrid className={classes.formGrid}>
			<IonRow>
				<IonList className="ion-padding">
					<IonListHeader>
						<IonLabel className={classes.detailHeader}>
							<FormattedMessage {...Messages.audioVideoSettings} />:
						</IonLabel>
					</IonListHeader>
				</IonList>
			</IonRow>

			{!isUpdatingDevicesList && (
				<IonRow>
					<IonCol
						sizeLg="6"
						sizeMd="12"
						sizeSm="12"
						className={classes.leftColWithSelect}
					>
						<IonRow>
							<IonCol size="12" className={classes.firstCol}>
								<IonItem
									className={classNames(
										classes.selectContainer,
										'ion-no-padding'
									)}
								>
									<IonLabel position="floating">
										<FormattedMessage id="fmSettings.microphone" />
									</IonLabel>
									<IonSelect
										value={selectedMicrophoneId}
										onIonChange={(e: SelectCustomEvent<string>) =>
											onSelectedMicrophoneChanged(e.detail?.value)
										}
										key="microphoneName"
										placeholder="No microphone selected"
									>
										{microphonesList.map(({ id, name }) => {
											return (
												<IonSelectOption key={id} value={id}>
													{name}
												</IonSelectOption>
											);
										})}
									</IonSelect>
								</IonItem>
							</IonCol>
							<IonCol />
						</IonRow>

						{/* <IonRow>
						<IonCol size="12">
							<IonItem
								className={classNames(classes.selectContainer, 'ion-no-padding')}
							>
								<IonLabel position="floating">
									<FormattedMessage id="fmSettings.speakers" />
								</IonLabel>
								<IonSelect
									value={selectedSpeakers?.id}
									onIonChange={(e: SelectCustomEvent<string>) =>
										onSelectedSpeakerChanged(e.detail?.value)
									}
									key="speakerName"
									placeholder="No speakers selected"
								>
									{speakersList.map(({ id, name }) => {
										return (
											<IonSelectOption key={id} value={id}>
												{name}
											</IonSelectOption>
										);
									})}
								</IonSelect>
							</IonItem>
						</IonCol>
						<IonCol />
					</IonRow> */}

						<IonRow>
							<IonCol size="12">
								<IonItem
									className={classNames(
										classes.selectContainer,
										'ion-no-padding'
									)}
								>
									<IonLabel position="floating">
										<FormattedMessage id="fmSettings.camera" />
									</IonLabel>

									<IonSelect
										onIonChange={(e: SelectCustomEvent<string>) =>
											onSelectedCameraChanged(e.detail?.value)
										}
										value={selectedCameraId}
										key="cameraName"
										placeholder="No camera selected"
									>
										{camerasList.map(({ id, name }) => {
											return (
												<IonSelectOption key={id} value={id}>
													{name}
												</IonSelectOption>
											);
										})}
									</IonSelect>
								</IonItem>
							</IonCol>
							<IonCol />
						</IonRow>
					</IonCol>

					<IonCol
						sizeLg="6"
						sizeMd="12"
						sizeSm="12"
						className={classes.rightColWithSelect}
					>
						<IonRow className={classes.rowSliders}>
							<IonCol size="2">
								<div className={classes.audioRecognizeContainer}>
									<div
										className={classes.audioStrength}
										style={{ height: `${4 + microphoneSound / 2}px` }}
									/>
									<div
										className={classes.audioStrengthMax}
										style={{ height: `${4 + microphoneSound}px` }}
									/>
									<div
										className={classes.audioStrength}
										style={{ height: `${4 + microphoneSound / 2}px` }}
									/>
								</div>
							</IonCol>
							<IonCol size="10">
								<IonRow className={classes.sliderDiv}>
									<IonCol size="2" className={classes.leftCol}>
										{microphoneVolume !== 0 && (
											<IonIcon
												size="large"
												icon={mic}
												className={classes.ionIcon}
											/>
										)}

										{microphoneVolume === 0 && (
											<IonIcon
												size="large"
												icon={micOff}
												className={classes.ionIconOff}
											/>
										)}
									</IonCol>

									<IonCol size="3" className={classes.rightCol}>
										<IonLabel
											className={
												microphoneVolume !== 0
													? classNames(classes.volumeStatusText)
													: classNames(classes.volumeStatusTextWhenIs0)
											}
										>
											{microphoneVolume}%
										</IonLabel>
									</IonCol>

									<IonCol size="7" className={classes.sliderCol}>
										<Slider
											onChange={onMicrophoneVolumeChange}
											value={microphoneVolume.toString()}
											icon="speed-green.svg"
											id="navVideoSpeed"
										/>
									</IonCol>
								</IonRow>
							</IonCol>
						</IonRow>
						{/* Speaker volume feature not yet implemented  */}
						{/* <IonRow>
						<IonCol size="2" className={classes.secondSliderLabel} />

						<IonCol size="10">
							<IonRow className={classes.sliderDiv2}>
								<IonCol size="2" className={classes.leftCol}>
									{speakerVolume !== 0 && speakerVolume !== '0' && (
										<IonIcon
											size="large"
											icon={volumeHigh}
											className={classes.ionIcon}
										/>
									)}

									{(speakerVolume === 0 || speakerVolume === '0') && (
										<IonIcon
											size="large"
											icon={volumeMute}
											className={classes.ionIconOff}
										/>
									)}
								</IonCol>

								<IonCol size="3" className={classes.rightCol}>
									<IonLabel
										className={
											speakerVolume !== '0' && speakerVolume !== 0
												? classNames(classes.volumeStatusText)
												: classNames(classes.volumeStatusTextWhenIs0)
										}
									>
										{speakerVolume}%
									</IonLabel>
								</IonCol>
								<IonCol size="7" className={classes.sliderCol}>
									<Slider
										onChange={onSpeakerVolumeChange}
										value={speakerVolume}
										icon="speed-green.svg"
										id="navVideoSpeed"
									/>
								</IonCol>
							</IonRow>
						</IonCol>
					</IonRow> */}
						<IonRow className={classes.videoRow}>
							<IonCol sizeLg="2" />

							<IonCol sizeLg="10" sizeSm="12">
								<div className={classes.videoLandscape}>
									<video
										ref={videoElementRef}
										className={classes.videoStream}
										id="video"
										playsInline
										autoPlay
									/>
								</div>
							</IonCol>
						</IonRow>
					</IonCol>
				</IonRow>
			)}
		</IonGrid>
	);
};

export default injectIntl(
	isAuthenticated(connect(null, { setParameter })(AudioVideoSettings), 'AudioVideoSettings')
);

const debouncedGetUserMedia = debounce(
	(args: {
		cameraId?: string | null;
		microphoneId?: string | null;
		speakersId?: string | null;
		onGotMediaStream: (mediaStream: MediaStream) => void;
	}) => {
		const { cameraId, microphoneId, onGotMediaStream } = args;

		const constraints: MediaStreamConstraints = {
			video: !cameraId ? false : { deviceId: { exact: cameraId } },
			audio: !microphoneId ? false : { deviceId: { exact: microphoneId } },
		};

		if (!constraints.video && !constraints.audio) {
			console.warn('debouncedGetUserMedia::No video or audio found in constraints');
			return;
		}

		console.debug('Getting media stream', { constraints });
		navigator.mediaDevices
			.getUserMedia(constraints)
			.then(onGotMediaStream)
			.catch(error => {
				console.error(error);
				console.error('Error getting media stream', { constraints });
			})
			.finally(() => console.debug('Got media stream', { constraints }));
	},
	250,
	{ trailing: true, leading: false }
);

const cleanupMediaStream = (mediaStream: MediaStream | null) => {
	mediaStream?.getTracks().forEach(track => {
		track.stop();
		track.enabled = false;
		mediaStream.removeTrack(track);
	});
};

const requestMediaDevicesPermission = () => {
	// calling getUserMedia will trigger the media devices permission
	//  modal of the browser
	return navigator.mediaDevices
		.getUserMedia({ audio: true, video: true })
		.then(stream => stream.getTracks().forEach(track => track.stop()));
};

const permissionAwareEnumerateDevices = async () => {
	await requestMediaDevicesPermission();
	const devices = await navigator.mediaDevices.enumerateDevices();

	const videoDevices = devices.filter(device => device.kind === 'videoinput');

	const videoConstraints = {
		facingMode: 'user',
		width: 640,
		height: 480,
		frameRate: { max: 30 },
	} as MediaTrackConstraints;

	let compatibleVideoDevices = [];
	for (const videoDevice of videoDevices) {
		try {
			await navigator.mediaDevices.getUserMedia({
				video: { ...videoConstraints, deviceId: videoDevice.deviceId },
			});
			compatibleVideoDevices.push(videoDevice);
		} catch (error) {
			// Device does not support the constraints, ignore it
		}
	}

	const validDevices = [
		...devices.filter(device => device.kind !== 'videoinput'),
		...compatibleVideoDevices,
	];
	return validDevices;
};

const getSelectedDeviceId = (
	deviceFromReduxStore: MediaDevice | null,
	deviceList: MediaDevice[]
): string | null => {
	const isDeviceFoundInList =
		!!deviceFromReduxStore &&
		!!deviceList.find(device => device.id === deviceFromReduxStore.id);

	if (isDeviceFoundInList) {
		return deviceFromReduxStore?.id ?? null;
	} else {
		return deviceList[0]?.id ?? null;
	}
};
