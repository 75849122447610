import React, { FC, useEffect, useState, useCallback } from 'react';
import {
	IonGrid,
	IonRow,
	IonInput,
	IonList,
	IonItem,
	IonLabel,
	IonCol,
	IonTextarea,
	IonSelect,
	IonSelectOption,
	IonItemGroup,
	IonItemDivider,
	IonChip,
	IonText,
	IonButton,
	IonIcon,
} from '@ionic/react';
import { settingsOutline, copyOutline } from 'ionicons/icons';
import { useForm, useFormContext } from 'react-hook-form';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { setParameter } from '../../actions/setParam';
import isAuthenticated from '../../components/Authentication/Authenticated';
import { injectIntl, FormattedMessage } from 'react-intl';
import Messages from './OrganizationForm.messages';
import { publish } from '../../actions/publish';

import classes from './OrganizationForm.module.css';

import 'react-phone-input-2/lib/style.css';
import { useTypedSelector } from '../../reducers';
import { b64EncodeUnicode } from '../../utils/encoding';
import Config from '../../config';

import OrganizationDomainConfigurationModal from './OrganizationDomainConfigurationModal';
import { getSSoTypeString } from '../../config/ssoTypeOptions';
import { InputLimit, URLInputLimit } from '../../utils/validator';
import { translationWithErrorHandling } from '../../translation/handleMissingTranslation';

interface OrganizationSSOSandboxModeFormProps {
	organization: any;
	isEditable?: boolean;
	onSubmit: (data: any) => void;
	saved: boolean;
	segment: string;
	setEditable: any;
	setIsTestSandboxSSOButtonVisible: boolean;
	shouldTriggerTestSandboxSSO: boolean;
	shouldTriggerApplySandboxSSO: boolean;
	setIsApplySandboxSSOButtonVisible: boolean;
	setShouldTriggerTestSandboxSSO: boolean;
}

type FormData = {
	name: string;
	domains: string;
	idpEntryPoint: string;
	idpIssuer: string;
	idpCertificate: string;
	issuer: string;
	clientId: string;
	clientSecret: string;
	metaDataEndpoint: string;
};

const OrganizationSSOSandboxModeForm: FC<OrganizationSSOSandboxModeFormProps> = (props: any) => {
	const customAlertOptions = {
		id: 'selectSSOType',
	};

	const {
		isEditable,
		organization,
		intl,
		saved,
		segment,
		setEditable,
		setIsTestSandboxSSOButtonVisible,
		shouldTriggerTestSandboxSSO,
		shouldTriggerApplySandboxSSO,
		setIsApplySandboxSSOButtonVisible,
		setShouldTriggerTestSandboxSSO,
	} = props;
	const username = useTypedSelector(state => state.accountState.user.username);
	const encodedUser = b64EncodeUnicode(username);
	const { register, getValues, handleSubmit, errors, formState } = useForm<FormData>();

	const { isValid } = formState;

	const [isDomainConfigurationModalOpen, setIsDomainConfigurationModalOpen] = useState<boolean>(
		false
	);
	const [isCheckButtonEnabled, setIsCheckButtonEnabled] = useState<boolean>(false);
	const [copyOrgCallbackURL, setCopyOrgCallbackURL] = useState<string>('');

	const parseSSOConfiguration = useCallback(
		(organization: any, sandbox: boolean = true) => {
			const ssoConfiguration = JSON.parse(JSON.stringify({ ...organization }))
				?.ssoConfiguration?.sandbox;
			if (ssoConfiguration && !ssoConfiguration?.oidcConfig) {
				ssoConfiguration.oidcConfig = {};
			}
			if (ssoConfiguration && !ssoConfiguration?.samlConfig) {
				ssoConfiguration.samlConfig = {};
			}
			if (ssoConfiguration && !ssoConfiguration.googleConfig) {
				ssoConfiguration.googleConfig = {};
			}
			if (ssoConfiguration && !ssoConfiguration.azureAdOidcConfig) {
				ssoConfiguration.azureAdOidcConfig = {};
			}
			return ssoConfiguration || {};
		},
		[organization]
	);

	const [changingSSOConfiguration, setChangingSSOConfiguration] = useState(
		parseSSOConfiguration(organization)
	);

	const [existingSSOConfiguration, setExistingSSOConfiguration] = useState(
		parseSSOConfiguration(organization)
	);

	useEffect(() => {
		if (saved && segment === 'sso' && changingSSOConfiguration?.ssoType) {
			handleSubmit(onEditSSOConfigurationSubmit)();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getValues, saved, segment]);

	useEffect(() => {
		setChangingSSOConfiguration(parseSSOConfiguration(organization));
		setExistingSSOConfiguration(parseSSOConfiguration(organization));
	}, [organization, parseSSOConfiguration]);

	useEffect(() => {
		let isAtLeastOneDomainVerified = false;
		if (changingSSOConfiguration?.domainVerification) {
			Object.keys(changingSSOConfiguration.domainVerification).map(
				(domainKey: string, i: number) => {
					if (
						changingSSOConfiguration.domainVerification[domainKey]
							?.verificationStatus === 'VERIFIED'
					) {
						isAtLeastOneDomainVerified = true;
						return;
					}
				}
			);
		}
		if (changingSSOConfiguration?.ssoType && isAtLeastOneDomainVerified) {
			setIsCheckButtonEnabled(true);
		} else {
			setIsCheckButtonEnabled(false);
		}
	}, [changingSSOConfiguration?.domainVerification, changingSSOConfiguration?.ssoType]);

	const updateLocalSSOConfiguration = (e: CustomEvent, prop: string) => {
		const change = e.detail.value;
		const ssoConfigurationToChange = changingSSOConfiguration || {};
		if (
			changingSSOConfiguration &&
			'oidc' === changingSSOConfiguration.ssoType &&
			['issuer', 'clientId', 'clientSecret'].includes(prop)
		) {
			if (!ssoConfigurationToChange.oidcConfig) {
				ssoConfigurationToChange.oidcConfig = {};
			}
			ssoConfigurationToChange.oidcConfig[prop] = change;
		} else if (
			changingSSOConfiguration &&
			'saml' === changingSSOConfiguration.ssoType &&
			['idpEntryPoint', 'idpIssuer', 'idpCertificate'].includes(prop)
		) {
			if (!ssoConfigurationToChange.samlConfig) {
				ssoConfigurationToChange.samlConfig = {};
			}
			ssoConfigurationToChange.samlConfig[prop] = change;
		} else if (
			changingSSOConfiguration &&
			'azureAdOidc' === changingSSOConfiguration.ssoType &&
			['metaDataEndpoint', 'clientId', 'clientSecret'].includes(prop)
		) {
			if (!ssoConfigurationToChange.azureAdOidcConfig) {
				ssoConfigurationToChange.azureAdOidcConfig = {};
			}
			ssoConfigurationToChange.azureAdOidcConfig[prop] = change;
		} else if (
			changingSSOConfiguration &&
			'google' === changingSSOConfiguration.ssoType &&
			['clientId', 'clientSecret'].includes(prop)
		) {
			if (!ssoConfigurationToChange.googleConfig) {
				ssoConfigurationToChange.googleConfig = {};
			}
			ssoConfigurationToChange.googleConfig[prop] = change;
		} else if ('domains' === prop) {
			if (!ssoConfigurationToChange[prop]) {
				ssoConfigurationToChange[prop] = [];
			}
			if (ssoConfigurationToChange[prop].join(',') !== change) {
				ssoConfigurationToChange[prop] = change ? change.toString().split(',') : [];
			}
		} else {
			ssoConfigurationToChange[prop] = change;
		}
		setChangingSSOConfiguration({ ...ssoConfigurationToChange });
	};

	const onRollBackSSO = useCallback(() => {
		const liveSSOConfig = parseSSOConfiguration(organization, false);

		const ssoConfiguration: any = organization.ssoConfiguration?.sandbox || {};

		ssoConfiguration['oidcConfig'] = {};
		ssoConfiguration['samlConfig'] = {};
		ssoConfiguration['azureAdOidcConfig'] = {};
		ssoConfiguration['googleConfig'] = {};

		ssoConfiguration['isvalid'] = false;
		ssoConfiguration['domains'] = [];
		ssoConfiguration['domainVerification'] = [];
		ssoConfiguration['ssoType'] = '';

		const domainUrl = window.location.hostname;
		//update only sandbox mode
		publish(`microservice/${organization.orgId}/${encodedUser}/updateOrgSSOSandboxInfo`, {
			data: {
				orgId: organization.orgId,
				applyToLive: liveSSOConfig?.ssoType ? true : false, //apply to  live mode immediately
				domainUrl: domainUrl,
				...ssoConfiguration,
			},
			requestId: 'updateOrgSSOSandboxInfoIdRollBack',
		});
	}, []);

	const onEditSSOConfigurationSubmit = useCallback((data: any) => {
		const ssoConfiguration: any = organization.ssoConfiguration?.sandbox || {};
		delete ssoConfiguration.oidcConfig;
		delete ssoConfiguration.samlConfig;
		delete ssoConfiguration.googleConfig;
		for (const key in data) {
			if (key === 'domains') {
				ssoConfiguration[key] = data[key] ? data[key].toString().split(',') : [];
			} else if (
				ssoConfiguration &&
				'oidc' === ssoConfiguration.ssoType &&
				['issuer', 'clientId', 'clientSecret'].includes(key)
			) {
				if (!ssoConfiguration.oidcConfig) {
					ssoConfiguration.oidcConfig = {};
				}
				ssoConfiguration.oidcConfig[key] = data[key];
			} else if (
				ssoConfiguration &&
				'saml' === ssoConfiguration.ssoType &&
				['idpEntryPoint', 'idpIssuer', 'idpCertificate'].includes(key)
			) {
				if (!ssoConfiguration.samlConfig) {
					ssoConfiguration.samlConfig = {};
				}
				ssoConfiguration.samlConfig[key] = data[key];
			} else if (
				ssoConfiguration &&
				'azureAdOidc' === ssoConfiguration.ssoType &&
				['metaDataEndpoint', 'clientId', 'clientSecret'].includes(key)
			) {
				if (!ssoConfiguration.azureAdOidcConfig) {
					ssoConfiguration.azureAdOidcConfig = {};
				}
				ssoConfiguration.azureAdOidcConfig[key] = data[key];
			} else if (
				ssoConfiguration &&
				'google' === ssoConfiguration.ssoType &&
				['clientId', 'clientSecret'].includes(key)
			) {
				if (!ssoConfiguration.googleConfig) {
					ssoConfiguration.googleConfig = {};
				}
				ssoConfiguration.googleConfig[key] = data[key];
			} else {
				ssoConfiguration[key] = data[key];
			}
		}
		if (ssoConfiguration.oidcConfig) {
			ssoConfiguration.oidcConfig['token_endpoint_auth_method'] = 'post';
		}

		if (!data.ssoType) {
			ssoConfiguration['oidcConfig'] = {};
			ssoConfiguration['samlConfig'] = {};
			ssoConfiguration['azureAdOidcConfig'] = {};
			ssoConfiguration['googleConfig'] = {};
		}
		ssoConfiguration['isvalid'] = false;
		publish(`microservice/${organization.orgId}/${encodedUser}/updateOrgSSOSandboxInfo`, {
			data: {
				orgId: organization.orgId,
				...ssoConfiguration,
			},
			requestId: 'updateOrgSSOSandboxInfoId',
		});

		if (data.ssoType) {
			setIsTestSandboxSSOButtonVisible(true);
		} else {
			setIsApplySandboxSSOButtonVisible(true);
		}
	}, []);

	useEffect(() => {
		if (shouldTriggerTestSandboxSSO) {
			window.open(
				`${
					Config.ssoBaseUrl
				}login?email=test@${changingSSOConfiguration?.domains.shift()}&sandbox=true`
			);
			setShouldTriggerTestSandboxSSO(false);
		}
	}, [shouldTriggerTestSandboxSSO]);

	useEffect(() => {
		if (shouldTriggerApplySandboxSSO) {
			const domainUrl = window.location.hostname;
			publish(`microservice/${organization.orgId}/${encodedUser}/applySSOSandboxToLive`, {
				data: {
					orgId: organization.orgId,
					domainUrl,
				},
				requestId: 'applySSOSandboxToLiveId',
			});

			setIsApplySandboxSSOButtonVisible(false);
			setEditable(false);
		}
	}, [shouldTriggerApplySandboxSSO]);

	const handleVerifyDomain = (domain: string) => {
		publish(`microservice/${organization.orgId}/${encodedUser}/verifyDomain`, {
			data: {
				orgId: organization.orgId,
				domain: domain,
			},
			requestId: 'verifyDomainId',
		});
	};

	useEffect(() => {
		if (copyOrgCallbackURL) {
			setTimeout(() => {
				setCopyOrgCallbackURL('');
			}, 1000);
		}
	}, [copyOrgCallbackURL]);

	return (
		<div className={classes.ssoDiv}>
			<IonGrid className={classes.formGrid}>
				<IonRow>
					<IonCol className={classes.detailsCol}>
						<IonList>
							<form
								onSubmit={handleSubmit(onEditSSOConfigurationSubmit)}
								className={classes.editForm}
							>
								<IonItem>
									<IonLabel
										position="fixed"
										className={classes.formLb}
										style={{ whiteSpace: 'unset !important' }}
									>
										<FormattedMessage {...Messages.orgDomains} />
									</IonLabel>
									<div>
										{changingSSOConfiguration?.domains?.length
											? changingSSOConfiguration?.domains.map(
													(domain: string) => {
														return (
															<IonChip>
																<IonLabel color="dark">
																	{' '}
																	{domain}{' '}
																</IonLabel>
															</IonChip>
														);
													}
											  )
											: ''}
									</div>
									<IonButton
										shape="round"
										fill="clear"
										onClick={() => setIsDomainConfigurationModalOpen(true)}
									>
										<IonIcon
											slot="icon-only"
											size="small"
											icon={settingsOutline}
											color="medium"
										/>
									</IonButton>
									<OrganizationDomainConfigurationModal
										isOpen={isDomainConfigurationModalOpen}
										organization={organization}
										onDismissModal={() => {
											setIsDomainConfigurationModalOpen(false);
										}}
									/>
								</IonItem>
								<IonItem>
									<IonLabel position="fixed" className={classes.formLb}>
										<FormattedMessage {...Messages.ssoType} />
									</IonLabel>
									<IonLabel
										className={!isEditable ? classes.inputLb : classes.hidden}
									>
										{changingSSOConfiguration?.ssoType ?? 'None'}
									</IonLabel>
									<IonSelect
										className={`${
											isEditable ? classes.editable : classes.hidden
										} ion-no-padding`}
										name="ssoType"
										placeholder={translationWithErrorHandling(
											intl,
											'SSOConfiguration.ssoTypeHint'
										)}
										value={changingSSOConfiguration?.ssoType}
										ref={register({ required: false })}
										onIonChange={e => updateLocalSSOConfiguration(e, 'ssoType')}
										interfaceOptions={customAlertOptions}
										interface="alert"
									>
										<IonSelectOption value="">
											{getSSoTypeString('')}
										</IonSelectOption>
										<IonSelectOption value="saml" disabled={true}>
											{getSSoTypeString('saml')}
										</IonSelectOption>

										<IonSelectOption value="oidc" disabled={true}>
											{getSSoTypeString('oidc')}
										</IonSelectOption>
										<IonSelectOption value="azureAdOidc">
											{getSSoTypeString('azureAdOidc')}
										</IonSelectOption>
										<IonSelectOption value="google" disabled={true}>
											{getSSoTypeString('google')}
										</IonSelectOption>
									</IonSelect>
								</IonItem>
								<IonItem disabled={!isEditable}>
									<IonLabel position="fixed" className={classes.formLb}>
										<FormattedMessage {...Messages.callbackURL} />
									</IonLabel>
									{changingSSOConfiguration?.callbackId && (
										<>
											<IonLabel
												className={classes.inputLb}
												style={{ whiteSpace: 'unset' }}
											>
												{`${Config.ssoBaseUrl}login/callback/${changingSSOConfiguration.callbackId}`}
											</IonLabel>
											{copyOrgCallbackURL ? (
												<IonLabel
													color="primary"
													className={classes.copiedOrgCallbackURL}
												>
													Copied!
												</IonLabel>
											) : (
												<IonIcon
													slot="end"
													size="small"
													icon={copyOutline}
													color="primary"
													className={classes.copyOrgCallbackURLBtn}
													onClick={() => {
														navigator.clipboard
															.writeText(
																`${Config.ssoBaseUrl}login/callback/${changingSSOConfiguration.callbackId}`
															)
															.then(() => {
																setCopyOrgCallbackURL(
																	`${Config.ssoBaseUrl}login/callback/${changingSSOConfiguration.callbackId}`
																);
															});
													}}
												/>
											)}
										</>
									)}
								</IonItem>

								{changingSSOConfiguration.ssoType && (
									<IonItemDivider>
										<IonLabel>
											<FormattedMessage {...Messages.configuration} />
										</IonLabel>
									</IonItemDivider>
								)}
								{changingSSOConfiguration.ssoType === 'saml' && (
									<IonItemGroup>
										<IonItem disabled={!isEditable}>
											<IonLabel position="fixed" className={classes.formLb}>
												<FormattedMessage
													{...Messages.samlConfig.idpEntryPoint}
												/>
											</IonLabel>
											<IonLabel
												className={
													!isEditable ? classes.inputLb : classes.hidden
												}
											>
												{
													changingSSOConfiguration?.samlConfig
														?.idpEntryPoint
												}
											</IonLabel>

											<IonInput
												className={
													isEditable ? classes.editable : classes.hidden
												}
												maxlength={InputLimit}
												name="idpEntryPoint"
												placeholder={translationWithErrorHandling(
													intl,
													'SSOConfiguration.samlConfig.idpEntryPointHint'
												)}
												value={
													changingSSOConfiguration?.samlConfig
														?.idpEntryPoint
												}
												ref={register({
													validate: value =>
														changingSSOConfiguration.ssoType ===
															'saml' && !value
															? 'This field is required.'
															: undefined,
												})}
												readonly={!isEditable}
												onIonChange={e =>
													updateLocalSSOConfiguration(e, 'idpEntryPoint')
												}
											/>
											{isEditable && errors.idpEntryPoint && (
												<IonText className={classes.errorMsg}>
													{errors.idpEntryPoint.message}
												</IonText>
											)}
										</IonItem>
										<IonItem disabled={!isEditable}>
											<IonLabel position="fixed" className={classes.formLb}>
												<FormattedMessage
													{...Messages.samlConfig.idpIssuer}
												/>
											</IonLabel>
											<IonLabel
												className={
													!isEditable ? classes.inputLb : classes.hidden
												}
											>
												{changingSSOConfiguration?.samlConfig?.idpIssuer}
											</IonLabel>

											<IonInput
												className={
													isEditable ? classes.editable : classes.hidden
												}
												name="idpIssuer"
												placeholder={translationWithErrorHandling(
													intl,
													'SSOConfiguration.samlConfig.idpIssuerHint'
												)}
												maxlength={InputLimit}
												value={
													changingSSOConfiguration?.samlConfig?.idpIssuer
												}
												ref={register({
													validate: value =>
														changingSSOConfiguration.ssoType ===
															'saml' && !value
															? 'This field is required.'
															: undefined,
												})}
												readonly={!isEditable}
												onIonChange={e =>
													updateLocalSSOConfiguration(e, 'idpIssuer')
												}
											/>
											{isEditable && errors.idpIssuer && (
												<IonText className={classes.errorMsg}>
													{errors.idpIssuer.message}
												</IonText>
											)}
										</IonItem>
										<IonItem disabled={!isEditable}>
											<IonLabel position="fixed" className={classes.formLb}>
												<FormattedMessage
													{...Messages.samlConfig.idpCertificate}
												/>
											</IonLabel>

											<IonTextarea
												className={isEditable ? classes.editable : ''}
												name="idpCertificate"
												rows={20}
												cols={20}
												placeholder={translationWithErrorHandling(
													intl,
													'SSOConfiguration.samlConfig.idpCertificateHint'
												)}
												readonly={!isEditable}
												value={
													changingSSOConfiguration?.samlConfig
														?.idpCertificate
												}
												ref={register({
													validate: value =>
														changingSSOConfiguration.ssoType ===
															'saml' && !value
															? 'This field is required.'
															: undefined,
												})}
												onIonChange={e =>
													updateLocalSSOConfiguration(e, 'idpCertificate')
												}
											/>
											{isEditable && errors.idpCertificate && (
												<IonText className={classes.errorMsg}>
													{errors.idpCertificate.message}
												</IonText>
											)}
										</IonItem>
									</IonItemGroup>
								)}
								{changingSSOConfiguration.ssoType === 'oidc' && (
									<IonItemGroup>
										<IonItem disabled={!isEditable}>
											<IonLabel position="fixed" className={classes.formLb}>
												<FormattedMessage
													{...Messages.oidcConfig.idpIssuer}
												/>
											</IonLabel>
											<IonLabel
												className={
													!isEditable ? classes.inputLb : classes.hidden
												}
											>
												{changingSSOConfiguration?.oidcConfig?.issuer}
											</IonLabel>

											<IonInput
												className={
													isEditable ? classes.editable : classes.hidden
												}
												name="issuer"
												placeholder={translationWithErrorHandling(
													intl,
													'SSOConfiguration.oidcConfig.idpIssuerHint'
												)}
												maxlength={InputLimit}
												value={changingSSOConfiguration?.oidcConfig?.issuer}
												ref={register({
													validate: value =>
														changingSSOConfiguration.ssoType ===
															'oidc' && !value
															? 'This field is required.'
															: undefined,
												})}
												readonly={!isEditable}
												onIonChange={e =>
													updateLocalSSOConfiguration(e, 'issuer')
												}
											/>
											{isEditable && errors.issuer && (
												<IonText className={classes.errorMsg}>
													{errors.issuer.message}
												</IonText>
											)}
										</IonItem>
										<IonItem disabled={!isEditable}>
											<IonLabel position="fixed" className={classes.formLb}>
												<FormattedMessage
													{...Messages.oidcConfig.idpClient}
												/>
											</IonLabel>
											<IonLabel
												className={
													!isEditable ? classes.inputLb : classes.hidden
												}
											>
												{changingSSOConfiguration?.oidcConfig?.clientId}
											</IonLabel>

											<IonInput
												className={
													isEditable ? classes.editable : classes.hidden
												}
												maxlength={InputLimit}
												name="clientId"
												placeholder={translationWithErrorHandling(
													intl,
													'SSOConfiguration.oidcConfig.idpClientHint'
												)}
												value={
													changingSSOConfiguration?.oidcConfig?.clientId
												}
												ref={register({
													validate: value =>
														changingSSOConfiguration.ssoType ===
															'oidc' && !value
															? 'This field is required.'
															: undefined,
												})}
												readonly={!isEditable}
												onIonChange={e =>
													updateLocalSSOConfiguration(e, 'clientId')
												}
											/>
											{isEditable && errors.clientId && (
												<IonText className={classes.errorMsg}>
													{errors.clientId.message}
												</IonText>
											)}
										</IonItem>
										<IonItem disabled={!isEditable}>
											<IonLabel position="fixed" className={classes.formLb}>
												<FormattedMessage
													{...Messages.oidcConfig.idpClientSecret}
												/>
											</IonLabel>
											<IonLabel
												className={
													!isEditable ? classes.inputLb : classes.hidden
												}
											>
												{changingSSOConfiguration?.oidcConfig?.clientSecret}
											</IonLabel>

											<IonInput
												className={
													isEditable ? classes.editable : classes.hidden
												}
												maxlength={InputLimit}
												name="clientSecret"
												placeholder={translationWithErrorHandling(
													intl,
													'SSOConfiguration.oidcConfig.idpClientSecretHint'
												)}
												value={
													changingSSOConfiguration?.oidcConfig
														?.clientSecret
												}
												ref={register({
													validate: value =>
														changingSSOConfiguration.ssoType ===
															'oidc' && !value
															? 'This field is required.'
															: undefined,
												})}
												readonly={!isEditable}
												onIonChange={e =>
													updateLocalSSOConfiguration(e, 'clientSecret')
												}
											/>
											{isEditable && errors.clientSecret && (
												<IonText className={classes.errorMsg}>
													{errors.clientSecret.message}
												</IonText>
											)}
										</IonItem>
									</IonItemGroup>
								)}

								{changingSSOConfiguration.ssoType === 'azureAdOidc' && (
									<IonItemGroup>
										<IonItem disabled={!isEditable}>
											<IonLabel position="fixed" className={classes.formLb}>
												<FormattedMessage
													{...Messages.azureAdOidcConfig
														.idpMetadataEndpoint}
												/>
											</IonLabel>
											<IonLabel
												className={
													!isEditable ? classes.inputLb : classes.hidden
												}
											>
												{
													changingSSOConfiguration?.azureAdOidcConfig
														?.metaDataEndpoint
												}
											</IonLabel>

											<IonInput
												className={
													isEditable ? classes.editable : classes.hidden
												}
												maxlength={URLInputLimit}
												name="metaDataEndpoint"
												placeholder={translationWithErrorHandling(
													intl,
													'SSOConfiguration.azureAdOidcConfig.idpMetadataEndpointHint'
												)}
												value={
													changingSSOConfiguration?.azureAdOidcConfig
														?.metaDataEndpoint
												}
												ref={register({
													validate: value =>
														changingSSOConfiguration.ssoType ===
															'azureAdOidc' && !value
															? 'This field is required.'
															: undefined,
												})}
												readonly={!isEditable}
												onIonChange={e =>
													updateLocalSSOConfiguration(
														e,
														'metaDataEndpoint'
													)
												}
											/>
											{isEditable && errors.metaDataEndpoint && (
												<IonText className={classes.errorMsg}>
													{errors.metaDataEndpoint.message}
												</IonText>
											)}
										</IonItem>
										<IonItem disabled={!isEditable}>
											<IonLabel position="fixed" className={classes.formLb}>
												<FormattedMessage
													{...Messages.azureAdOidcConfig.idpClient}
												/>
											</IonLabel>
											<IonLabel
												className={
													!isEditable ? classes.inputLb : classes.hidden
												}
											>
												{
													changingSSOConfiguration?.azureAdOidcConfig
														?.clientId
												}
											</IonLabel>

											<IonInput
												className={
													isEditable ? classes.editable : classes.hidden
												}
												name="clientId"
												placeholder={translationWithErrorHandling(
													intl,
													'SSOConfiguration.azureAdOidcConfig.idpClientHint'
												)}
												maxlength={InputLimit}
												value={
													changingSSOConfiguration?.azureAdOidcConfig
														?.clientId
												}
												ref={register({
													validate: value =>
														changingSSOConfiguration.ssoType ===
															'azureAdOidc' && !value
															? 'This field is required.'
															: undefined,
												})}
												readonly={!isEditable}
												onIonChange={e =>
													updateLocalSSOConfiguration(e, 'clientId')
												}
											/>
											{isEditable && errors.clientId && (
												<IonText className={classes.errorMsg}>
													{errors.clientId.message}
												</IonText>
											)}
										</IonItem>
									</IonItemGroup>
								)}
								{changingSSOConfiguration.ssoType === 'google' && (
									<IonItemGroup>
										<IonItem disabled={!isEditable}>
											<IonLabel position="fixed" className={classes.formLb}>
												<FormattedMessage
													{...Messages.googleConfig.clientId}
												/>
											</IonLabel>
											<IonLabel
												className={
													!isEditable ? classes.inputLb : classes.hidden
												}
											>
												{changingSSOConfiguration?.googleConfig?.clientId}
											</IonLabel>

											<IonInput
												className={
													isEditable ? classes.editable : classes.hidden
												}
												name="clientId"
												placeholder={translationWithErrorHandling(
													intl,
													'SSOConfiguration.googleConfig.clientIdHint'
												)}
												maxlength={InputLimit}
												value={
													changingSSOConfiguration?.googleConfig?.clientId
												}
												ref={register({
													validate: value =>
														changingSSOConfiguration.ssoType ===
															'google' && !value
															? 'This field is required.'
															: undefined,
												})}
												readonly={!isEditable}
												onIonChange={e =>
													updateLocalSSOConfiguration(e, 'clientId')
												}
											/>
											{isEditable && errors.clientId && (
												<IonText className={classes.errorMsg}>
													{errors.clientId.message}
												</IonText>
											)}
										</IonItem>
										<IonItem disabled={!isEditable}>
											<IonLabel position="fixed" className={classes.formLb}>
												<FormattedMessage
													{...Messages.googleConfig.clientSecret}
												/>
											</IonLabel>
											<IonLabel
												className={
													!isEditable ? classes.inputLb : classes.hidden
												}
											>
												{
													changingSSOConfiguration?.googleConfig
														?.clientSecret
												}
											</IonLabel>

											<IonInput
												className={
													isEditable ? classes.editable : classes.hidden
												}
												name="clientSecret"
												placeholder={translationWithErrorHandling(
													intl,
													'SSOConfiguration.googleConfig.clientSecretHint'
												)}
												maxlength={InputLimit}
												value={
													changingSSOConfiguration?.googleConfig
														?.clientSecret
												}
												ref={register({
													validate: value =>
														changingSSOConfiguration.ssoType ===
															'google' && !value
															? 'This field is required.'
															: undefined,
												})}
												readonly={!isEditable}
												onIonChange={e =>
													updateLocalSSOConfiguration(e, 'clientSecret')
												}
											/>
											{isEditable && errors.clientSecret && (
												<IonText className={classes.errorMsg}>
													{errors.clientSecret.message}
												</IonText>
											)}
										</IonItem>
									</IonItemGroup>
								)}
								{!changingSSOConfiguration?.ssoType &&
								existingSSOConfiguration?.ssoType ? (
									<IonItem lines="none">
										<IonButton
											expand="block"
											shape="round"
											size="default"
											type="button"
											onClick={() => onRollBackSSO()}
										>
											Rollback SSO
										</IonButton>
									</IonItem>
								) : (
									<IonItem lines="none">
										<IonButton
											expand="block"
											shape="round"
											type="submit"
											size="default"
											disabled={!isCheckButtonEnabled}
										>
											Check
										</IonButton>
									</IonItem>
								)}
							</form>
						</IonList>
					</IonCol>
				</IonRow>
			</IonGrid>
		</div>
	);
};
const mapStateToProps = (state: any) => ({
	organizations: state.organizationState.organizations,
});

const enhance = compose(connect(mapStateToProps, { setParameter }));

export default injectIntl(
	isAuthenticated(enhance(OrganizationSSOSandboxModeForm), 'OrganizationSSOSandboxModeForm')
);
