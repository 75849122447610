import classNames from 'classnames';
import React from 'react';
import { useTypedSelector } from '../../../../../../../reducers';
import { Device } from '../../../../../../../types/types';
import { getStateEntityByOrgId } from '../../../../../../../utils/conformState';
import { equalityFnc } from '../../../../utils/deviceStateConverter';
import { useDeviceCurrentState } from '../../../../utils/statusConverter';
import { BatteryColumn } from './components/BatteryColumn';
import { FavoriteColumn } from './components/FavoriteColumn';
import { NameColumn } from './components/NameColumn/NameColumn';
import { StatusColumn } from './components/StatusColumn/StatusColumn';
import { useSelector } from 'react-redux';
import { selectAccessHoursEvents } from '../../../../../../../selectors/accessHoursSelector';
import { processRobotAccessHours, isCallableChecker } from '../../../../utils/accessHourLogic';
import './index.scss';
import { useIntl } from 'react-intl';
import { translationWithErrorHandling } from '../../../../../../../translation/handleMissingTranslation';

interface Props {
	deviceId: number | string;
	orgId: number | string;
	deviceGroupsNames?: string | null;
	onClickStartSession: (
		device: Device,
		reservationStartTime?: string,
		reservationEndDate?: string,
		isMyReservation?: boolean
	) => void;
	isCalling: boolean;
	publishFavouriteDevice: any;
}

export const LaunchRow = ({
	deviceId,
	orgId,
	deviceGroupsNames,
	onClickStartSession,
	isCalling,
	publishFavouriteDevice,
}: Props) => {
	const intl = useIntl();
	const device = useTypedSelector(
		state =>
			getStateEntityByOrgId(state, 'accountState', {
				entityId: deviceId as string,
				orgId: orgId as string,
				propertyOptions: [
					'deviceId',
					'serialNumber',
					'online',
					'status',
					'battery',
					'currentState',
					'name',
					'battery',
					'location',
					'guestReservation',
					'isPermanent',
				],
			}),
		(left, right) => equalityFnc(left, right)
	);

	const accountState = useTypedSelector(state => state.accountState);
	const isFavorite = accountState.user.favouriteDevice?.includes(device.serialNumber) || false;

	const {
		canCall,
		deviceStatus,
		othersNextReservation,
		myNextReservation,
		nextReservation,
	} = useDeviceCurrentState(device, isCalling);
	console.log('device battery', device?.battery);

	const accessHoursEvents = useSelector(selectAccessHoursEvents);

	let AccessHoursNotBlocking = true;
	let formattedStartTime = '';
	let formattedEndTime = '';
	let days: string[] = [];
	if (Object.keys(accessHoursEvents.accessHoursEvents).includes(device.serialNumber)) {
		({ AccessHoursNotBlocking, formattedStartTime, formattedEndTime } = processRobotAccessHours(
			accessHoursEvents.accessHoursEvents,
			device.serialNumber
		));
	}

	//Check if access hours are blocking access to the robot. Access hours do not apply to reservations
	const isCallable: boolean = isCallableChecker(
		accessHoursEvents.accessHoursEvents,
		device.serialNumber,
		AccessHoursNotBlocking,
		!!nextReservation,
		canCall
	);

	return (
		<div className={classNames('rosterListRow oneGoBeListContainer')}>
			<FavoriteColumn
				isFavorite={isFavorite}
				onClick={() => publishFavouriteDevice(device as Device)}
			/>
			<NameColumn
				canCall={canCall}
				onClickStartSession={() => {
					if (isCallable) {
						onClickStartSession(
							device as Device,
							myNextReservation,
							othersNextReservation
						);
					} else {
						alert(
							`${translationWithErrorHandling(
								intl,
								'listViewLaunchRow.thisRobotMayOnlyBeUsed'
							)} ${formattedStartTime} ${translationWithErrorHandling(
								intl,
								'listViewLaunchRow.and'
							)} ${formattedEndTime} ${translationWithErrorHandling(
								intl,
								'listViewLaunchRow.onDays'
							)} ${days.join(', ')} (${translationWithErrorHandling(
								intl,
								'listViewLaunchRow.yourLocalTime'
							)}\n${translationWithErrorHandling(
								intl,
								'listViewLaunchRow.contactYourRobotAdmin'
							)}`
						);
					}
				}}
				nextReservation={nextReservation}
				deviceStatus={!isCallable ? 'unavailable' : deviceStatus}
				isCalling={isCalling}
				deviceName={device.name}
				isPermanentDevice={device.isPermanent}
			/>
			<StatusColumn
				nextReservation={nextReservation}
				othersNextReservation={othersNextReservation}
				myNextReservation={myNextReservation}
				deviceStatus={!isCallable ? 'unavailable' : deviceStatus}
				isCalling={isCalling}
				isPermanentDevice={device.isPermanent}
				deviceSerial={device.serialNumber}
			/>
			<BatteryColumn
				deviceBattery={
					device?.battery ?? (device?.status ? JSON.parse(device?.status).battery : {})
				}
			/>
			<div className="rosterListRowCell">
				<span className="bodyText">
					{device.location ||
						translationWithErrorHandling(intl, 'listViewLaunchRow.locationUnknown')}
				</span>
			</div>
			<div className="rosterListRowCell">
				<span className="bodyText">{deviceGroupsNames}</span>
			</div>
		</div>
	);
};
